import { Box } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import Loader from 'src/components/Loader';
import { AzureWebPubSubUserContext } from 'src/contexts/AzureWebPubSubUserContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import {
  closeDocumentViewer,
  updateQuestionnaireAssociation,
  updateAnalysisQuestionnaireStatus
} from 'src/redux/slices/docAnalyst';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { useLazyGetAnalysisDetailQuery } from 'src/services/api';
import { AnalysisResponseProps } from 'src/types/docAnalyst';
import { ErrorContext } from 'src/utils/errorMappings';
import ResponseDetailViewer from '../ResponseDetailViewer';
import { analysisDetailColumns } from '../../../utils/utils';
import DataRow from './DataRow';
import HeaderRow from './HeaderRow';

const AnalysisDetailGrid = ({ regeneratedResponseData }) => {
  const dispatch = useDispatch();
  const {
    initiateAnalysis: { selectedAnalysis },
    analysisHistoryContexts
  } = useSelector((state: RootState) => state.docAnalyst);
  const [rowData, setRowData] = useState<AnalysisResponseProps[]>([]);
  const [isDetailedResponseOpen, setIsDetiledResponseOpened] = useState(false);
  const [currentOpenedQuestion, setCurrentOpenedQuestion] =
    useState<number>(null);
  const { analysisResponse } = useContext(AzureWebPubSubUserContext);
  const [
    getAnalysisDetail,
    { isLoading: isFetchingAnalyssiDetail, data: analysisDetail }
  ] = useLazyQuery({
    api: useLazyGetAnalysisDetailQuery,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const updateRowData = useCallback((val: AnalysisResponseProps) => {
    setRowData((prevRowData) => {
      const idx = prevRowData.findIndex((data) => data?.id === val?.id);
      if (idx !== -1) {
        const newRowData = [...prevRowData];
        newRowData.splice(idx, 1, val);
        return newRowData;
      }
      return prevRowData;
    });
  }, []);

  const handleResponseUpdate = useCallback(
    (val: AnalysisResponseProps) => {
      updateRowData(val);
    },
    [rowData]
  );

  const handlePrevQuestion = useCallback(() => {
    if (currentOpenedQuestion > 0) {
      setCurrentOpenedQuestion((prev) => prev - 1);
      dispatch(closeDocumentViewer(selectedAnalysis?.id));
    }
  }, [currentOpenedQuestion, selectedAnalysis?.id]);

  const handleNextQuestion = useCallback(() => {
    if (currentOpenedQuestion < rowData.length - 1) {
      setCurrentOpenedQuestion((prev) => prev + 1);
      dispatch(closeDocumentViewer(selectedAnalysis?.id));
    }
  }, [rowData, currentOpenedQuestion]);

  const onRowSelect = useCallback((id: number) => {
    setCurrentOpenedQuestion(id);
    setIsDetiledResponseOpened(true);
  }, []);

  const isDocumentViewerOpen =
    analysisHistoryContexts?.[selectedAnalysis?.id]?.isDocumentViewerOpen;

  useEffect(() => {
    getAnalysisDetail({ params: { params: { id: selectedAnalysis?.id } } });
  }, [selectedAnalysis?.id]);

  useEffect(() => {
    const data = analysisDetail?.data;
    if (data?.length) {
      const hasEditedQuestions = data[0].detail.some((q) => q.is_edited);

      dispatch(updateAnalysisQuestionnaireStatus(hasEditedQuestions));
      dispatch(
        updateQuestionnaireAssociation({
          isQuestionnaireAssociated:
            data[0].analysis?.is_questionnaire_associated
        })
      );
      setRowData(data[0].detail);
    }
  }, [analysisDetail]);

  useEffect(() => {
    handleResponseUpdate(analysisResponse);
  }, [analysisResponse]);

  useEffect(() => {
    if (regeneratedResponseData) setRowData(regeneratedResponseData.data);
  }, [regeneratedResponseData]);

  if (isFetchingAnalyssiDetail) {
    return <Loader />;
  }

  return (
    <Box width={'100%'}>
      <HeaderRow columns={analysisDetailColumns} />
      <Box height={'calc(100vh - 205px)'} overflow={'auto'}>
        {rowData.map((data, index) => (
          <DataRow
            key={index}
            index={index}
            data={data}
            updateRowData={updateRowData}
            setSelectedRow={onRowSelect}
          />
        ))}
      </Box>
      {isDetailedResponseOpen && (
        <ResponseDetailViewer
          open={isDetailedResponseOpen}
          setOpen={setIsDetiledResponseOpened}
          response={rowData[currentOpenedQuestion]}
          currentOpenedQuestion={currentOpenedQuestion + 1}
          onNext={handleNextQuestion}
          onPrev={handlePrevQuestion}
          isDocumentViewerOpen={isDocumentViewerOpen}
          totalQuestions={rowData.length}
        />
      )}
    </Box>
  );
};

export default AnalysisDetailGrid;
