import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CollectionIcon } from 'src/assets/documentIcons/collection-icon.svg';
import { ReactComponent as CollectionOneDriveIcon } from 'src/assets/documentIcons/collection-onedrive-icon.svg';
import { ReactComponent as CollectionSharepointIcon } from 'src/assets/documentIcons/collection-sharepoint-icon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';
import { ReactComponent as MoreIcon } from 'src/assets/documentIcons/more-icon.svg';
import { ReactComponent as OpenInNewIcon } from 'src/assets/documentIcons/open-in-new-icon.svg';
import { ReactComponent as RenameIcon } from 'src/assets/documentIcons/rename-icon.svg';
import { ReactComponent as ShareIcon } from 'src/assets/documentIcons/share-icon.svg';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { convertDate } from 'src/utils/utils';

import { Check, Close } from '@mui/icons-material';
import SyncIcon from '@mui/icons-material/Sync';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { ReactComponent as StarOutlineIcon } from 'src/assets/documentIcons/star-icon.svg';
import TextField from 'src/components/TextField';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { updateOnboarding } from 'src/redux/slices/onboarding';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import {
  DOCUMENT_STATUS,
  OnboardingStepTypes,
  PROVIDER_STATUS,
  RESOURCE_TYPE,
  USER_RESOURCE_PERMISSION
} from 'src/types/enum';
import { DATA_LIBRARY_ROUTES } from 'src/utils/constants';
import { getCardIconsStyles } from '../../Workspaces/WorkspaceCard/styles';
import { ActionButton, StarRateIcon } from '../../utils/styles';
import { publicOrPrivateWorkspaceCheck } from '../../utils/utils';

interface CollectionCardProps {
  row: StringKeys;
  handleShareResourceClick?: (data: StringKeys) => void;
  handleStartChatClick?: (data: StringKeys) => void;
  setActiveDirectory?: (data: StringKeys) => void;
  onRowUpdated?: (changedRow: StringKeys) => void;
  setDeletedRowId?: React.Dispatch<React.SetStateAction<string>>;
  setDeleteOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setAddNewRow?: React.Dispatch<React.SetStateAction<boolean>>;
  addNewRow?: boolean;
  isMoveDialogOpen?: boolean;
  onRowAdded?: (changedRow: StringKeys) => void;
  handleSyncResourceClick?: (providerId: string, fileId: string) => void;
  filteredKeys?: StringKeys;
}

interface FormValues {
  name: string;
}
const CollectionCard = ({
  row,
  handleShareResourceClick,
  handleStartChatClick,
  setActiveDirectory,
  onRowUpdated,
  setDeletedRowId,
  setDeleteOpen,
  setAddNewRow,
  addNewRow,
  isMoveDialogOpen,
  onRowAdded,
  handleSyncResourceClick,
  filteredKeys
}: CollectionCardProps) => {
  const renameTextFieldRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isRenaming, setIsRenaming] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const formMethods = useForm<FormValues>({
    defaultValues: { name: row?.name || '' },
    mode: 'onChange'
  });
  const { sharePointProviderId, oneDriveProviderId } = useSelector(
    (state: RootState) => state.data
  );
  const { isOnboarded } = useSelector((state: RootState) => state.data);
  const { isOnboarding } = useSelector((state: RootState) => state.onboarding);

  const { updateFavourite, isUpdatingFavourite } = useContext(DocumentsContext);
  const { isFavouritesFetching } = useSelector(
    (state: RootState) => state.data
  );
  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch
  } = formMethods;

  const isPublicOrPrivateWorkspace = useMemo(
    () => publicOrPrivateWorkspaceCheck(row?.parent_hierarchy),
    [row]
  );

  useEffect(() => {
    formMethods.setValue('name', row?.name || '');
    if (renameTextFieldRef.current) {
      renameTextFieldRef.current.focus();
    }
  }, [row?.name, isRenaming]);

  const open = Boolean(anchorEl);
  const id = open ? 'more-actions' : undefined;

  const handleClose = (e?) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleConfirm = (event) => {
    event.stopPropagation();
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleStartChat = (e: any) => {
    e.stopPropagation();
    handleStartChatClick?.(row);
  };

  const handleRenaming = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleClose(e);
    if (addNewRow) return;
    setIsRenaming(true);
  };

  const handleRenamingCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (row?.id === 0) {
      if (!isOnboarded) {
        dispatch(updateOnboarding({ stepsContext: OnboardingStepTypes.USER }));
      }
      setAddNewRow(false);

      return;
    }
    setIsRenaming(false);
  };

  const handleShare = (e: any) => {
    e.stopPropagation();
    handleShareResourceClick?.(row);
  };

  const handleSync = (e: any) => {
    e.stopPropagation();
    handleSyncResourceClick?.(row.provider_id, row?.id);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    handleClose(e);
    setDeletedRowId?.(row?.id);
    setDeleteOpen?.(true);
  };

  const handleResourceProviderNavigateClick = (e: any) => {
    e.stopPropagation();
    handleClose(e);
    const webUrl = JSON.parse(row.external_data || '{}')?.item_data?.webUrl;
    window.open(webUrl, '_blank');
  };

  const handleNavigate = () => {
    if (isRenaming || !row?.id) return;

    if (isMoveDialogOpen) {
      setActiveDirectory(row);
    } else if (!isMoveDialogOpen) {
      navigate(
        `/data-library/${DATA_LIBRARY_ROUTES[row.type_code]}/${row?.id}`,
        {
          state: filteredKeys
        }
      );
    }
  };

  const handleAddFavourite = (e: any) => {
    e.stopPropagation();
    if (!isUpdatingFavourite && !isFavouritesFetching) {
      dispatch(setIsFavouritesFetching(true));
      updateFavourite(row?.id, !row?.isFavourite);
    }
  };

  const onSubmit = (data: FormValues) => {
    if (isValid && row.name !== data.name) {
      const updatedRow = { [`${row?.id}`]: { ...row, name: data.name } };
      if (row?.id === 0) {
        onRowAdded?.({ ...row, name: data.name });
        if (isOnboarding && !isOnboarded) {
          dispatch(updateOnboarding({ isOnboarding: false, stepCount: 4 }));
        }
        return;
      }
      onRowUpdated?.(updatedRow);
    }
  };

  const hasReprocessPermission =
    row?.permissions?.[USER_RESOURCE_PERMISSION.UPLOAD_FILES_FOLDERS]?.value &&
    row.type_code === RESOURCE_TYPE.FILE &&
    row.status_code === DOCUMENT_STATUS.UNSUPPORTED;

  const useShowButton = (providerId: number) =>
    useMemo(
      () =>
        row?.provider_id === providerId &&
        JSON.parse(row.external_data || '{}')?.item_data?.webUrl,
      [providerId, row]
    );

  const showSharePointButton = useShowButton(sharePointProviderId);
  const showOneDriveButton = useShowButton(oneDriveProviderId);

  const showMoreActions =
    row?.permissions?.[USER_RESOURCE_PERMISSION.DELETE_FILES_FOLDERS]?.value ||
    row?.permissions?.[USER_RESOURCE_PERMISSION.RENAME_FILES_FOLDERS]?.value ||
    hasReprocessPermission ||
    showSharePointButton ||
    showOneDriveButton ||
    row?.provider_id === sharePointProviderId ||
    row?.provider_id === oneDriveProviderId ||
    false;

  return (
    <>
      <Card
        id={
          row?.id === 0
            ? ONBOARDING_STEPS_TARGET.DOCUMENT_NEW_COLLECTION_BUTTON
            : undefined
        }
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: 'none',
          border: `1px solid ${alpha(theme.colors.custom.labelColor, 0.2)}`,
          borderRadius: '6px !important',
          padding: '8px 12px',
          cursor: 'pointer',
          minHeight: '90px',
          maxWidth: '100%',
          ['& .collection-card-actions']: {
            visibility: 'hidden'
          },
          '& .starRateIcon': {
            visibility: row?.isFavourite ? 'visible' : 'hidden'
          },

          ['&:hover']: {
            ['& .collection-card-actions']: {
              visibility: 'visible',
              display: 'flex'
            },
            boxShadow: theme.colors.shadows.cardElevation
          }
        }}
        onClick={handleNavigate}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          minWidth={'0'}
          flex={1}
          overflow={'hidden'}
        >
          {row?.provider_id === sharePointProviderId ? (
            <CollectionSharepointIcon
              height={64}
              width={64}
              style={{
                minHeight: '64px',
                minWidth: '64px'
              }}
            ></CollectionSharepointIcon>
          ) : row?.provider_id === oneDriveProviderId ? (
            <CollectionOneDriveIcon
              height={64}
              width={64}
              style={{
                minHeight: '64px',
                minWidth: '64px'
              }}
            />
          ) : (
            <CollectionIcon
              height={64}
              width={64}
              style={{
                minHeight: '64px',
                minWidth: '64px'
              }}
            />
          )}
          <Box
            sx={{
              marginLeft: 1,
              overflow: 'hidden',
              maxWidth: '100%'
            }}
          >
            {isRenaming || row?.id === 0 ? (
              <Box
                width={'100%'}
                padding={0.5}
                sx={{
                  alignSelf: 'flex-start'
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <form onSubmit={handleSubmit(onSubmit)} id="collection-form">
                  <Controller
                    name="name"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t(T.required, { name: t(T.collectionName) })
                      },
                      maxLength: {
                        value: 30,
                        message: t(T.maxLength, {
                          name: t(T.workspaceName),
                          length: 30
                        })
                      }
                    }}
                    render={({ field, formState: { errors } }) => (
                      <TextField
                        sx={{
                          width: '100%'
                        }}
                        value={field.value || ''}
                        required
                        error={!!errors.name}
                        helperText={errors.name?.message || ''}
                        placeholder={t(T.name)}
                        InputProps={{
                          style: {
                            padding: 0
                          }
                        }}
                        {...field}
                        ref={renameTextFieldRef}
                      />
                    )}
                  />
                </form>
              </Box>
            ) : (
              <>
                <Typography
                  variant={'body1'}
                  noWrap={true}
                  style={{
                    marginRight: 1,
                    fontSize: '16px',
                    fontWeight: 500,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {row?.name}
                </Typography>
                <Box>
                  <Typography
                    style={{
                      padding: 0,
                      fontSize: '11px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {row?.provider_id === sharePointProviderId ||
                    row?.provider_id === oneDriveProviderId
                      ? `${t(T.syncedBy)}: ${row?.synchronized_by}`
                      : `${t(T.modifiedBy)}: ${row?.modified_by}`}
                  </Typography>
                  {row.sync_status_code === PROVIDER_STATUS.SYNC_STARTED ||
                  row.sync_status_code === PROVIDER_STATUS.SYNC_STARTING ||
                  row.sync_status_code === PROVIDER_STATUS.SYNC_RETRYING ||
                  row.sync_status_code === PROVIDER_STATUS.SYNC_FAILED ? (
                    <Typography
                      style={{
                        padding: 0,
                        fontSize: '11px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {row?.sync_status_code === PROVIDER_STATUS.SYNC_FAILED
                        ? t(T.syncingFailed)
                        : t(T.syncing)}
                    </Typography>
                  ) : (
                    !!(
                      convertDate(row?.modified_on, t) ||
                      convertDate(row?.synchronized_on, t)
                    ) && (
                      <Typography
                        style={{
                          padding: 0,
                          fontSize: '11px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {row?.provider_id === sharePointProviderId ||
                        row?.provider_id === oneDriveProviderId
                          ? t(T.syncedWithTime, {
                              time: `${convertDate(row?.synchronized_on, t)}`
                            })
                          : t(T.modifiedWithTime, {
                              time: `${convertDate(row?.modified_on, t)}`
                            })}
                      </Typography>
                    )
                  )}
                </Box>
              </>
            )}
          </Box>
        </Box>
        {!isMoveDialogOpen && (
          <Grid
            display={'flex'}
            alignItems={'flex-end'}
            flexDirection={'column'}
            paddingTop={0.75}
            justifyContent={'space-evenly'}
            className="collection-card-actions"
          >
            <Grid item minHeight={'30px'}>
              {row?.permissions?.[USER_RESOURCE_PERMISSION.QUERY_FILES_FOLDERS]
                ?.value &&
                !isRenaming &&
                row?.id && (
                  <Button
                    title={t(T.startChat)}
                    onClick={handleStartChat}
                    color="secondary"
                    size="small"
                    variant="contained"
                    sx={{
                      fontSize: '12px',
                      px: 0.8,
                      py: 0.5
                    }}
                  >
                    {t(T.startChat)}
                  </Button>
                )}
            </Grid>
            <Grid item minHeight={'30px'}>
              <Grid
                container
                display={'flex'}
                justifyItems={'flex-end'}
                alignContent={'flex-end'}
                alignSelf={'flex-end'}
                marginTop={0.5}
              >
                <Grid item>
                  {(isRenaming || !row?.id) && (
                    <IconButton
                      type="submit"
                      form="collection-form"
                      title={t(T.confirm)}
                      onClick={handleConfirm}
                      sx={{
                        padding: '5px 5px'
                      }}
                    >
                      <Check
                        color="success"
                        sx={{
                          width: '18px',
                          height: '18px'
                        }}
                      />
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  {isRenaming || row?.id === 0 ? (
                    <IconButton
                      onClick={handleRenamingCancel}
                      title={t(T.cancel)}
                      sx={{
                        padding: '5px 5px'
                      }}
                    >
                      <Close
                        fontSize="small"
                        sx={{
                          width: '18px',
                          height: '18px'
                        }}
                      />
                    </IconButton>
                  ) : (
                    showMoreActions && (
                      <>
                        <IconButton
                          sx={{
                            padding: '5px 5px'
                          }}
                          aria-describedby={id}
                          onClick={handleClick}
                          title={t(T.moreActions)}
                        >
                          <MoreIcon />
                        </IconButton>
                        {!isPublicOrPrivateWorkspace &&
                          (row?.provider_id === sharePointProviderId ||
                            row?.provider_id === oneDriveProviderId ||
                            row?.permissions?.[
                              USER_RESOURCE_PERMISSION.ASSIGN_USER_ROLES
                            ]?.value) && (
                            <IconButton
                              onClick={handleShare}
                              title={t(T.share)}
                              sx={{
                                padding: '5px 5px'
                              }}
                            >
                              <ShareIcon />
                            </IconButton>
                          )}
                        <IconButton
                          title={
                            row?.isFavourite
                              ? t(T.unmarkFavourite)
                              : t(T.markFavourite)
                          }
                          onClick={handleAddFavourite}
                          sx={getCardIconsStyles(false)}
                          size="small"
                        >
                          {row?.isFavourite ? (
                            <StarRateIcon className="starRateIcon" />
                          ) : (
                            <StarOutlineIcon />
                          )}
                        </IconButton>
                        <Popover
                          id={id}
                          open={open}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          disablePortal={true}
                        >
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignContent={'flex-start'}
                            justifyContent={'flex-start'}
                            textAlign={'left'}
                            sx={{
                              backgroundColor: 'white'
                            }}
                          >
                            {!!row?.provider_id && (
                              <ActionButton onClick={handleSync}>
                                <SyncIcon
                                  fontSize="small"
                                  sx={{
                                    color: theme.colors.custom.iconColor
                                  }}
                                />
                                <Typography
                                  sx={{
                                    marginLeft: 1,
                                    color: theme.colors.custom.labelColor
                                  }}
                                >
                                  {t(T.sync)}
                                </Typography>
                              </ActionButton>
                            )}
                            {row?.provider_id !== sharePointProviderId &&
                              row?.provider_id !== oneDriveProviderId && (
                                <>
                                  {row?.permissions?.[
                                    USER_RESOURCE_PERMISSION
                                      .RENAME_FILES_FOLDERS
                                  ]?.value && (
                                    <ActionButton onClick={handleRenaming}>
                                      <RenameIcon />
                                      <Typography
                                        sx={{
                                          marginLeft: 1,
                                          color: theme.colors.custom.labelColor
                                        }}
                                      >
                                        {t(T.rename)}
                                      </Typography>
                                    </ActionButton>
                                  )}
                                </>
                              )}
                            {row?.permissions?.[
                              USER_RESOURCE_PERMISSION.DELETE_FILES_FOLDERS
                            ]?.value && (
                              <ActionButton onClick={handleDelete}>
                                <DeleteIcon />
                                <Typography
                                  sx={{
                                    marginLeft: 1,
                                    color: theme.colors.custom.labelColor
                                  }}
                                >
                                  {t(T.delete)}
                                </Typography>
                              </ActionButton>
                            )}
                            {(showSharePointButton || showOneDriveButton) && (
                              <Tooltip
                                title={
                                  showSharePointButton
                                    ? t(T.openInSharePoint)
                                    : t(T.openInOneDrive)
                                }
                                placement="right"
                                arrow
                              >
                                <ActionButton
                                  onClick={handleResourceProviderNavigateClick}
                                >
                                  <OpenInNewIcon />
                                  <Typography
                                    sx={{
                                      marginLeft: 1,
                                      color: theme.colors.custom.labelColor
                                    }}
                                  >
                                    {t(T.Navigate)}
                                  </Typography>
                                </ActionButton>
                              </Tooltip>
                            )}
                          </Box>
                        </Popover>
                      </>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
};

export default CollectionCard;
