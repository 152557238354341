export enum PERMISSION_TYPE {
  USER_APP_PERMISSIONS = 'user_app_perms',
  USER_RESOURCE_PERMISSION = 'user_resource_perms',
  USER_RESOURCE_CHILD_PERMISSIONS = 'user_resource_perms',
  ROLE_PERMISSIONS = 'role_perms'
}

export enum USER_APP_PERMISSIONS {
  CREATE_OWN_USER_GROUPS = 'create-own-user-groups',
  CREATE_USER_GROUPS = 'create-user-groups',
  CREATE_USERS = 'create-users',
  CREATE_ROLES = 'create-roles',
  CREATE_ROOT_FOLDER_CONTENT = 'create-root-folder-content',
  DELETE_OWN_USER_GROUPS = 'delete-own-user-groups',
  DELETE_USER_GROUPS = 'delete-user-groups',
  DELETE_USERS = 'delete-users',
  DELETE_ROLES = 'delete-roles',
  DELETE_ROOT_FOLDER_CONTENT = 'delete-root-folder-content',
  EDIT_OWN_USER_GROUPS = 'edit-own-user-groups',
  EDIT_USER_GROUPS = 'edit-user-groups',
  EDIT_USERS = 'edit-users',
  EDIT_ROLES = 'edit-roles',
  EDIT_ROOT_FOLDER_CONTENT = 'edit-root-folder-content',
  VIEW_OWN_USER_GROUPS = 'view-own-user-groups',
  VIEW_USER_GROUPS = 'view-user-groups',
  VIEW_USERS = 'view-users',
  VIEW_ROLES = 'view-roles',
  VIEW_ROOT_FOLDER_CONTENT = 'view-root-folder-content',
  VIEW_ANALYTICS = 'view-usage-analytics'
}

export enum USER_RESOURCE_PERMISSION {
  VIEW_FILES_FOLDERS = 'view-files-folders',
  QUERY_FILES_FOLDERS = 'query-files-folders',
  DOWNLOAD_FILES = 'download-files',
  CREATE_FOLDERS = 'create-folders',
  UPLOAD_FILES_FOLDERS = 'upload-files-folders',
  DELETE_FILES_FOLDERS = 'delete-files-folders',
  RENAME_FILES_FOLDERS = 'rename-files-folders',
  MOVE_FILES_FOLDERS = 'move-files-folders',
  COPY_FILES_FOLDERS = 'copy-files-folders',
  ASSIGN_USER_ROLES = 'access-control',
  TAG_UNTAG_FILES = 'tag-untag-files'
}

export enum RESOURCE_TYPE {
  WORKSPACE = 'RT_WORKSPACE',
  COLLECTION = 'RT_COLLECTION',
  FOLDER = 'RT_FOLDER',
  FILE = 'RT_FILE',
  NAMESPACE = 'RT_NAMESPACE',
  PUBLIC_WORKSPACE = 'RT_PUBLIC_WORKSPACE',
  FOUND_WORKSPACE = 'RT_FOUND_WORKSPACE',
  PRIVATE_WORKSPACE = 'RT_PRIVATE_WORKSPACE',
  FOUND_COLLECTION = 'RT_FOUND_COLLECTION'
}

export enum FAVOURITE_TYPE {
  RESOURCE = 'F'
}

export enum UPLOAD_TYPE {
  FILE = 'file',
  FOLDER = 'folder'
}

export enum FILE_UPLOADING_STATUS {
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
  FAILED = 'failed',
  PENDING = 'pending'
}

export enum DEFAULT_CONTENT_ROLES {
  VIEWER = 'Viewer',
  CONTRIBUTOR = 'Contributor',
  CONTROLLER = 'Controller'
}

export enum USER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITATION_EXPIRED = 'invitation_expired',
  INVITED = 'invited'
}

export enum CONTENT_SHARED_WITH_TYPE {
  USER = 'RT_USER',
  USER_GROUP = 'RT_USER_GROUP'
}

export enum ACCOUNT_TYPE {
  REGULAR_USER = 'L_AT_RU',
  ORGANIZATION_ADMINISTRATOR = 'L_AT_OA',
  WORKSPACE_ADMINISTRATOR = 'L_AT_WA',
  TRIAL_ADMINISTRATOR = 'L_AT_TA',
  TRIAL_USER = 'L_AT_TU'
}

export enum SUPPORTED_UPLOAD_FILES_FORMAT {
  PDF = 'application/pdf',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ODS = 'application/vnd.oasis.opendocument.spreadsheet',
  ODP = 'application/vnd.oasis.opendocument.presentation',
  ODT = 'application/vnd.oasis.opendocument.text'
}

export enum FILE_EXTENSIONS {
  PPT = 'ppt',
  PPTX = 'pptx',
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  XLS = 'xls',
  XLSX = 'xlsx',
  ODS = 'ods',
  ODP = 'odp',
  ODT = 'odt'
}

export enum LOOKUP {
  FILES = 'F_S',
  REACTION = 'R_T',
  REACTION_DETAILS = 'R_T_D',
  ACCOUNT_TYPE = 'LT_A_T',
  USERS = 'U_S',
  LT_SYNC_STATUS = 'LT_SYNC_STATUS',
  DOCUMENT_ANALYSIS_STATUS = 'LT_DA_S'
}

export const enum DOCUMENT_STATUS {
  PROCESSED = 'F_S_PR',
  INPROGRESS = 'F_S_UP',
  RETRYING = 'F_S_RE',
  UNSUPPORTED = 'F_S_US',
  WAITING_FOR_FILE = 'F_S_WF',
  LICENSE_LIMIT_REACHED = 'F_S_LLR'
}

export const enum PROVIDER_STATUS {
  SYNC_STARTING = 'L_SS_SSG',
  SYNC_STARTED = 'L_SS_SSD',
  SYNC_RETRYING = 'L_SS_RET',
  SYNC_FAILED = 'L_SS_SFD',
  SYNC_COMPLETED = 'L_SS_SCD'
}

export const enum REACTION {
  THUMBS_DOWN = 'R_T_TD',
  THUMBS_UP = 'R_T_TU'
}

export enum ALERT {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info'
}

export enum VALUE_EXPR {
  ITEM = 'item',
  ID = 'id',
  NAME = 'name'
}

export enum RESPONSE_FEEDBACK_DETAILS {
  R_T_CWD = 'feedback1',
  R_T_OSD = 'feedback2',
  R_T_HOU = 'feedback3',
  R_T_NHF = 'feedback4'
}

export enum ACTION_TYPES {
  EDIT = 'edit',
  DELETE = 'delete',
  CANCEL = 'cancel',
  COMMIT = 'commit'
}

export enum CHAT_SOURCE_TYPES {
  ChatGPT = 'C',
  EmpowerGPT = 'E'
}

export enum USER_TYPE {
  NON_AD = 'NON-AD',
  LDAP = 'LDAP',
  AZURE = 'AZURE'
}

export enum RESOURCE_PROVIDERS {
  SHAREPOINT = 'Sharepoint',
  ONEDRIVE = 'OneDrive'
}

export enum USAGE_STATS_TYPE {
  F = 'File Processing',
  E = 'Enterprise Knowledge Chat',
  C = 'World Knowledge Chat'
}

export enum AzureDetailsFields {
  CLIENT_ID = 'client_id',
  TENANT_ID = 'tenant_id',
  CLIENT_SECRET = 'client_secret'
}

export enum AiModels {
  mistralLarge = 'Mistral-large',
  llama370BInstruct = 'Llama-3-70B-Instruct',
  llama38BInstruct = 'Llama-3-1-8b-Instruct',
  gpt4Turbo = 'gpt-4-turbo',
  gpt432k = 'gpt-4-32k',
  gpt4o = 'gpt-4o',
  cohere = 'cohere',
  gpt35Turbo16k = 'gpt-3.5-turbo-16k',
  gpt35Turbo = 'gpt-3.5-turbo',
  gpt4 = 'gpt-4'
}
export enum FileLanguage {
  en = 'English',
  de = 'German',
  it = 'Italian'
}

export enum ResourceTypes {
  Workspace = 'Workspace',
  Collection = 'Collection',
  Folder = 'Folder'
}

export enum OnboardingStepTypes {
  CHAT = 'chat',
  DOCUMENT = 'document',
  USER = 'user'
}

export enum DOC_ANALYST_TABS {
  ANALYSIS = 'analysis',
  DATASET = 'dataset',
  QUESTIONNAIRE = 'questionnaire'
}

export const enum DOCUMENT_ANALYSIS_STATUS {
  COMPLETED = 'L_DA_CM',
  INPROGRESS = 'L_DA_IP',
  CANCELLED = 'L_DA_CN',
  FAILED = 'L_DA_FL',
  PARTIALLY_COMPLETED = 'L_DA_PC',
  REGENERATING = 'L_DA_RGR'
}

export enum ANALYSIS_QUESTION_STATUS {
  ANSWERED = 'L_DAC_AD',
  CREATED = 'L_DAC_CR',
  REGENERATING = 'L_DAC_RG',
  GENERATING = 'L_DAC_GR',
  FAILED = 'L_DAC_FD'
}

export enum PubsubEvents {
  STREAM = 'stream',
  CHAT_META = 'chat_meta',
  CHAT_STEP = 'chat_step',
  ERROR = 'error'
}
