import { USER_COLUMNS, getAccountTypeDetails } from '../utils';
import { RootState, useSelector } from 'src/redux/store';
import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/Select';
import { useTheme } from '@mui/material';
import { LOOKUP } from 'src/types/enum';
import { IAccountType } from '../types';
import { TextFormatter } from '../ColumnFormatters/Text';
import { StatusFormatter } from '../ColumnFormatters/Status';
import UserGroupsFormatter from '../ColumnFormatters/UserGroups';

type EditorProps = {
  cellProps: TableEditRow.CellProps;
};
export const getEditorComponent = ({ cellProps }: EditorProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { lookups } = useSelector((state: RootState) => state.data);
  const { user } = useSelector((state: RootState) => state.auth);
  const { column } = cellProps;
  const accountTypes = useMemo<IAccountType[]>(
    () => getAccountTypeDetails(lookups[LOOKUP.ACCOUNT_TYPE], t),
    [user, i18n.language, lookups]
  );

  const renderValue = useCallback(
    (value) => accountTypes?.find((_value) => _value.id === value)?.name,
    [accountTypes]
  );

  if (column.name.includes(USER_COLUMNS.NAME)) {
    return (
      <TableEditRow.Cell {...cellProps} editingEnabled={false}>
        <TextFormatter value={cellProps.value} />
      </TableEditRow.Cell>
    );
  } else if (column.name.includes(USER_COLUMNS.ACCOUNT_TYPE)) {
    const handleChange = (event: any) => {
      const item = lookups[LOOKUP.ACCOUNT_TYPE].find(
        (item) => item.id === event.target.value
      );
      cellProps.onValueChange({ ...item });
    };

    return (
      <TableEditRow.Cell {...cellProps}>
        <Select
          id="AccountType"
          value={cellProps?.value?.id}
          onChange={handleChange}
          width="100%"
          sx={{
            backgroundColor: theme.colors.alpha.trueWhite[100]
          }}
          dataSource={accountTypes}
          renderValue={renderValue}
        />
      </TableEditRow.Cell>
    );
  } else if (column.name.includes(USER_COLUMNS.STATUS)) {
    return (
      <TableEditRow.Cell {...cellProps} editingEnabled={false}>
        <StatusFormatter value={cellProps.value} />
      </TableEditRow.Cell>
    );
  } else if (column.name.includes(USER_COLUMNS.USER_GROUPS)) {
    return (
      <TableEditRow.Cell {...cellProps} editingEnabled={false}>
        <UserGroupsFormatter value={cellProps.value} />
      </TableEditRow.Cell>
    );
  }
};
