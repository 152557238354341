import { TabContext } from '@mui/lab';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as AnalysisIcon } from 'src/assets/miniExperts/DocAnalyst/analysisIcon.svg';
import { ReactComponent as DatasetIcon } from 'src/assets/miniExperts/DocAnalyst/dataCollectionsIcon.svg';
import {
  StyledTab,
  StyledTabs
} from 'src/content/MiniExperts/DocAnalyst/styles';
import { RootState } from 'src/redux/store';
import { DOC_ANALYST_TABS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

type Props = {
  title: string;
  showTabs?: boolean;
  setSelectedTab?: React.Dispatch<React.SetStateAction<string>>;
  selectedTab?: string;
};

const basePath = '/mini-experts/doc-analyst/';

const PageHeader = ({
  title,
  showTabs = false,
  selectedTab = '',
  setSelectedTab = () => {}
}: Props) => {
  const {
    docAnalyst: { currentlyRunningAnalysis },
    auth: { maxAnalysisLimit }
  } = useSelector((state: RootState) => state);

  const { t } = useTranslation();

  return (
    <Paper elevation={0}>
      <Grid
        container
        sx={{ height: '65px' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item>
          <Box display={'flex'} alignItems={'center'} gap={'16px'}>
            <Typography fontSize={'32px'} fontWeight={500}>
              {title}
            </Typography>
            {showTabs && (
              <TabsPanel
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PageHeader;

const TabsPanel = ({ setSelectedTab, selectedTab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleTabChange = (
    _: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    let url = `${basePath}${newValue}`;
    navigate(url, {
      state: { selectedTab: newValue }
    });
  };

  return (
    <StyledTabs
      onChange={handleTabChange}
      aria-label="doc-analysis-tab-selection"
      value={selectedTab}
    >
      <StyledTab
        iconPosition="start"
        icon={<AnalysisIcon />}
        label={t(T.analysis)}
        value={DOC_ANALYST_TABS.ANALYSIS}
      />
      <StyledTab
        iconPosition="start"
        icon={<DatasetIcon />}
        label={t(T.dataset)}
        value={DOC_ANALYST_TABS.DATASET}
      />
      <StyledTab
        iconPosition="start"
        icon={<PlaylistAddCheckIcon />}
        label={t(T.questionnaire)}
        value={DOC_ANALYST_TABS.QUESTIONNAIRE}
      />
    </StyledTabs>
  );
};
