import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { IconButton, OutlinedInput, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useMutation from 'src/hooks/useMutation';
import {
  addUpdateAnalysisHistoryContext,
  updateQuestion
} from 'src/redux/slices/docAnalyst';
import { RootState } from 'src/redux/store';
import { useUpdateAnalysisDetailMutation } from 'src/services/api';
import { AnalysisResponseProps } from 'src/types/docAnalyst';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface EditInputProps {
  data: AnalysisResponseProps;
  question: string;
  handleQuestionEditing: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  updateRowData: (val: AnalysisResponseProps) => void;
  setQuestion: (question: string) => void;
}
const EditInput: React.FC<EditInputProps> = ({
  data,
  question,
  handleQuestionEditing,
  handleKeyDown,
  setIsEditing,
  updateRowData,
  setQuestion
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    initiateAnalysis: { selectedAnalysis }
  } = useSelector((state: RootState) => state.docAnalyst);
  const [
    updateAnalysisDetail,
    { isLoading: isUpdatingAnalysisDetail, data: updatedAnalysisDetail }
  ] = useMutation({
    api: useUpdateAnalysisDetailMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const handleMouseDown = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    []
  );

  const handleEditClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setIsEditing(false);
      if (data?.question !== question)
        updateAnalysisDetail({
          params: { params: { id: data.id, question: question } },
          successMsg: t(T.questionUpdatedSuccess),
          successCallback(data) {
            updateRowData(data?.data?.[0]);
            dispatch(updateQuestion({ questionEdited: true }));
            dispatch(
              addUpdateAnalysisHistoryContext({
                analysisId: selectedAnalysis.id,
                isAnalysisStreaming: true
              })
            );
          }
        });
    },
    [question]
  );

  const handleCloseInputEdit = useCallback(() => {
    setQuestion(data.question);
    setIsEditing(false);
  }, []);

  return (
    <OutlinedInput
      autoFocus
      fullWidth
      multiline
      maxRows={2}
      minRows={2}
      onBlur={handleCloseInputEdit}
      sx={{
        padding: '10px 8px',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#26389A'
        },
        '&.Mui-focused .MuiInputBase-input': {
          color: '#26389A'
        }
      }}
      value={question}
      onChange={handleQuestionEditing}
      onKeyDown={handleKeyDown}
      endAdornment={
        <Stack direction="row">
          <IconButton
            onMouseDown={handleMouseDown}
            onClick={handleEditClick}
            size="small"
          >
            <DoneIcon color="secondary" fontSize="small" />
          </IconButton>
          <IconButton
            onMouseDown={handleMouseDown}
            onClick={handleCloseInputEdit}
            size="small"
          >
            <CloseIcon color="error" fontSize="small" />
          </IconButton>
        </Stack>
      }
    />
  );
};

export default EditInput;
