import { Popper, autocompleteClasses, IconButton, styled } from '@mui/material';

export const StyledPopper = styled(({ optionStyles, maxHeight, ...props }: any) => (
  <Popper {...props} />
))(({ optionStyles = false, maxHeight = 180 }) => ({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    maxHeight,
    width: '100%',
    overflowY: 'auto',
    ...(optionStyles && {
      '& .MuiAutocomplete-option': {
        backgroundColor: '#ffffff',
        padding: '0px!important'
      }
    })
  }
}));
