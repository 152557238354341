import { Getter } from '@devexpress/dx-react-core';
import {
  ChangeSet,
  CustomPaging,
  DataTypeProvider,
  EditingState,
  PagingState
} from '@devexpress/dx-react-grid';
import {
  PagingPanel,
  Table,
  TableEditColumn,
  Grid as TableGrid,
  TableHeaderRow,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import {
  CustomTableRow,
  Root,
  TableRowCell,
  TableRowHeader
} from 'src/components/Grid/styles';
import GridContainer from 'src/components/GridContainer';
import Loader from 'src/components/Loader';
import { getColumnExtensionsWidth } from 'src/content/Documents/utils/utils';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import {
  useDeleteQuestionnaireMutation,
  useLazyGetQuestionnairesQuery
} from 'src/services/api';
import { CommandComponentProps } from 'src/types/custom_component';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { CompactTableCellHeader, CustomEditActionCell } from '../../styles';
import {
  getActionColumnSorted,
  getRowId,
  PAGE_SIZES,
  QUESTIONNAIRE_COLUMNS,
  questionnaireColumnExtensions,
  questionnaireColumns
} from '../../utils/utils';
import CreateQuestionnaireModal from '../CreateQuestionnaireModal';
import RowAction from './RowButtons';
import { debounce } from 'lodash';
import format from 'date-fns/format';
import { FilesCountFormatter } from '../../utils/ColumnFormatters';
import Filters from './Filters';

function QuestionnaireList() {
  const { t, i18n } = useTranslation();
  const [clonedRowId, setClonedRowId] = useState<number>();
  const [editedRow, setEditedRow] = useState<any>(null);
  const [deletedRowId, setDeletedRowId] = useState<string | number>();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const { sidebarToggle } = useContext(SidebarContext);
  const [columnExtensions, setColumnExtensions] = useState<
    VirtualTable.ColumnExtension[]
  >([]);
  const gridRef = useRef<HTMLDivElement>(null);
  const [isCreateQuestionnaireOpen, setIsCreateQuestionnaireOpen] =
    useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZES[0]);
  const [search, setSearch] = useState<string>('');
  const [dateCreated, setDateCreated] = useState<Date>(null);

  const [fetchQuestionnaires, { isLoading, isFetching, data }] = useLazyQuery({
    api: useLazyGetQuestionnairesQuery,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [deleteQuestionnaire] = useMutation({
    api: useDeleteQuestionnaireMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const currentRows = data?.data || [];

  const debouncedFetchQuestionnaires = useCallback(
    debounce(fetchQuestionnaires, 300),
    [fetchQuestionnaires]
  );

  useEffect(() => {
    debouncedFetchQuestionnaires({
      params: {
        params: {
          p: page,
          ps: pageSize,
          s: search,
          created_date: dateCreated ? format(dateCreated, 'yyyy-MM-dd') : null
        }
      }
    });
  }, [page, pageSize, search, dateCreated]);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
    setPage(0);
  }, []);

  useEffect(() => {
    setColumnExtensions(
      getColumnExtensionsWidth(
        questionnaireColumnExtensions,
        gridRef.current?.clientWidth || 0
      )
    );
  }, [sidebarToggle, gridRef.current]);

  const translatedQuestionnaireColumns = useMemo(
    () =>
      questionnaireColumns.map((col) => ({
        ...col,
        title: t(col.title)
      })),
    [i18n.language]
  );

  const Command = useCallback((props: CommandComponentProps) => {
    return <RowAction {...props} setClonedRowId={setClonedRowId} />;
  }, []);

  const onCommitChanges = ({ deleted }: ChangeSet) => {
    if (deleted) {
      setDeletedRowId(deleted[0]);
      setIsDeleteOpen(true);
      return;
    }
  };

  const handleClose = useCallback(() => {
    setIsDeleteOpen(false);
    setDeletedRowId(undefined);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDeleteOpen(false);
    deleteQuestionnaire({
      params: {
        params: {
          id: deletedRowId
        }
      },
      successMsg: T.questionnaireDeleteSuccess,
      fallbackMsg: T.questionnaireDeleteFailed
    });
  }, [deletedRowId]);

  const TableRow = useCallback(({ row, ...restProps }: Table.DataRowProps) => {
    const handleRowClick = () => {
      setEditedRow({ id: row.id, name: row.name });
    };
    return <CustomTableRow row={row} {...restProps} onClick={handleRowClick} />;
  }, []);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleCreateQuestionnaire = useCallback(() => {
    setIsCreateQuestionnaireOpen(true);
  }, []);

  const handleCloseQuestionnaireModal = useCallback(() => {
    setEditedRow(null);
    setClonedRowId(null);
    setIsCreateQuestionnaireOpen(false);
  }, []);

  const clearAllFilters = useCallback(() => {
    setSearch('');
    setDateCreated(null);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box maxWidth="100%" display="flex" flexDirection="column" rowGap="16px">
        <Filters
          search={search}
          setSearch={setSearch}
          dateCreated={dateCreated}
          setDateCreated={setDateCreated}
          clearAllFilters={clearAllFilters}
          handleCreateQuestionnaire={handleCreateQuestionnaire}
        />

      <Box maxWidth="100%" height="100%" ref={gridRef}>
        <GridContainer minHeight={'100%'} length={1}>
          <TableGrid
            rows={currentRows}
            columns={translatedQuestionnaireColumns}
            getRowId={getRowId}
            rootComponent={Root}
          >
            <DataTypeProvider
              for={[QUESTIONNAIRE_COLUMNS.QUESTIONS_COUNT]}
              formatterComponent={FilesCountFormatter}
            />

              <VirtualTable
                rowComponent={TableRow}
                cellComponent={TableRowCell}
                columnExtensions={columnExtensions}
              />
              <TableHeaderRow
                rowComponent={TableRowHeader}
                cellComponent={CompactTableCellHeader}
              />

              <PagingState
                currentPage={page}
                onCurrentPageChange={setPage}
                pageSize={pageSize}
                onPageSizeChange={handlePageSizeChange}
              />
              <CustomPaging totalCount={data?.rc} />
              <PagingPanel
                pageSizes={PAGE_SIZES}
                messages={{
                  rowsPerPage: t(T.rowsPerPage)
                }}
              />

              <EditingState onCommitChanges={onCommitChanges} />
              <TableEditColumn
                showEditCommand
                showDeleteCommand
                commandComponent={Command}
                cellComponent={CustomEditActionCell}
              />

              <Getter name="tableColumns" computed={getActionColumnSorted} />
            </TableGrid>
          </GridContainer>
        </Box>
      </Box>

      {isDeleteOpen && (
        <ConfirmationDialog
          message={T.questionnaireDeleteConfirmation}
          onClose={handleClose}
          onConfirm={handleDelete}
          isOpen={isDeleteOpen}
        />
      )}

      {(isCreateQuestionnaireOpen || clonedRowId || editedRow) && (
        <CreateQuestionnaireModal
          isOpen={!!(isCreateQuestionnaireOpen || clonedRowId || editedRow)}
          onClose={handleCloseQuestionnaireModal}
          cloneID={clonedRowId}
          questionnaireToEdit={editedRow}
        />
      )}
    </>
  );
}

export default QuestionnaireList;
