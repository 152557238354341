import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { IActionButtonProps } from 'src/types/header';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const ActionButtons = (
  showAddButton: boolean = false,
  isAddingNewRow: boolean = false,
  handleOnClickAdd: () => void = () => {}
): IActionButtonProps[] => {
  const { t } = useTranslation();
  return [
    {
      visible: showAddButton,
      variant: 'outlined',
      color: 'primary',
      icon: Add,
      disabled: isAddingNewRow,
      tooltipMsg: t(T.workSpaceInfoMessage),
      popover: true,
      onClick: handleOnClickAdd,
      text: t(T.add),
      popoverElements: [
        {
          disabled: isAddingNewRow,
          onClick: handleOnClickAdd,
          text: t(T.new),
          visible: showAddButton
        }
      ]
    }
  ];
};
