import {
  KeyboardArrowRightOutlined,
  KeyboardArrowLeftOutlined
} from '@mui/icons-material';
import { DatePicker } from '@mui/lab';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

type Props = {
  onMonthChange: (date: Date) => void;
  value: Date;
  loading: boolean;
};

const MonthPicker = ({ onMonthChange, value, loading = false }: Props) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const handleChange = (date: Date) => {
    onMonthChange(date);
  };

  const decrementMonth = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const newDate = new Date(value);
      newDate.setMonth(newDate.getMonth() - 1);
      onMonthChange(newDate);
    },
    [value]
  );

  const incrementMonth = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      const newDate = new Date(value);
      newDate.setMonth(newDate.getMonth() + 1);
      onMonthChange(newDate);
    },
    [value]
  );

  const isDisabled = useMemo(
    () =>
      new Date(value).getMonth() >= new Date().getMonth() &&
      new Date(value).getFullYear() >= new Date().getFullYear(),
    [value]
  );

  const openCalendar = useCallback(() => {
    setIsCalendarOpen(true);
  }, []);

  const closeCalendar = useCallback(() => {
    setIsCalendarOpen(false);
  }, []);

  return (
    <DatePicker
      value={value}
      views={['year', 'month']}
      onChange={handleChange}
      disabled={loading}
      open={isCalendarOpen}
      onClose={closeCalendar}
      inputFormat="MMM - yyyy"
      maxDate={new Date()}
      disableFuture
      openTo="month"
      disableMaskedInput
      renderInput={(
        props: TextFieldProps
      ): React.ReactElement<any, string | React.JSXElementConstructor<any>> => (
        <TextField
          {...props}
          inputProps={{
            ...props.inputProps,
            readOnly: true
          }}
          sx={{
            '& .MuiInputBase-input': {
              paddingY: 1.0,
              paddingX: 0,
              textAlign: 'center',
              width: '120px'
            }
          }}
          InputProps={{
            startAdornment: (
              <IconButton
                sx={{
                  padding: 0
                }}
                onClick={decrementMonth}
                disabled={loading}
              >
                <KeyboardArrowLeftOutlined />
              </IconButton>
            ),
            endAdornment: (
              <IconButton
                sx={{
                  padding: 0
                }}
                disabled={isDisabled || loading}
                onClick={incrementMonth}
              >
                <KeyboardArrowRightOutlined />
              </IconButton>
            )
          }}
          onClick={openCalendar}
        />
      )}
    />
  );
};

export default MonthPicker;
