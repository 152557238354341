import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import PageHeader from '../DocAnalyst/PageHeader';
import { menuOptions } from '../miniExpertsUtils';
import { ContainerWrapper } from '../styles';
import MenuItem from './MenuItem';
import { Grid } from '@mui/material';

const MainMenu: React.FC = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <ContainerWrapper>
        <PageHeader title={t(T.miniExperts)} />
        <Grid container spacing={2}>
          {menuOptions.map((option, index) => (
            <Grid key={index} item xs={12} lg={6}>
              <MenuItem
                title={option.title}
                description={option.description}
                navLink={option.navLink}
                ImageIcon={option.icon}
                mainFeatures={t(option.mainFeatures).split('$$')}
              />
            </Grid>
          ))}
        </Grid>
      </ContainerWrapper>
    </React.Fragment>
  );
};

export default MainMenu;
