export type FilterChip = {
  id: number;
  value: string;
  filterKey: string;
};

export const extractFilters = (data: any): FilterChip[] => {
  let filterChips: FilterChip[] = [];

  Object.keys(data).forEach((key) => {
    const value = data[key];

    if (value && typeof value === 'object' && 'id' in value) {
      filterChips.push({
        id: value.id,
        value: value.name || value.username || '',
        filterKey: key
      });
    } else if (Array.isArray(value)) {
      const arrayChips = value.map((item) => ({
        id: item.id,
        value: item.name,
        filterKey: key
      }));
      filterChips = [...filterChips, ...arrayChips];
    }
  });

  return filterChips;
};
