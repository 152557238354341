import { IconButton } from '@mui/material';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';
import { ReactComponent as DownloadIcon } from 'src/assets/download.svg';
import { CommandComponentProps } from 'src/types/custom_component';
import { ACTION_TYPES } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { StatusCodesWithExportDisabled } from '../../utils/utils';

interface RowActionProps extends CommandComponentProps {
  handleExport: (analysis: any) => void;
}

const RowAction = ({
  id,
  onExecute,
  handleExport,
  rowData
}: RowActionProps) => {
  const { t } = useTranslation();

  const handleDownload = (event: MouseEvent) => {
    event.stopPropagation();
    handleExport(rowData);
  };

  const handleDelete = (event: MouseEvent) => {
    event.stopPropagation();
    onExecute();
  };
  if (id === ACTION_TYPES.EDIT) {
    if (StatusCodesWithExportDisabled.includes(rowData.status_code)) {
      return null;
    }

    return (
      <IconButton onClick={handleDownload} title={t(T.download)}>
        <DownloadIcon />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={handleDelete} title={t(T.delete)}>
      <DeleteIcon />
    </IconButton>
  );
};

export default RowAction;
