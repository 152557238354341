import { StringKeys } from 'src/types/base';
import { chartColors } from '../../utils';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const DOCUMENTS = {
  processed: T.F_S_PR,
  retrying: T.F_S_RE,
  unsupported: T.F_S_US
} as const;

export const USERS = {
  active: T.active,
  inactive: T.inactive,
  invited: T.invited
} as const;

export const getDocumentColor = (type) => {
  switch (type) {
    case DOCUMENTS.processed:
      return chartColors.primary;
    case DOCUMENTS.retrying:
      return chartColors.secondary;
    case DOCUMENTS.unsupported:
      return chartColors.tertiary;
    default:
      return chartColors.default;
  }
};

export const getUserColor = (type) => {
  switch (type) {
    case USERS.active:
      return chartColors.primary;
    case USERS.inactive:
      return chartColors.secondary;
    case USERS.invited:
      return chartColors.tertiary;
    default:
      return chartColors.default;
  }
};

export const categorizeLibraryData = (data: StringKeys[]) => {
  let workspaces: StringKeys = {};
  let documents: StringKeys[] = [];

  data?.forEach((item) => {
    if (item.name === 'Workspace') {
      workspaces = item;
    } else if (Object.values(DOCUMENTS).includes(item.name)) {
      documents.push({
        ...item,
        color: getDocumentColor(item.name)
      });
    }
  });
  return { workspaces, documents };
};

export const categorizeUserData = (data: StringKeys) => {
  let users: StringKeys[] = [];

  Object.keys(data || {})?.forEach((key) => {
    const item = data[key];
    const name = USERS[key];
    if (Object.keys(USERS).includes(key)) {
      users.push({
        ...item,
        name: name,
        count: item,
        color: getUserColor(name)
      });
    }
  });
  return { users };
};
