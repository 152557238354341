import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRole } from 'src/types/user';
import { AiModel, StringKeys } from 'src/types/base';
import { IResourceProvider } from 'src/types/api';
import { RESOURCE_PROVIDERS } from 'src/types/enum';
import { IGetIdentityProviderResponse } from 'src/types/settings';

type Permission = {
  created_by: string;
  created_on: string;
  desc_id: number;
  description: string;
  modified_by: string;
  modified_on: string;
  name: string;
  rid: number;
  role: string;
  type_id: number;
  value: boolean;
};

type DataReducer = {
  language: string;
  isLangInitialized: boolean;
  allKCRoles: string[];
  allKCRolesDetails: IRole[];
  isLoadingRoles: boolean;
  uploadTransactionId: string;
  documents: {
    [id: number]: {
      file: Blob;
    };
  };
  lookups: { [lookup_type: string]: any[] };
  userAppPermissions: { [key: string]: Permission };
  applicationResources: StringKeys;
  userResourceProviders: IResourceProvider[];
  userIdentityProviders: IGetIdentityProviderResponse['data'];
  sharePointProviderId: number;
  oneDriveProviderId: number;
  userFavourites: StringKeys;
  isOnboarded: boolean;
  configuredModels: AiModel[];
  areModelsInitialised: boolean;
  chatDefaultModel: AiModel;
  isFavouritesFetching: boolean;
};

type ApplicationResources = {
  applicationResources: StringKeys;
};

const initialState: DataReducer = {
  language: 'en',
  isLangInitialized: false,
  allKCRoles: [],
  allKCRolesDetails: [],
  isLoadingRoles: true,
  uploadTransactionId: '',
  documents: {},
  lookups: {},
  userAppPermissions: {},
  applicationResources: { User: '' },
  userResourceProviders: [],
  userIdentityProviders: [],
  sharePointProviderId: 0,
  oneDriveProviderId: 0,
  userFavourites: {},
  isOnboarded: false,
  configuredModels: null,
  areModelsInitialised: false,
  chatDefaultModel: null,
  isFavouritesFetching: false
};

const GlobalData = createSlice({
  name: 'data',
  initialState,
  reducers: {
    updateLookups(state, action) {
      state.lookups = action.payload;
    },
    updateUserLanguage(state, action) {
      state.language = action.payload || state.language;
      state.isLangInitialized = true;
    },
    updateUserOnboarding(state, action) {
      state.isOnboarded = action.payload || state.isOnboarded;
    },
    updateChatDefaultModel(state, { payload }: { payload: AiModel }) {
      state.chatDefaultModel = payload || state.chatDefaultModel;
    },
    updateConfiguredModels(state, action) {
      state.configuredModels = action.payload || state.configuredModels;
      state.areModelsInitialised = true;
    },
    setAllKCRole(state, action: PayloadAction<IRole[]>) {
      let rolesList: string[] = action.payload.map((item: any) => item.name);
      state.allKCRoles = rolesList;
      state.allKCRolesDetails = action.payload;
      state.isLoadingRoles = false;
    },
    updateUploadTransactionId(state, { payload }) {
      state.uploadTransactionId = payload;
    },
    addDocument(
      state,
      { payload }: { payload: { id: number | string; file: Blob } }
    ) {
      state.documents[payload.id] = { file: payload.file };
    },
    removeDocument(state, { payload }) {
      delete state.documents[payload];
    },
    setUserAppPermissions(state, { payload }) {
      state.userAppPermissions = payload;
    },
    setApplicationResources(
      state: ApplicationResources,
      action: PayloadAction<{
        applicationResources: StringKeys;
      }>
    ) {
      const { applicationResources } = action.payload;
      state.applicationResources = applicationResources;
    },
    setUserResourceProvider(state, { payload }) {
      state.userResourceProviders = payload;
      state.sharePointProviderId = +payload.find(
        ({ type, is_configuration_completed }) =>
          type === RESOURCE_PROVIDERS.SHAREPOINT && is_configuration_completed
      )?.id;
      state.oneDriveProviderId = +payload.find(
        ({ type, is_configuration_completed }) =>
          type === RESOURCE_PROVIDERS.ONEDRIVE && is_configuration_completed
      )?.id;
    },
    setUserIdentityProviders(state, { payload }) {
      state.userIdentityProviders = payload;
    },
    setUserFavourites(state, { payload }) {
      state.userFavourites = payload;
      state.isFavouritesFetching = false;
    },
    setIsFavouritesFetching(state, { payload }) {
      state.isFavouritesFetching = payload;
    }
  }
});

export default GlobalData;
export const reducer = GlobalData.reducer;
export const {
  updateLookups,
  updateUserLanguage,
  updateUserOnboarding,
  updateConfiguredModels,
  updateChatDefaultModel,
  updateUploadTransactionId,
  setAllKCRole,
  addDocument,
  removeDocument,
  setUserAppPermissions,
  setApplicationResources,
  setUserResourceProvider,
  setUserIdentityProviders,
  setUserFavourites,
  setIsFavouritesFetching
} = GlobalData.actions;
