import { ArticleOutlined } from '@mui/icons-material';
import type { ReactNode } from 'react';
import { IS_MINI_EXPERTS_DISABLED } from 'src/config';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { ReactComponent as DataLibraryIcon } from 'src/assets/sidebarIcon/datalibrary.svg';
import { ReactComponent as MiniExpertsIcon } from 'src/assets/sidebarIcon/Mini Expert.svg';
export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const getMenuItems = (userAppPermissions) => {
  const menuItems = !IS_MINI_EXPERTS_DISABLED
    ? [
        {
          heading: '',
          items: [
            {
              name: 'Mini Experts',
              icon: MiniExpertsIcon,
              link: '/mini-experts'
            }
          ]
        }
      ]
    : [];
  if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROOT_FOLDER_CONTENT]?.value
  ) {
    menuItems.push({
      heading: '',
      items: [
        {
          name: 'Workspaces',
          icon: DataLibraryIcon,
          link: '/data-library'
        }
      ]
    });
    return menuItems;
  }
  return menuItems;
};

export default getMenuItems;
