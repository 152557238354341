import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useMutation from 'src/hooks/useMutation';
import {
  addUpdateAnalysisHistoryContext,
  updateInitiateAnalysisState
} from 'src/redux/slices/docAnalyst';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import {
  useCreateAnalysisMutation,
  useUpdateAnalysisMutation
} from 'src/services/api';
import { DOCUMENT_ANALYSIS_STATUS } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import DatasetSelection from './DatasetSelection';
import Header from './Header';
import InitiateAnalysisPlaceholder from './InitiateAnalysisPlaceholder';
import QuestionnaireSelection from './QuestionnaireSelection';
import { ContentContainer } from './styles';
import AnalysisDetailGrid from './AnalysisDetailGrid';

const InitiateAnalysis = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    initiateAnalysis: {
      isSelectingDataset,
      isSelectingQuestionnaire,
      title,
      selectedDataset,
      selectedQuestionnaire,
      selectedModel,
      isAnalysisView,
      selectedAnalysis
    },
    analysisHistoryContexts
  } = useSelector((data: RootState) => data.docAnalyst);

  const { maxAnalysisLimit } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [
    regenerateAnalysis,
    { isLoading: isRegeneratingAnalysis, data: regeneratedAnalysisData }
  ] = useMutation({
    api: useUpdateAnalysisMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [initiateAnalysis, { isLoading, data: currentAnalysis }] = useMutation({
    api: useCreateAnalysisMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const handleInitiateAnalysis = useCallback(() => {
    initiateAnalysis({
      params: {
        params: {
          title,
          dataset_id: selectedDataset?.id,
          questionnaire_id: selectedQuestionnaire?.id,
          model_id: selectedModel?.id
        }
      }
    });
  }, [title, selectedDataset, selectedQuestionnaire, selectedModel]);

  const handleRegenerateAnalysis = useCallback(() => {
    regenerateAnalysis({
      params: {
        params: {
          id: selectedAnalysis?.id,
          regenerate_analysis: true
        }
      }
    });
  }, [selectedAnalysis?.id]);

  const isDisableRunAnalysisButton = useMemo((): boolean => {
    return (
      !(selectedDataset?.name && selectedQuestionnaire?.name && title) ||
      analysisHistoryContexts[selectedAnalysis?.id]?.isAnalysisStreaming
    );
  }, [selectedDataset, selectedQuestionnaire, title, analysisHistoryContexts]);

  useEffect(() => {
    if (currentAnalysis?.data?.length) {
      const currentAnalysisData = currentAnalysis.data[0];

      dispatch(
        updateInitiateAnalysisState({
          isDialogOpen: true,
          dialogTitle: t(T.analysisDetail),
          selectedAnalysis: {
            id: currentAnalysisData.id,
            status:
              currentAnalysisData.status_code ||
              DOCUMENT_ANALYSIS_STATUS.INPROGRESS
          },
          title: currentAnalysisData.name,
          selectedDataset: {
            id: currentAnalysisData.dataset_id,
            name: currentAnalysisData.dataset_name || selectedDataset?.name
          },
          selectedQuestionnaire: {
            id: currentAnalysis?.questionnaire_id,
            name:
              currentAnalysisData.questionnaire_name ||
              selectedQuestionnaire?.name,
            isEditable:
              currentAnalysisData.is_editable ||
              selectedQuestionnaire?.isEditable
          },
          isAnalysisView: true
        })
      );
      dispatch(
        addUpdateAnalysisHistoryContext({
          analysisId: currentAnalysisData.id,
          isAnalysisStreaming: true
        })
      );
    }
  }, [currentAnalysis]);

  useEffect(() => {
    if (regeneratedAnalysisData) {
      dispatch(
        updateInitiateAnalysisState({
          selectedAnalysis: {
            id: selectedAnalysis.id,
            status: DOCUMENT_ANALYSIS_STATUS.REGENERATING
          }
        })
      );
      dispatch(
        addUpdateAnalysisHistoryContext({
          analysisId: selectedAnalysis.id,
          isAnalysisStreaming: true
        })
      );
    }
  }, [regeneratedAnalysisData]);

  const showInitiateAnalysisPlaceholder =
    !isSelectingDataset && !isSelectingQuestionnaire && !isAnalysisView;

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      rowGap={'16px'}
      ref={containerRef}
    >
      <Header
        handleInitiateAnalysis={handleInitiateAnalysis}
        handleRegenerateAnalysis={handleRegenerateAnalysis}
        isDisableRunAnalysisButton={isDisableRunAnalysisButton}
      />

      {showInitiateAnalysisPlaceholder && (
        <InitiateAnalysisPlaceholder
          handleInitiateAnalysis={handleInitiateAnalysis}
          isDisableRunAnalysisButton={isDisableRunAnalysisButton}
        />
      )}
      {isSelectingDataset && (
        <ContentContainer>
          <DatasetSelection
            selectedId={selectedDataset?.id}
            width={containerRef.current?.clientWidth}
          />
        </ContentContainer>
      )}
      {isSelectingQuestionnaire && (
        <ContentContainer>
          <QuestionnaireSelection
            selectedId={selectedQuestionnaire?.id}
            width={containerRef.current?.clientWidth}
          />
        </ContentContainer>
      )}
      {isAnalysisView && (
        <AnalysisDetailGrid regeneratedResponseData={regeneratedAnalysisData} />
      )}
    </Box>
  );
};

export default InitiateAnalysis;
