import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReactComponent as Warning } from 'src/assets/Warning.svg';
import { TFunction } from 'i18next';
import { ErrorContext, ErrorMessages } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface ErrorMessageProps {
  statusDetails: string;
  t: TFunction;
}

const AIErrorMessage: React.FC<ErrorMessageProps> = ({ statusDetails, t }) => (
  <Box display="flex" alignItems={'center'} gap={1}>
    <Warning style={{ flexShrink: 0 }} />
    <Typography>
      {t(ErrorMessages[ErrorContext.AI][statusDetails] || T.generalErrorMsg)}
    </Typography>
  </Box>
);

export default AIErrorMessage;
