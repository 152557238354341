import { Button } from '@mui/material';
import TooltipOnDisabled from 'src/components/TooltipOnDisabled';

interface ActionButtonProps {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  tooltipText?: string;
}

const ActionButton = ({
  text,
  onClick,
  disabled = false,
  tooltipText = ''
}: ActionButtonProps) => {
  return (
    <TooltipOnDisabled showTooltip={disabled} title={tooltipText}>
      <Button
        color="secondary"
        variant="contained"
        size="small"
        sx={{ height: '100%' }}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </Button>
    </TooltipOnDisabled>
  );
};

export default ActionButton;
