import { IconButton } from '@mui/material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';
import { CommandComponentProps } from 'src/types/custom_component';

const RowAction = ({ onExecute }: CommandComponentProps) => {
  const { t } = useTranslation();

  return (
    <IconButton onClick={onExecute} title={t(T.delete)}>
      <DeleteIcon />
    </IconButton>
  );
};

export default RowAction;
