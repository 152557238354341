import { useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { DOCUMENT_ANALYSIS_STATUS } from 'src/types/enum';
import Label from 'src/components/Label';
import {
  DataTypeProvider,
  DataTypeProviderProps
} from '@devexpress/dx-react-grid';

export const StatusFormatter = ({ value }) => {
  const { t, i18n } = useTranslation();

  const translatedStatus = useMemo(
    () => t(T[value || '']),
    [value, i18n.language]
  );

  const getStatusLabelComponent = useMemo(() => {
    switch (value) {
      case DOCUMENT_ANALYSIS_STATUS.COMPLETED:
        return <Label color="success">{translatedStatus}</Label>;
      case DOCUMENT_ANALYSIS_STATUS.CANCELLED:
        return <Label color="primary">{translatedStatus}</Label>;
      case DOCUMENT_ANALYSIS_STATUS.FAILED:
        return <Label color="error">{translatedStatus}</Label>;
      case DOCUMENT_ANALYSIS_STATUS.INPROGRESS:
        return <Label color="warning">{translatedStatus}</Label>;
      default:
        return <Label color="info">{translatedStatus}</Label>;
    }
  }, [value, i18n.language]);

  return (
    <Box display="flex" alignItems="center">
      {getStatusLabelComponent}
    </Box>
  );
};

export const StatusProvider = (props: DataTypeProviderProps) => {
  return <DataTypeProvider formatterComponent={StatusFormatter} {...props} />;
};
