import { Avatar, Box, Grid, Skeleton, Typography } from '@mui/material';
import { RESOURCE_TYPE } from 'src/types/enum';

type CardSkeletonProps = {
  skeletonType: string;
};
const skeletonRowsNumber = 5;
const CardSkeleton = ({ skeletonType }: CardSkeletonProps) => {
  return (
    <Grid container height={'100%'} alignContent={'flex-start'} paddingX={2}>
      {[...Array(skeletonRowsNumber)].map((row, index) => {
        return (
          <Grid
            key={index}
            item
            xs={12}
            sm={12}
            md={6}
            lg={skeletonType !== RESOURCE_TYPE.NAMESPACE ? 4 : 6}
            xl={skeletonType !== RESOURCE_TYPE.NAMESPACE ? 3 : 4}
          >
            <Box
              display="flex"
              flexDirection={'row'}
              alignItems={'center'}
              key={index}
              height={skeletonType !== RESOURCE_TYPE.NAMESPACE ? 90 : 130}
            >
              <Skeleton
                variant="rectangular"
                animation="wave"
                height={skeletonType !== RESOURCE_TYPE.NAMESPACE ? 64 : 80}
                width={skeletonType !== RESOURCE_TYPE.NAMESPACE ? 64 : 80}
              >
                <Avatar />
              </Skeleton>
              <Box flex={1} marginLeft={'15px'}>
                <Typography variant="h3">
                  <Skeleton width="70%" />
                </Typography>
                <Skeleton width="50%" />
                <Skeleton width="50%" />
              </Box>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CardSkeleton;
