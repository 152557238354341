import {
  IntegratedSorting,
  Sorting,
  SortingState
} from '@devexpress/dx-react-grid';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { caseInsensitiveSort } from 'src/utils/utils';

export const COLUMNS = {
  NAME: 'name',
  USERS: 'users',
  CREATED_BY: 'createdBy'
};

export const userGroupsColumns = [
  { name: COLUMNS.NAME, title: T.name, getCellValue: (row) => row.name },
  {
    name: COLUMNS.USERS,
    title: T.users,
    getCellValue: (row) =>
      row.members.map((member) => member?.fullName?.trim() || member?.username)
  },
  {
    name: COLUMNS.CREATED_BY,
    title: T.createdBy,
    getCellValue: (row) => row?.attributes?.owner?.[0] || ''
  }
];

export const userGroupsColumnExtensions = [
  {
    columnName: COLUMNS.NAME,
    width: '30%'
  },
  {
    columnName: COLUMNS.USERS,
    width: '30%'
  },
  {
    columnName: COLUMNS.CREATED_BY,
    width: '30%'
  }
];

export const getFilteredList = (filtered: StringKeys) => {
  let filterObj = {};
  Object.keys(filtered).forEach((key) => {
    if (key === 's') {
      filterObj[key] = filtered[key];
    } else if (key === 'created_by') {
      filterObj[key] = filtered[key];
    }
  });
  return filterObj;
};

export const defaultSorting: Sorting[] = [
  { columnName: COLUMNS.NAME, direction: 'asc' }
];

export const sortingColumnExtensions: SortingState.ColumnExtension[] = [
  {
    columnName: COLUMNS.NAME,
    sortingEnabled: true
  },
  { columnName: COLUMNS.USERS, sortingEnabled: false },
  { columnName: COLUMNS.CREATED_BY, sortingEnabled: true }
];

export const integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[] =
  [
    {
      columnName: COLUMNS.NAME,
      compare: (a, b) => caseInsensitiveSort(a, b)
    },
    {
      columnName: COLUMNS.CREATED_BY,
      compare: (a, b) => caseInsensitiveSort(a, b)
    }
  ];
