import { Grid } from '@mui/material';
import CardView from './cardView';
import { IDocumentCardContent } from './type';
import CreateWorkspaceCard from './createWorkspaceCard';

const DocumentCardContent = (props: IDocumentCardContent) => {
  const {
    rows,
    setRows,
    handleShareResourceClick,
    handleStartChatClick,
    setDeletedRowId,
    setDeleteOpen,
    handleCreateUpdateNewCard,
    isMoveDialogOpen,
    setActiveDirectory,
    filteredKeys,
    ignoreExemptionOfWorkspace,
    showCreateWorkspaceCard
  } = props;

  return (
    <Grid container pb={'12px'} rowSpacing={2} columnSpacing={2}>
      {showCreateWorkspaceCard && (
        <Grid item xs={12} sm={12} md={6} xl={4}>
          <CreateWorkspaceCard
            handleCreateNewCard={handleCreateUpdateNewCard}
          />
        </Grid>
      )}
      {!!rows.length &&
        rows.map((row: any, index: number) => {
          return (
            <Grid item key={index} xs={12} sm={12} md={6} xl={4}>
              <CardView
                index={index}
                row={row}
                filteredKeys={filteredKeys}
                ignoreExemptionOfWorkspace={ignoreExemptionOfWorkspace}
                workspace={{
                  rows: rows,
                  setRows: setRows,
                  handleCreateUpdateNewCard: handleCreateUpdateNewCard,
                  handleShareResourceClick: handleShareResourceClick,
                  handleStartChatClick: handleStartChatClick,
                  setDeleteOpen: setDeleteOpen,
                  setDeletedRowId: setDeletedRowId,
                  isMoveDialogOpen: isMoveDialogOpen,
                  setActiveDirectory: setActiveDirectory,
                  permissions: row?.permissions,
                  showCreateWorkspaceCard
                }}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default DocumentCardContent;
