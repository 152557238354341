import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_SERVER_URL, IS_ENCRYPTION_ENABLED } from '../config';
import { DataApiInputParams } from '../types/api';
import {
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { blobToBuffer, decryptFileData } from 'src/utils/files/file_encryption';
import users from './users';
import roles from './roles';
import { getEncryptParams } from 'src/keycloak';
import common from './common';
import userGroups from './userGroups';
import { getPayload } from 'src/utils/encryption';
import permissions from './permissions';
import analytics from './analytics';
import { ICreateSharepointResourceParams } from 'src/types/document';
import settings from './settings';
import docAnalyst from './docAnalyst';

export const API_BASE_URL = `/api/v1/`;

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_SERVER_URL}${API_BASE_URL}`,
    prepareHeaders: (headers) => {
      headers.set('withCredentials', 'true');
      return headers;
    }
  }), // Set the baseUrl for every endpoint below
  tagTypes: [
    'User',
    'Files',
    'Chats',
    'Tags',
    'ChatParams',
    'Roles',
    'Permissions',
    'Groups',
    'IdentityProvider',
    'ResourceProvider',
    'Favourites',
    'DocAnalyst',
    'ResourceSettings'
  ],
  endpoints: (builder) => ({
    getDocuments: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `files/file-details?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? {
              data: {
                ...response.ret_val,
                data: Array.isArray(response.ret_val.data)
                  ? response.ret_val.data.map((item) => ({
                      ...item,
                      attrs: (Array.isArray(item.attrs)
                        ? item.attrs
                        : []
                      ).reduce(
                        (acc, attr) => ({ ...acc, [attr.name]: attr.value }),
                        {}
                      ),
                      tags: Array.isArray(item.tags) ? item.tags : []
                    }))
                  : []
              }
            }
          : serializeErrorResponse(response);
      },
      providesTags: ['Files']
    }),
    uploadDocuments: builder.mutation({
      async queryFn(
        { params }: DataApiInputParams,
        { getState },
        extraOptions,
        baseQuery
      ) {
        let decryptKey = getEncryptParams()?.k;
        let result: any = await baseQuery({
          url: `files/file`,
          method: 'POST',
          body: params.formData,
          ...getHeaders(`files/file`, params.formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    processDocuments: builder.mutation({
      async queryFn({ params }, { getState }, extraOptions, baseQuery) {
        let result: any = await baseQuery({
          url: `files/file`,
          method: 'PATCH',
          body: params.formData,
          ...getHeaders(`files/file`, params.formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Tags']
    }),
    updateResource: builder.mutation({
      async queryFn({ params }, { getState }, extraOptions, baseQuery) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(params);
        let result: any = await baseQuery({
          url: `files/folder`,
          method: 'PATCH',
          body: formData,
          ...getHeaders(`files/folder`, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Files', 'Favourites']
    }),
    createResource: builder.mutation({
      async queryFn(
        { params }: DataApiInputParams | ICreateSharepointResourceParams,
        { getState },
        extraOptions,
        baseQuery
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(params);
        let result: any = await baseQuery({
          url: `files/folder`,
          method: 'POST',
          body: formData,
          ...getHeaders(`files/folder`, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Files']
    }),
    addFavourite: builder.mutation({
      async queryFn(
        { params }: DataApiInputParams,
        { getState },
        extraOptions,
        baseQuery
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(params);
        let result: any = await baseQuery({
          url: `favourites/favourite`,
          method: 'POST',
          body: formData,
          ...getHeaders(`favourites/favourite`, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Favourites']
    }),
    getFavourite: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = 'favourites/favourite';
        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}?payload=${btoa(getPayload('', getEncryptParams()))}`;
        }
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Favourites']
    }),
    removeFavourite: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `favourites/favourite`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Favourites']
    }),
    getChats: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `expert-advisor/chat?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Chats']
    }),
    createChat: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `expert-advisor/chat`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    deleteChat: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `expert-advisor/chat`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Chats']
    }),
    renameChat: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `expert-advisor/chat`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PATCH',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Chats'] // TODO: LATER SEE IF CHAT INVALIDATION IS REQUIRED ON RENAMING ....
    }),
    getChatParams: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `expert-advisor/chat-params?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['ChatParams']
    }),
    postChatParams: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `expert-advisor/chat-params`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PATCH',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['ChatParams']
    }),
    getMessages: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `expert-advisor/chat-msg?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    postMessage: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `expert-advisor/chat-msg`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    patchMessage: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `expert-advisor/chat-msg`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PATCH',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    getChatTags: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `expert-advisor/chat-tags?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    updateChatTags: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `expert-advisor/chat-tags`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    getFilesTags: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `files/file-tag?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Tags']
    }),
    deleteDocument: builder.mutation({
      async queryFn(
        args: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(args.params);
        let url = `files/file`;

        const result: any = await fetchWithBQ({
          url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Files', 'Favourites']
    }),
    postDocTags: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `files/tagged-file`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Tags', 'Files']
    }),
    deleteDocTags: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `files/tagged-file`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Tags', 'Files']
    }),
    downloadFile: builder.query({
      async queryFn(
        args: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const url = `files/file?${getQueryString(args.params)}`;
        const result: any = await fetchWithBQ({
          url,
          ...getHeaders(url),
          responseHandler: async (response) => {
            if (response.status === 404) {
              return {
                extended: {
                  error_name: 'REFERENCE_DOCUMENT_DELETED'
                }
              };
            }

            return {
              ret_val: await response.blob()
            };
          }
        });
        if (result.error) {
          return serializeErrorResponse(result.error.data);
        }
        const bufferData = (await blobToBuffer(
          result?.data?.ret_val as Blob
        )) as Buffer;
        let decryptedData = await decryptFileData(bufferData);
        decryptedData = {
          blob: decryptedData,
          contentType: result.meta.response.headers.get('Ea-Content-Type'),
          format: result.meta.response.headers.get('Ea-File-Format')
        };
        return decryptedData
          ? { data: decryptedData }
          : serializeErrorResponse(decryptedData.extended);
      }
    }),
    getUserPreferences: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `users/user-settings?${getQueryString(arg.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    updateUserPreferences: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `users/user-settings`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    getUserOrUserGroups: builder.query({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        const queryString = getQueryString(arg.params);
        let url = `keycloak/User-Group${
          queryString.length ? '?' + queryString : ''
        }`;
        let decryptKey = getEncryptParams()?.k;

        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}${queryString.length ? '&' : '?'}payload=${btoa(
            getPayload('', getEncryptParams())
          )}`;
        }

        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['Permissions']
    }),
    getContentAccessRights: builder.query({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        const queryString = getQueryString(arg.params);
        let url = `files/folder${queryString.length ? '?' + queryString : ''}`;
        let decryptKey = getEncryptParams()?.k;

        if (IS_ENCRYPTION_ENABLED) {
          url = `${url}${queryString.length ? '&' : '?'}payload=${btoa(
            getPayload('', getEncryptParams())
          )}`;
        }

        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });

        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val.data }
          : serializeErrorResponse(response);
      }
    }),

    syncProviderResource: builder.mutation({
      async queryFn({ params }, _, __, baseQuery) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(params);
        let result: any = await baseQuery({
          url: `files/resource-providers`,
          method: 'PATCH',
          body: formData,
          ...getHeaders(`files/resource-providers`, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['Files']
    }),
    shareChat: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `public-resources/`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    getShareChat: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let url = `public-resources/?${getQueryString(arg?.params)}`;
        let result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    ...users(builder),
    ...roles(builder),
    ...userGroups(builder),
    ...permissions(builder),
    ...common(builder),
    ...analytics(builder),
    ...settings(builder),
    ...docAnalyst(builder)
  })
});
export const {
  // Common
  useLazyGetLookupsQuery,
  useLazyGetAppSettingsQuery,
  useUpdateAppSettingsMutation,

  //  Tags
  useLazyGetTagsQuery,
  usePostDocTagsMutation,
  useDeleteDocTagsMutation,
  useLazyGetFilesTagsQuery,

  // CHATS
  useLazyGetChatsQuery,
  useCreateChatMutation,
  useDeleteChatMutation,
  useRenameChatMutation,

  // CHAT MESSAGE
  useLazyGetMessagesQuery,
  usePostMessageMutation,
  usePatchMessageMutation,

  // CHAT PARAMS
  useLazyGetChatParamsQuery,
  usePostChatParamsMutation,

  //CHAT TAGS
  useLazyGetChatTagsQuery,
  useUpdateChatTagsMutation,

  //
  useShareChatMutation,
  useLazyGetShareChatQuery,

  // DOCUMENTS
  useLazyGetDocumentsQuery,
  useUploadDocumentsMutation,
  useLazyDownloadFileQuery,
  useUpdateResourceMutation,
  useCreateResourceMutation,
  useDeleteDocumentMutation,
  useProcessDocumentsMutation,
  useSyncProviderResourceMutation,

  // Favourites
  useAddFavouriteMutation,
  useGetFavouriteQuery,
  useLazyGetFavouriteQuery,
  useRemoveFavouriteMutation,

  // USER SETTINGS
  useLazyGetUserPreferencesQuery,
  useUpdateUserPreferencesMutation,

  // USERS
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useUpdateStatusMutation,

  // ROLES
  useLazyGetRolesQuery,
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,

  // USER GROUPS
  useLazyGetUserGroupsQuery,
  useCreateUserGroupMutation,
  useDeleteUserGroupMutation,
  useUpdateUserGroupsMutation,

  // Permissions
  useLazyGetPermissionsQuery,

  // CONTENT SHARING
  useLazyGetUserOrUserGroupsQuery,
  useLazyGetContentAccessRightsQuery,

  // Stats
  useLazyGetProcessedDocumentsQuery,
  useLazyGetUsageQuery,
  useLazyGetDataLibraryAnalyticsQuery,
  useLazyGetUserAnalyticsQuery,
  useLazyGetChatAnalyticsQuery,

  // Settings
  useCreateIdentityProviderMutation,
  useUpdateIdentityProviderMutation,
  useGetResourceProviderQuery,
  useGetIdentityProviderQuery,
  useLazyGetIdentityProviderQuery,
  useDeleteIdentityProviderMutation,
  useCreateResourceProviderMutation,
  useUpdateResourceProviderMutation,
  useDeleteResourceProviderMutation,
  useLazyGetResourceProviderQuery,
  useLazyDownloadCertificateQuery,
  useLazyGetModelsQuery,

  // Doc Analyst
  useGetAnalysisHistoryQuery,
  useLazyGetAnalysisHistoryQuery,
  useGetAnalysisQuery,
  useLazyGetAnalysisQuery,
  useCreateAnalysisMutation,
  useDeleteAnalysisMutation,
  useGetDatasetsQuery,
  useLazyGetDatasetsQuery,
  useCreateDatasetMutation,
  useDeleteDatasetMutation,
  useGetQuestionnairesQuery,
  useLazyGetQuestionnairesQuery,
  useGetQuestionnaireQuery,
  useLazyGetQuestionnaireQuery,
  useCreateQuestionnaireMutation,
  useEditQuestionnaireMutation,
  useDeleteQuestionnaireMutation,
  useLazyGetAnalysisDetailQuery,
  useGetAnalysisDetailQuery,
  useUpdateAnalysisDetailMutation,
  useUpdateAnalysisMutation
} = api;
