import React from 'react';
import { Box } from '@mui/material';
import { formatFloatNumber, getColor } from '../utils';
import { USAGE_STATS_TYPE } from 'src/types/enum';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

const CustomChartTooltip = ({ active, data, label, theme, usageStats }) => {
  const { t } = useTranslation();
  if (!active || !data?.length) {
    return null;
  }

  const totalValue = data.reduce(
    (accumulator, pld) => accumulator + pld.value,
    0
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      p={1}
      border={`1px solid ${theme.colors.alpha.black[50]}`}
      sx={{ backgroundColor: theme.colors.alpha.white[100] }}
      zIndex={1000}
    >
      <Box color="#222F59" padding={1} display="flex" justifyContent="center">
        <b>{`${format(new Date(label), 'd MMM')} : ${formatFloatNumber(
          totalValue
        )} ${usageStats.unit}`}</b>
      </Box>
      <Box>
        {data.map((item, index) => (
          <Box key={index} display="flex" alignItems="center" padding={1}>
            <Box
              width={10}
              height={10}
              display={'inline-block'}
              marginRight={1}
              sx={{ backgroundColor: getColor(item.dataKey) }}
            />
            <Box color={'rgba(34, 47, 89, 0.8)'}>
              {`${t(USAGE_STATS_TYPE[item.dataKey])}: ${formatFloatNumber(
                item.value
              )} ${usageStats.unit}`}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomChartTooltip;
