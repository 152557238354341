import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { QUESTION_CHARACTER_LIMIT, QUESTION_CHARACTER_WARNING_LIMIT } from "../../utils/utils";
import { CharacterLimitInfo, StyledChip, StyledTextarea } from "../styles";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';
import { DragIndicator } from '@mui/icons-material';
import { TRANSLATION_CONSTANTS as T } from "src/utils/translations";

const QuestionRow = ({
  id,
  question,
  handleQuestionChange,
  moveQuestion,
  removeQuestion,
  index,
  isReorderingVisible,
  disableActionButtons = false,
  moveQuestionToId
}: any) => {
  const { t } = useTranslation();

  const [{ isDragging }, drag, dragPreview] = useDrag(
    () => ({
      type: 'question',
      item: { id, index },
      canDrag: () => {
        return !disableActionButtons;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      }),
      end: (item, monitor) => {
        const { id: droppedId, index } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveQuestion(droppedId, index);
        }
      }
    }),
    [id, index, moveQuestion]
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'question',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          moveQuestionToId(draggedId, id);
        }
      }
    }),
    [moveQuestionToId]
  );

  const handleChange = (e) => {
    handleQuestionChange(id, e.target.value);
  };

  const handleDelete = () => {
    removeQuestion(id);
  };

  return (
    <Box
      display="flex"
      gap="8px"
      sx={{
        opacity: isDragging ? 0.2 : 1
      }}
      ref={(node: HTMLDivElement) => drop(node)}
    >
      <StyledChip label={index + 1} />
      <Box
        display="flex"
        gap="8px"
        ref={(node: HTMLDivElement) => dragPreview(node)}
        flexGrow={1}
      >
        <Box position="relative" width="100%">
          <StyledTextarea
            placeholder={t(T.questionnaireQuestionPlaceholder)}
            rows={2}
            value={question}
            onChange={handleChange}
            maxLength={QUESTION_CHARACTER_LIMIT}
          />

          {question.length > QUESTION_CHARACTER_WARNING_LIMIT && (
            <CharacterLimitInfo color="GrayText">
              {question.length}/{QUESTION_CHARACTER_LIMIT} characters
            </CharacterLimitInfo>
          )}
        </Box>
        <Box display="flex">
          <IconButton
            sx={{ alignItems: 'flex-start', height: 'fit-content' }}
            onClick={handleDelete}
            disabled={disableActionButtons}
          >
            <DeleteIcon />
          </IconButton>
          {isReorderingVisible && (
            <IconButton
              sx={{ alignItems: 'flex-start', height: 'fit-content' }}
              ref={drag}
              disabled={disableActionButtons}
            >
              <DragIndicator fontSize="medium" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionRow;
