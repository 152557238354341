import { Box, IconButton, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as SyncIcon } from 'src/assets/SyncIcon.svg';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg';
import useMutation from 'src/hooks/useMutation';
import {
  addUpdateAnalysisHistoryContext,
  updateInitiateAnalysisState,
  updateQuestion
} from 'src/redux/slices/docAnalyst';
import { RootState } from 'src/redux/store';
import { useUpdateAnalysisDetailMutation } from 'src/services/api';
import { AnalysisResponseProps } from 'src/types/docAnalyst';
import {
  ANALYSIS_QUESTION_STATUS,
  DOCUMENT_ANALYSIS_STATUS
} from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { Dot, LoaderDotsContainer } from '../styles';
import EditInput from './EditInput';
import {
  DataRowContainer,
  QuestionCellContainer,
  ResponseCellContainer
} from './styles';
import AIErrorMessage from './AIErrorMessage';

type DataRowProps = {
  data: AnalysisResponseProps;
  index: number;
  setSelectedRow: (id: number) => void;
  updateRowData: (val: AnalysisResponseProps) => void;
};

const DataRow: React.FC<DataRowProps> = ({
  data,
  updateRowData,
  setSelectedRow,
  index
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const [isEditing, setIsEditing] = useState(false);
  const [question, setQuestion] = useState(data.question);

  const {
    analysisHistoryContexts,
    initiateAnalysis: {
      selectedDataset,
      selectedQuestionnaire,
      selectedAnalysis
    },
    currentlyRunningAnalysis
  } = useSelector((state: RootState) => state.docAnalyst);

  const { maxAnalysisLimit } = useSelector((state: RootState) => state.auth);

  const isAnalysisStreaming = useMemo(
    () => analysisHistoryContexts[selectedAnalysis?.id]?.isAnalysisStreaming,
    [analysisHistoryContexts, selectedAnalysis?.id]
  );

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const [
    updateAnalysisDetail,
    { isLoading: isUpdatingAnalysisDetail, data: updatedAnalysisDetail }
  ] = useMutation({
    api: useUpdateAnalysisDetailMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const handleDetailView = useCallback(() => {
    setSelectedRow(index);
  }, []);

  const handleEditButton = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleQuestionEditing = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuestion(e.target.value);
    },
    []
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setIsEditing(false);
        const { value } = e.target as HTMLInputElement;
        if (data?.question !== value)
          updateAnalysisDetail({
            params: { params: { id: data.id, question: value } },
            successMsg: t(T.questionUpdatedSuccess),
            successCallback(data) {
              updateRowData(data?.data?.[0]);
              dispatch(updateQuestion({ questionEdited: true }));
              dispatch(
                addUpdateAnalysisHistoryContext({
                  analysisId: selectedAnalysis.id,
                  isAnalysisStreaming: true
                })
              );
            }
          });
      }
    },
    []
  );

  const handleRegenerateSingleResponse = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      updateAnalysisDetail({
        params: { params: { id: data.id, regenerate_response: true } },
        successMsg: t(T.regeneratingResponse),
        successCallback(data) {
          updateRowData(data?.data?.[0]);
        }
      });
      dispatch(
        updateInitiateAnalysisState({
          selectedAnalysis: {
            ...selectedAnalysis,
            status: DOCUMENT_ANALYSIS_STATUS.REGENERATING
          }
        })
      );
      dispatch(
        addUpdateAnalysisHistoryContext({
          analysisId: selectedAnalysis.id,
          isAnalysisStreaming: true
        })
      );
    },
    [data]
  );

  const responseData = useMemo(() => {
    try {
      if (typeof data?.answer === 'string') {
        const parsedResponse = JSON.parse(data.answer);
        return parsedResponse;
      }
      return data?.answer;
    } catch (error) {
      return { response: data?.answer };
    }
  }, [data.answer]);

  const showResponseLoader = useMemo(() => {
    if (!isAnalysisStreaming) return null;

    if (
      data.status_code === ANALYSIS_QUESTION_STATUS.CREATED ||
      data.status_code === ANALYSIS_QUESTION_STATUS.REGENERATING
    ) {
      return (
        <>
          <Skeleton width="90%" variant="text" animation="wave" />
          <Skeleton width="70%" variant="text" animation="wave" />
        </>
      );
    }

    if (data.status_code === ANALYSIS_QUESTION_STATUS.GENERATING) {
      return (
        <LoaderDotsContainer>
          <Dot />
          <Dot />
          <Dot />
          <Typography>{t(T.generating)}</Typography>
        </LoaderDotsContainer>
      );
    }
  }, [data.status_code, isAnalysisStreaming]);

  const showActionButtons = useMemo(() => {
    return (
      isHovered &&
      !isAnalysisStreaming &&
      !selectedDataset.isDatasetDeleted &&
      selectedQuestionnaire.isEditable &&
      currentlyRunningAnalysis !== maxAnalysisLimit
    );
  }, [isHovered, isAnalysisStreaming, selectedDataset, selectedQuestionnaire]);

  function markdownToPlainText(markdown) {
    return markdown
      ?.replace(/[*_~`]/g, '') // Remove *, _, ~, ` characters
      .replace(/!\[.*?\]\(.*?\)/g, '') // Remove images
      .replace(/\[(.*?)\]\(.*?\)/g, '$1') // Replace links with the link text
      .replace(/#+ /g, '') // Remove headings
      .replace(/>\s?/g, '') // Remove blockquotes
      .replace(/(?:\r\n|\r|\n)/g, ' ') // Replace line breaks with space
      .replace(/^\s+|\s+$/g, '') // Trim leading and trailing spaces
      .replace(/\[Document:\s*(\d+(?:,\s*Document:\s*\d+)*)\]/gi, ''); // Remove document references
  }

  const plainTextResponse = useMemo(
    () => (
      <Typography>{markdownToPlainText(responseData?.response)}</Typography>
    ),
    [responseData?.response]
  );

  return (
    <>
      <DataRowContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box width={'50px'}>
          <Typography>{index + 1}</Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          width="400px"
        >
          {isEditing ? (
            <EditInput
              data={data}
              question={question}
              handleQuestionEditing={handleQuestionEditing}
              handleKeyDown={handleKeyDown}
              setIsEditing={setIsEditing}
              updateRowData={updateRowData}
              setQuestion={setQuestion}
            />
          ) : (
            <>
              <Box>
                <QuestionCellContainer>{data.question}</QuestionCellContainer>
                {data?.is_edited && (
                  <Typography color={'#222F5999'}>{t(T.edited)}</Typography>
                )}
              </Box>
              {showActionButtons && (
                <IconButton
                  onClick={handleEditButton}
                  sx={{
                    height: '40px',
                    alignSelf: 'center'
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
        </Box>
        <ResponseCellContainer onClick={handleDetailView}>
          {data?.status_details &&
          data?.status_code === ANALYSIS_QUESTION_STATUS.FAILED ? (
            <AIErrorMessage statusDetails={data.status_details} t={t} />
          ) : (
            responseData?.response && plainTextResponse
          )}
          {showResponseLoader}
        </ResponseCellContainer>
        <Box width="100px" textAlign={'end'} paddingRight={'20px'}>
          {showActionButtons && (
            <IconButton onClick={handleRegenerateSingleResponse}>
              <SyncIcon />
            </IconButton>
          )}
        </Box>
      </DataRowContainer>
    </>
  );
};

export default DataRow;
