import DataLibraryExplorer from 'src/components/DataLibraryExplorer';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import { Box, OutlinedInput, Typography } from '@mui/material';
import useMutation from 'src/hooks/useMutation';
import { useCreateDatasetMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { StringKeys } from 'src/types/base';
import { DOCUMENT_STATUS, LOOKUP } from 'src/types/enum';
import { useSelector } from 'src/redux/store';
import { useParams } from 'react-router';

interface ImportDatasetProps {
  open: boolean;
  handleClose: () => void;
  sources: StringKeys;
}

const ImportDataset = ({ open, handleClose, sources }: ImportDatasetProps) => {
  const { t } = useTranslation();
  const [datasetTitleDialogOpen, setDatasetTitleDialogOpen] = useState(false);
  const [datasetTitle, setDatasetTitle] = useState<string>('');
  const [selectedDocs, setSelectedDocs] = useState<StringKeys[]>([]);

  const { id: paramsId } = useParams();
  const [currentResourceId, setCurrentResourceId] = useState<string | null>(
    paramsId
  );

  const { lookups } = useSelector((state) => state.data);

  const [createDataset] = useMutation({
    api: useCreateDatasetMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const fetchDocumentsQueryParams = useMemo(() => {
    const processedStatus = lookups?.[LOOKUP.FILES]?.find(
      (lookup) => lookup.code === DOCUMENT_STATUS.PROCESSED
    );
    return { status_id: processedStatus?.id };
  }, [lookups]);

  const handleOpenDatasetTitleDialog = useCallback(() => {
    setDatasetTitleDialogOpen(true);
  }, []);

  const handleCloseDatasetTitleDialog = useCallback(() => {
    setDatasetTitleDialogOpen(false);
  }, []);

  const handleImportButton = async () => {
    const resources = selectedDocs.map((doc) => ({
      rid: doc.id,
      type_id: doc.type_id
    }));

    await createDataset({
      params: {
        params: {
          title: datasetTitle,
          rid_list: JSON.stringify(resources)
        }
      },
      successMsg: t(T.filesImportSuccess),
      successCallback(data) {
        handleClose();
      }
    });
  };

  const handleDatasetTitleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setDatasetTitle(event.target.value);

  return (
    <>
      <DataLibraryExplorer
        open={open}
        handleClose={handleClose}
        dialogTitle={t(T.importFromApp)}
        mainActionButtonText={t(T.import)}
        currentResourceId={currentResourceId}
        setCurrentResourceId={setCurrentResourceId}
        disabledActionButtonMsg={t(T.selectItemsToImport)}
        sources={sources}
        ignoreExemptionOfWorkspace
        handleDialogAction={handleOpenDatasetTitleDialog}
        fetchDocumentsQueryParams={fetchDocumentsQueryParams}
        onSelect={setSelectedDocs}
        showSelectionColumn
        disableActionButton={selectedDocs.length === 0}
      />

      <DialogLayout
        isOpen={datasetTitleDialogOpen}
        handleConfirmation={handleImportButton}
        onClose={handleCloseDatasetTitleDialog}
        dialogTitle={T.import}
        confirmationButtonText={T.save}
        disableButton={!datasetTitle?.length}
      >
        <Box mb={'15px'}>
          <Typography height={'26px'}>{t(T.datasetTitle)}</Typography>
          <OutlinedInput
            fullWidth
            placeholder={`${T.enter}`}
            sx={{ height: '42px' }}
            value={datasetTitle}
            onChange={handleDatasetTitleChange}
          />
        </Box>
      </DialogLayout>
    </>
  );
};

export default ImportDataset;
