import { useMemo } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { USER_STATUS } from 'src/types/enum';
import Label from 'src/components/Label';

export const StatusFormatter = ({ value }: any) => {
  const { t, i18n } = useTranslation();

  const getUserStatus = useMemo(() => {
    switch (value) {
      case USER_STATUS.ACTIVE:
        return (
          <Label maxWidth="100%" color="success">
            {t(T.active)}
          </Label>
        );
      case USER_STATUS.INVITATION_EXPIRED:
        return (
          <Label color="error" maxWidth="100%">
            {t(T.invitationExpired)}
          </Label>
        );
      case USER_STATUS.INACTIVE:
        return (
          <Label color="warning" maxWidth="100%">
            {t(T.inactive)}
          </Label>
        );
      case USER_STATUS.INVITED:
        return (
          <Label color="info" maxWidth="100%">
            {t(T.invited)}
          </Label>
        );
      default:
        return '-';
    }
  }, [i18n.language]);

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        maxWidth: '100%'
      }}
    >
      {getUserStatus}
    </Box>
  );
};

export const StatusProvider = (props: any) => (
  <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
);
