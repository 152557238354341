import Close from '@mui/icons-material/Close';
import { Box, Link, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { StringKeys } from 'src/types/base';
import { extractFilters } from './utils';
import {
  FilterComponents,
  FilterElementTypes
} from 'src/types/custom_component';
import { getDefaultValues, getValues } from '../HeaderFilterwithPopOver/utils';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';

const linkStyles = {
  fontWeight: 'bold',
  cursor: 'pointer'
};

type Props = {
  elementsList: FilterComponents[];
  appliedFiltersValue?: StringKeys;
  setAppliedFiltersValue?: React.Dispatch<React.SetStateAction<StringKeys>>;
  onReset: (initialObject: any) => void;
};

const AppliedFilters = ({
  onReset,
  appliedFiltersValue,
  setAppliedFiltersValue,
  elementsList
}: Props) => {
  const { t } = useTranslation();
  const filterChips = useMemo(() => {
    return extractFilters(appliedFiltersValue);
  }, [appliedFiltersValue]);
  const removeFilterById = (filterKey: string, idToRemove: number | string) => {
    let newData = { ...appliedFiltersValue };

    if (Array.isArray(newData[filterKey])) {
      newData[filterKey] = newData[filterKey].filter(
        (item) => item.id !== idToRemove
      );
    } else if (
      typeof newData[filterKey] === 'object' &&
      newData[filterKey]?.id === idToRemove
    ) {
      newData[filterKey] = null;
    }
    setAppliedFiltersValue(newData);
  };
  const onClickReset = () => {
    let initialObject: { [name: string]: string | boolean } = {};
    let defaultState: { [name: string]: string | boolean } = {};
    const defaults: { [name: string]: string } = {};
    Object.keys(appliedFiltersValue).forEach((key: string) => {
      let item: FilterComponents | undefined = elementsList.find(
        (item) => item.dataField === key
      );
      if (item.type !== FilterElementTypes.Text) {
        initialObject[key] = getDefaultValues(item, true);
        if (getDefaultValues(item, true)) {
          defaults[key] = getDefaultValues(item, true);
        }
      }
    });
    getValues(defaults, defaultState, elementsList);
    defaultState = { ...defaultState, s: appliedFiltersValue['s'] };
    initialObject = { ...initialObject, s: appliedFiltersValue['s'] };
    setAppliedFiltersValue(initialObject);
    onReset(defaultState);
  };
  return (
    !!filterChips[0] && (
      <Box marginTop={'10px'}>
        <Box display={'flex'} gap={1} alignItems={'start'}>
          <Typography fontWeight={'bold'} color={'primary'} minWidth={'max-content'}>
            Applied Filters:
          </Typography>
          <Stack direction="row" flexWrap={'wrap'} rowGap={'6px'} columnGap={'8px'} alignItems={'end'}>
            {filterChips.map((filter, index) => (
              <Box
                key={index}
                display={'flex'}
                gap={1}
                border={1}
                borderColor={'#CAD9F2'}
                borderRadius={'6px'}
                alignItems={'center'}
                padding={'1px 3px'}
              >
                <Typography fontSize={'13px'}>{filter.value}</Typography>
                <Close
                  onClick={() => {
                    removeFilterById(filter.filterKey, filter.id);
                  }}
                  sx={{ fontSize: '16px', cursor: 'pointer' }}
                />
              </Box>
            ))}
            <Typography variant="body2" align="center" marginLeft={'10px'}>
              <Link
                onClick={onClickReset}
                color="primary"
                underline="always"
                style={linkStyles}
              >
                {t(T.clearFilters)}
              </Link>
            </Typography>
          </Stack>
        </Box>
      </Box>
    )
  );
};

export default AppliedFilters;
