import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box, Typography } from '@mui/material';

export const AccountTypeFormatter = ({ value }: any) => {
  return (
    <Box>
      <Typography variant={'body1'}>{value?.name}</Typography>
    </Box>
  );
};
export const AccountTypeProvider = (props: any) => (
  <DataTypeProvider formatterComponent={AccountTypeFormatter} {...props} />
);
