import { ButtonBase, Chip, styled, Typography } from "@mui/material";

export const StyledChip = styled(Chip)(() => ({
  minWidth: 36,
  borderRadius: '6px',
  height: 'fit-content',
  padding: '3px 0',
  display: 'inline-flex',
  justifyContent: 'center',
  backgroundColor: '#193A6B0F'
}));

export const StyledLinkButton = styled(ButtonBase)(() => ({
  cursor: 'pointer',
  marginTop: '8px',
  borderBottom: '1px solid #000c57',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  padding: '2px 0px'
}));

export const StyledTextarea = styled('textarea')(
  ({ theme }) => `
  padding: 9px;
  width: 100%;
  border-radius: 8px;
  resize: none;
  font: inherit;
  color: currentColor;
  outline: none;
  box-sizing: border-box;
  border-color: ${theme.colors.alpha.black[30]};

  :focus {
    outline: 2px solid ${theme.colors.primaryAlt.main};
    outline-offset: -2px;
    border-color: ${theme.colors.primaryAlt.main};
  }

  :hover {    
    border-color: ${theme.colors.primaryAlt.main};
  }

  ::placeholder {
    color: ${theme.colors.alpha.black[50]}
  }
`
);

export const CharacterLimitInfo = styled(Typography)(`
  position: absolute;
  bottom: -10px;
  right: 16px;
  font-size: 10px;
  pointer-events: none;
  text-transform: lowercase;
`);