import { FC } from 'react';
import {
  Box,
  IconButton,
  DialogTitle as MUIDialogTitle,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';

interface IDialogTitleProps {
  title: string;
  subtitle?: string;
  onClose?: () => void;
  showCloseIcon: boolean;
  disableCloseIcon?: boolean;
}

const DialogTitle: FC<IDialogTitleProps> = ({
  title = '',
  subtitle = '',
  onClose = () => {},
  showCloseIcon = false,
  disableCloseIcon = false
}) => (
  <MUIDialogTitle
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }}
  >
    <Box display={'flex'} flexDirection={'column'}>
      <Typography variant="h3" fontWeight={'normal'}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          variant="h5"
          fontWeight={'normal'}
          lineHeight={'20px'}
          letterSpacing={'0.75px'}
          textAlign={'center'}
        >
          {subtitle}
        </Typography>
      )}
    </Box>
    {showCloseIcon && (
      <IconButton onClick={onClose} disabled={disableCloseIcon}>
        <Close fontSize="medium" />
      </IconButton>
    )}
  </MUIDialogTitle>
);

export default DialogTitle;
