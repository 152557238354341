import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';

export const UploadedByFormatter = ({ value }: any) => {
  return (
    <Typography
      noWrap
      sx={{
        fontSize: '14px'
      }}
    >
      {value}
    </Typography>
  );
};

export const UploadedByProvider = (props: any) => (
  <DataTypeProvider formatterComponent={UploadedByFormatter} {...props} />
);
