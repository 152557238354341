import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import AIModelImageMapper from '../AIModelImageMapper';
import { PriceLabel } from './styles';
import { AiModels } from 'src/types/enum';

const ModelKeyMapping = {
  [AiModels.mistralLarge]: "mistralLarge",
  [AiModels.llama370BInstruct]: "llama370BInstruct",
  [AiModels.llama38BInstruct]: "llama38BInstruct",
  [AiModels.gpt4Turbo]: "gpt4Turbo",
  [AiModels.gpt432k]: "gpt432k",
  [AiModels.gpt4o]: "gpt4o",
  [AiModels.cohere]: "cohere",
  [AiModels.gpt35Turbo16k]: "gpt35Turbo16k",
  [AiModels.gpt35Turbo]: "gpt35Turbo",
  [AiModels.gpt4]: "gpt4",
}


type ItemWithDetailsProps = {
  name: string;
  displayName: string;
  description?: string;
  comment?: string;
  commentDetails?: string;
  isSelected?: boolean;
  showCheckbox?: boolean;
  imageSize?: 'small' | 'medium';
};

const ItemWithDetails = ({
  name,
  displayName,
  description,
  comment,
  commentDetails,
  showCheckbox,
  isSelected
}: ItemWithDetailsProps) => {
  const { t } = useTranslation();

  const modelKey = ModelKeyMapping[name];
  const translatedComment = t(T[`${modelKey}Comment`]);
  const translatedDescription = t(T[`${modelKey}Description`]);
  
  return (
    <Grid
      container
      sx={{ height: '36px', m: 0, px: '10px' }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      alignSelf="stretch"
    >
      <Box display="flex" alignItems="center" gap="8px" alignSelf="stretch">
        {showCheckbox ? (
          <Checkbox
            sx={{ p: 0 }}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={isSelected}
          />
        ) : null}
        <AIModelImageMapper name={name} />
        <Typography
          variant="body2"
          fontWeight={500}
          sx={{
            color: '#222F59'
          }}
        >
          {displayName}
        </Typography>
        {description ? (
          <Typography variant="body2" color="slategrey">
            {translatedDescription || description}
          </Typography>
        ) : null}
      </Box>
      <Box display={'flex'} textAlign="right" gap={'4px'}>
        <Typography
          variant="body2"
          sx={{ fontSize: '14px', lineHeight: '24px', fontWeight: 400 }}
        >
          {translatedComment || comment}
        </Typography>
        {commentDetails ? (
          <PriceLabel
            color="success"
            variant="subtitle1"
            sx={{
              lineHeight: '24px',
              fontWeight: 400
            }}
          >
            {commentDetails}
          </PriceLabel>
        ) : null}
      </Box>
    </Grid>
  );
};
export default memo(ItemWithDetails);
