import Gpt35Turbo from 'src/assets/models/gpt-3.5-turbo-16k.svg';
import Gpt35Turbo16k from 'src/assets/models/gpt-3.5-turbo.svg';
import Gpt4Turbo from 'src/assets/models/gpt-4-turbo.svg';
import Gpt4Turbo32k from 'src/assets/models/gpt-4-32k.svg';
import Gpt4 from 'src/assets/models/gpt-4.svg';
import Llama370BInstruct from 'src/assets/models/Llama-3-70B-Instruct.svg';
import Llama38BInstruct from 'src/assets/models/Llama-3-1-8b-Instruct.svg';
import MistralLarge from 'src/assets/models/Mistral-large.svg';
import Cohere from 'src/assets/models/cohere.svg';
import Gpt4o from 'src/assets/models/gpt-4o.svg';
import { AiModels } from 'src/types/enum';


const aiModelsMap = {
  [AiModels.gpt35Turbo]: Gpt35Turbo,
  [AiModels.gpt35Turbo16k]: Gpt35Turbo16k,
  [AiModels.gpt4Turbo]: Gpt4Turbo,
  [AiModels.gpt432k]: Gpt4Turbo32k,
  [AiModels.gpt4]: Gpt4,
  [AiModels.llama370BInstruct]: Llama370BInstruct,
  [AiModels.llama38BInstruct]: Llama38BInstruct,
  [AiModels.mistralLarge]: MistralLarge,
  [AiModels.cohere]: Cohere,
  [AiModels.gpt4o]: Gpt4o
};

const AIModelImageMapper = ({ name, style }: { name: string; style?: any }) => {
  return (
    <img
      src={aiModelsMap[name]}
      alt={name}
      style={{ height: '16px', width: '16px' }}
    />
  );
};

export default AIModelImageMapper;
