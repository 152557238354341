import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ContentContainer } from './styles';
import { useMemo } from 'react';
import { ReactComponent as DocAnalystIcon } from 'src/assets/miniExperts/docAnalyst.svg';
import { ImageWrapper } from 'src/content/MiniExperts/MainMenu/styles';

type Props = {
  handleInitiateAnalysis: () => void;
  isDisableRunAnalysisButton: boolean;
};

const InitiateAnalysisPlaceholder = ({
  handleInitiateAnalysis,
  isDisableRunAnalysisButton
}) => {
  const { t } = useTranslation();
  const disableLinkColor = useMemo(() => {
    return isDisableRunAnalysisButton ? 'GrayText' : 'primary';
  }, [isDisableRunAnalysisButton]);
  return (
    <ContentContainer justifyContent="center" alignItems="center">
      <Box
        width={'600px'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        rowGap={'20px'}
        textAlign={'center'}
      >
        <ImageWrapper>
          <DocAnalystIcon />
        </ImageWrapper>
        <Typography fontSize={'20px'} sx={{ whiteSpace: 'pre' }}>
          {t(T.runAnalysisPlaceholder)}
          <Link
            color={disableLinkColor}
            borderBottom={'1px solid '}
            underline="none"
            sx={!isDisableRunAnalysisButton ? { cursor: 'pointer' } : {}}
            onClick={
              !isDisableRunAnalysisButton ? handleInitiateAnalysis : undefined
            }
          >
            {t(T.runAnalysis)}
          </Link>
          ”
        </Typography>
      </Box>
    </ContentContainer>
  );
};

export default InitiateAnalysisPlaceholder;
