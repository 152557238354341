import { List } from '@mui/material';
import { useCallback } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ReactComponent as ArrowIcon } from 'src/assets/ChevronRight.svg';
import { ReactComponent as HistoryIcon } from 'src/assets/sidebarIcon/history.svg';
import { toggleChatHistoryPan } from 'src/redux/slices/chat';
import { useDispatch } from 'src/redux/store';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import SidebarMenuItem from '../Menu/item';
import { MenuWrapper, SubMenuWrapper } from '../styles';

const History = () => {
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenHistoryDrawer = useCallback(() => {
    dispatch(toggleChatHistoryPan());
  }, []);

  return (
    <MenuWrapper>
      <SubMenuWrapper>
        <List component="div">
          <SidebarMenuItem
            key={0}
            active={true}
            name={t(T.chatHistory)}
            badgeTooltip={t(T.chatHistory)}
            isLink={false}
            icon={HistoryIcon}
            endIcon={<ArrowIcon fill="white" />}
            handleHistoryButtonClick={handleOpenHistoryDrawer}
          />
        </List>
      </SubMenuWrapper>
    </MenuWrapper>
  );
};

export default History;
