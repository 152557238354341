import { alpha, Box, MenuItem, Select, TextField, useTheme } from '@mui/material';
import { ChangeEvent } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface SearchWithFieldsProps {
  search: string;
  setSearch: (newValue: string) => void;
  searchField: string;
  setSearchField: (newValue: string) => void;
  searchFields: { value: string; label: string }[];
}

const SearchWithFields = ({
  search,
  setSearch,
  searchField,
  setSearchField,
  searchFields
}: SearchWithFieldsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleFieldChange = (event) => {
    setSearchField(event.target.value);
  };

  return (
    <Box>
      <TextField
        value={search}
        onChange={handleChange}
        placeholder={t(T.search)}
        size="small"
        sx={{
          width: '350px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px 0 0 8px'
          }
        }}
        InputProps={{
          startAdornment: (
            <SearchOutlinedIcon
              color="disabled"
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(18)
              })}
            />
          )
        }}
      />

      <Select
        value={searchField}
        onChange={handleFieldChange}
        displayEmpty
        size="small"
        sx={{
          borderRadius: '0 8px 8px 0',
          backgroundColor: search ? alpha(theme.colors.secondary.main, 0.2) : ''
        }}
      >
        {searchFields.map(({ value, label }) => (
          <MenuItem value={value} key={value}>
            {t(label)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SearchWithFields;
