import {
  DataTypeProvider,
  DataTypeProviderProps
} from '@devexpress/dx-react-grid';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { sharingRoleDescriptions } from 'src/content/Documents/utils/utils';

const NameFormatter = ({
  value,
  row
}: DataTypeProvider.ValueFormatterProps) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography variant="body1">{value}</Typography>
      <Typography variant="subtitle1">
        {t(sharingRoleDescriptions[value]) || row.description}
      </Typography>
    </Stack>
  );
};
export const NameProvider = (props: DataTypeProviderProps) => {
  return <DataTypeProvider formatterComponent={NameFormatter} {...props} />;
};
