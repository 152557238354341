import { Theme } from '@mui/material/styles';
import numeral from 'numeral';
import { chartColors } from '../utils';

export const sortUsageStats = (usageStats) => {
  if (!usageStats?.daily_usage?.length) return [];

  const groupedData = usageStats.daily_usage.reduce((result, entry) => {
    const date = entry.date;
    const type = entry.type;
    if (!result[date]) {
      result[date] = { date: date };
    }
    if (type) {
      result[date][type] = (result[date][type] || 0) + entry.consumed;
    }
    return result;
  }, {});
  const sortedUsageStarts = Object.values(groupedData);
  return sortedUsageStarts;
};

export const getPercentage = (total: number, consumed: number) => {
  return !total ? 0 : Math.round((consumed / total) * 100);
};

export const getFormattedNumber = (
  value: number,
  capitalize: boolean = false
) => {
  const formattedNumber = numeral(value).format('a');
  if (capitalize) {
    return formattedNumber.toUpperCase();
  }
  return formattedNumber;
};

export const formatFloatNumber = (
  value: number | string,
  floatingPoint: number = 1
) => Number(value).toFixed(floatingPoint);

export const getColor = (type) => {
  switch (type) {
    case 'E':
      return chartColors.primary;
    case 'F':
      return chartColors.secondary;
    case 'C':
      return chartColors.tertiary;
    default:
      return chartColors.default;
  }
};
