import { useContext, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router';
import { convertDate } from 'src/utils/utils';
import {
  ActionsWrapper,
  StartChatActionWrapper,
  InitialBoxContainer,
  getCardIconsStyles,
  getDocCardWrapperStyles,
  useCardStyles,
  getCardTitleStyles,
  CollectionDisclaimerBox
} from './styles';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShareIcon } from 'src/assets/documentIcons/share-icon.svg';
import { ReactComponent as MoreIcon } from 'src/assets/documentIcons/more-icon.svg';
import { ReactComponent as RenameIcon } from 'src/assets/documentIcons/rename-icon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';

import { RESOURCE_TYPE, USER_RESOURCE_PERMISSION } from 'src/types/enum';
import { ICustomCardContent } from './type';
import { EXEMPTED_WORKSPACES_COLLECTIONS } from '../../utils/utils';
import { DATA_LIBRARY_ROUTES } from 'src/utils/constants';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { ReactComponent as StarRateIcon } from 'src/assets/documentIcons/StarRateIcon.svg';
import { ReactComponent as StarOutlineIcon } from 'src/assets/documentIcons/star-icon.svg';
import Disclaimer from './disclaimer';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { RootState } from 'src/redux/store';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import { ActionButton } from '../../utils/styles';

const CardView = (props: ICustomCardContent) => {
  const {
    index,
    isHeader,
    row,
    breadcrumbs,
    filteredKeys,
    ignoreExemptionOfWorkspace,
    workspace: {
      setRows,
      handleCreateUpdateNewCard,
      handleShareResourceClick,
      handleStartChatClick,
      setDeletedRowId,
      setDeleteOpen,
      isMoveDialogOpen,
      setActiveDirectory,
      permissions
    }
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const classes = useCardStyles();
  const isExemptedWorkspaceCollection = useMemo(
    () =>
      Object.values(EXEMPTED_WORKSPACES_COLLECTIONS)?.includes(
        row?.type_code
      ) || false,
    [row?.type_code]
  );

  const isSystemWorkspace = useMemo(
    (): boolean =>
      row.type_code === EXEMPTED_WORKSPACES_COLLECTIONS.PRIVATE ||
      row.type_code === EXEMPTED_WORKSPACES_COLLECTIONS.PUBLIC,
    [row]
  );

  const disclaimerText = useMemo(() => {
    return row.type_code === EXEMPTED_WORKSPACES_COLLECTIONS.PRIVATE
      ? T.privateDisclaimer
      : T.publicDisclaimer;
  }, [row.type_code]);

  const [showInitialEditIcon, setShowInitialEditIcon] = useState<number>(-1);

  const { updateFavourite, isUpdatingFavourite } = useContext(DocumentsContext);
  const { isFavouritesFetching } = useSelector(
    (state: RootState) => state.data
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isActionPopoverOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const actionPopoverId = useMemo(
    () => (isActionPopoverOpen ? 'more-actions' : undefined),
    [isActionPopoverOpen]
  );

  const handleNavigate = () => {
    if (isHeader) {
      return;
    }

    if (!isMoveDialogOpen) {
      setRows([]);
      navigate(
        `/data-library/${DATA_LIBRARY_ROUTES[row.type_code]}/${row?.id}`,
        {
          state: filteredKeys
        }
      );
      return;
    }

    if (!isExemptedWorkspaceCollection || ignoreExemptionOfWorkspace) {
      setActiveDirectory(row);
    }
  };

  const showDeleteButton = useMemo(() => {
    return (
      permissions?.[USER_RESOURCE_PERMISSION.DELETE_FILES_FOLDERS]?.value &&
      !isSystemWorkspace
    );
  }, [permissions, row]);
  const handleShare = (e: any) => {
    e.stopPropagation();
    handleShareResourceClick(row);
  };

  const handleStartChat = (e: any) => {
    e.stopPropagation();
    handleStartChatClick(row);
  };

  const handleRename = (e: any) => {
    e.stopPropagation();
    handleCreateUpdateNewCard(true, row);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    setDeletedRowId(row?.id);
    setDeleteOpen(true);
  };

  const handleAddFavourite = (e: any) => {
    e.stopPropagation();
    if (!isUpdatingFavourite && !isFavouritesFetching) {
      dispatch(setIsFavouritesFetching(true));
      updateFavourite(row?.id, !row?.isFavourite);
    }
  };

  const handleMouseEnter = () => {
    if (
      !isHeader &&
      permissions?.[USER_RESOURCE_PERMISSION.RENAME_FILES_FOLDERS]?.value
    ) {
      setShowInitialEditIcon(index);
    }
  };

  const handleMouseOut = () => {
    if (!isHeader) {
      setShowInitialEditIcon(-1);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = (e?) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <Card
      id={
        row?.type_code === RESOURCE_TYPE.PRIVATE_WORKSPACE
          ? ONBOARDING_STEPS_TARGET.DOCUMENT_PRIVATE_WORKSPACE
          : undefined
      }
      key={index}
      variant="outlined"
      className={classes.documentCardWrapper}
      sx={(theme) => ({
        ...(isHeader
          ? {
              cursor: 'default',
              border: 'none'
            }
          : { border: '1px solid rgba(170, 190, 222, 0.40)' }),
        ...(!ignoreExemptionOfWorkspace &&
          isExemptedWorkspaceCollection &&
          isMoveDialogOpen && {
            opacity: 0.6
          }),
        ...getDocCardWrapperStyles(isHeader, row, theme)
      })}
      onClick={handleNavigate}
    >
      <CardContent sx={{ padding: '0 !important' }}>
        <div className={classes.initialBoxInnerWrapper}>
          <InitialBoxContainer
            style={{
              backgroundColor: row?.initials_color || theme.colors.primary.dark,
              border: isHeader ? '10px solid white' : 'none',
              borderRadius: '16px',
              boxShadow: isHeader ? '0px 0px 4px 0px #00000040' : 'none'
            }}
            aria-haspopup="true"
            onMouseEnter={handleMouseEnter}
            onMouseOut={handleMouseOut}
          >
            {row?.initials}
          </InitialBoxContainer>
          <Box {...(!isHeader && { maxWidth: 360 })}>
            <Box
              className={classes.workspaceTitle}
              sx={(theme) => ({
                [theme.breakpoints.up('sm')]: {
                  ...(!isHeader && { maxWidth: 230 }),
                  fontSize: 18
                },
                [theme.breakpoints.down('sm')]: {
                  maxWidth: 400
                },
                ...getCardTitleStyles(isHeader)
              })}
              title={row?.display_name}
            >
              {row?.display_name}
              {isHeader && breadcrumbs}
              {isSystemWorkspace && isHeader && (
                <CollectionDisclaimerBox>
                  <Disclaimer disclaimerText={disclaimerText} />
                </CollectionDisclaimerBox>
              )}
            </Box>
            {!isHeader && (
              <>
                {!isSystemWorkspace && (
                  <Typography className={classes.cardTypography} gutterBottom>
                    {`${t(T.modifiedBy)}: ${row?.modified_by}`}
                  </Typography>
                )}
                {!!convertDate(row?.modified_on, t) && (
                  <Typography className={classes.cardTypography} gutterBottom>
                    {t(T.modifiedWithTime, {
                      time: `${convertDate(row?.modified_on, t)}`
                    })}
                  </Typography>
                )}
                {isSystemWorkspace && !isHeader && (
                  <Disclaimer
                    disclaimerText={disclaimerText}
                    isFavourite={row?.isFavourite}
                  />
                )}
              </>
            )}
          </Box>
        </div>
        {!isHeader && !isMoveDialogOpen && (
          <>
            <StartChatActionWrapper className="actionWrapper">
              {permissions?.[USER_RESOURCE_PERMISSION.QUERY_FILES_FOLDERS]
                ?.value && (
                <Button
                  title={t(T.startChat)}
                  onClick={handleStartChat}
                  color="secondary"
                  size="small"
                  variant="contained"
                  sx={{
                    px: 0.8,
                    py: 0.5
                  }}
                >
                  {t(T.startChat)}
                </Button>
              )}
            </StartChatActionWrapper>
            <ActionsWrapper className="actionWrapper">
              <Grid
                container
                flexWrap={'nowrap'}
                sx={{ height: '38px', lineHeight: '38px' }}
              >
                {((permissions?.[USER_RESOURCE_PERMISSION.RENAME_FILES_FOLDERS]
                  ?.value &&
                  !isExemptedWorkspaceCollection) ||
                  showDeleteButton) && (
                  <Grid item xs>
                    <IconButton
                      title={t(T.moreActions)}
                      sx={getCardIconsStyles(isHeader)}
                      size="small"
                      aria-describedby={actionPopoverId}
                      onClick={handleClick}
                    >
                      <MoreIcon />
                    </IconButton>
                  </Grid>
                )}
                {permissions?.[USER_RESOURCE_PERMISSION.ASSIGN_USER_ROLES]
                  ?.value &&
                  !isExemptedWorkspaceCollection && (
                    <Grid item xs>
                      <IconButton
                        title={t(T.share)}
                        onClick={handleShare}
                        sx={getCardIconsStyles(isHeader)}
                        size="small"
                      >
                        <ShareIcon />
                      </IconButton>
                    </Grid>
                  )}
                <Popover
                  id={actionPopoverId}
                  open={isActionPopoverOpen}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                  }}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  disablePortal={true}
                >
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignContent={'flex-start'}
                    justifyContent={'flex-start'}
                    textAlign={'left'}
                    sx={{
                      backgroundColor: 'white'
                    }}
                  >
                    {showDeleteButton && (
                      <ActionButton onClick={handleDelete}>
                        <DeleteIcon />
                        <Typography
                          sx={{
                            marginLeft: 1,
                            color: theme.colors.custom.labelColor
                          }}
                        >
                          {t(T.delete)}
                        </Typography>
                      </ActionButton>
                    )}
                    {permissions?.[
                      USER_RESOURCE_PERMISSION.RENAME_FILES_FOLDERS
                    ]?.value &&
                      !isExemptedWorkspaceCollection && (
                        <>
                          <ActionButton onClick={handleRename}>
                            <RenameIcon />
                            <Typography
                              sx={{
                                marginLeft: 1,
                                color: theme.colors.custom.labelColor
                              }}
                            >
                              {t(T.rename)}
                            </Typography>
                          </ActionButton>
                        </>
                      )}
                  </Box>
                </Popover>
                <Grid item xs>
                  <IconButton
                    title={
                      row?.isFavourite
                        ? t(T.unmarkFavourite)
                        : t(T.markFavourite)
                    }
                    onClick={handleAddFavourite}
                    sx={getCardIconsStyles(isHeader)}
                    size="small"
                  >
                    {row?.isFavourite ? (
                      <StarRateIcon className="starRateIcon" />
                    ) : (
                      <StarOutlineIcon />
                    )}
                  </IconButton>
                </Grid>
              </Grid>
            </ActionsWrapper>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CardView;
