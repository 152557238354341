import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Label from 'src/components/Label';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const FilesCountFormatter = ({
  value,
  row
}: DataTypeProvider.ValueFormatterProps) => {
  const { t } = useTranslation();

  if (row.is_corrupted) {
    return (
      <Box display="flex" alignItems="center">
        <Label color="error">{t(T.datasetCorrupted)}</Label>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center">
      <Chip label={value} />
    </Box>
  );
};
