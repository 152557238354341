import {
  FilterComponents,
  FilterElementTypes
} from 'src/types/custom_component';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const getFilters = (datasources: any) => {
  const filters: FilterComponents[] = [
    {
      name: 'Search',
      type: FilterElementTypes.Text,
      elementWidth: 250,
      dataField: 's',
      placeholder: T.search,
      showIcon: true,
      showDivider: true
    },
    {
      name: 'Created By',
      type: FilterElementTypes.SearchAutoComplete,
      dataField: 'created_by',
      placeholder: T.createdBy,
      elementWidth: 250,
      searchApi: datasources.searchApi,
      labelKey: 'attributes.full_name[0]',
      valueKey: 'username',
      showLabel: true,
      label: T.createdBy
    }
  ];

  return filters;
};
