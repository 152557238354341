import { InfoOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material';
import React, { PropsWithChildren, useCallback } from 'react';
import HeaderButton from './HeaderButton';
import { IActionButtonProps } from 'src/types/header';
import HeaderFilter from '../HeaderFilterwithPopOver';
import { FilterComponents } from 'src/types/custom_component';
import { StringKeys } from 'src/types/base';
type FilterProps = {
  onSearch: (selectedFields: { [z: string]: any }) => any;
  onReset: (initialObject: any) => void;
  forceReset?: boolean;
  elementsList: FilterComponents[];
  Button?: any;
  showFilters?: boolean;
  disableFilters?: boolean;
  filtersValue?: StringKeys;
  setFiltersValue?: React.Dispatch<React.SetStateAction<StringKeys>>;
  hideFilterButton?: boolean;
};
type Props = PropsWithChildren<{
  title: string;
  showInfoIcon?: boolean;
  infoIconTooltipText?: string;
  filterProps?: FilterProps;
  actionButtons: IActionButtonProps[];
}>;

const PageHeader = ({
  children,
  title,
  showInfoIcon,
  infoIconTooltipText = '',
  actionButtons = [],
  filterProps
}: Props) => {
  const renderHeaderButton = useCallback(
    (actionButton: IActionButtonProps, index: number) => {
      if (!actionButton.visible) {
        return null;
      }
      return (
        <React.Fragment key={index}>
          <Grid item display={'flex'}>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid item>
            <HeaderButton
              isButtonDisabled={actionButton.disabled}
              onClick={actionButton.onClick}
              tooltipMessage={actionButton.tooltipMsg}
              isPopover={actionButton.popover}
              buttonProps={{
                color: actionButton.color,
                startIcon: <actionButton.icon />,
                variant: actionButton.variant
              }}
              popoverElements={actionButton.popoverElements}
              btnText={actionButton.text}
              elemId={actionButton.elementId}
            />
          </Grid>
        </React.Fragment>
      );
    },
    []
  );

  return (
    <Paper sx={(theme) => ({ paddingY: theme.spacing(1) })} elevation={0}>
      <Grid
        container
        sx={{ height: '42.5px' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item>
          <Box display={'flex'}>
            <Typography variant="h3">{title}</Typography>
            {showInfoIcon && (
              <Tooltip title={infoIconTooltipText} placement="right">
                <InfoOutlined
                  sx={(theme) => ({
                    color: theme.colors.custom.iconColor,
                    padding: '4px'
                  })}
                />
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Grid container spacing={1.5}>
            {filterProps && (
              <HeaderFilter
                {...filterProps}
                componentsValue={filterProps.filtersValue}
                setComponentValue={filterProps.setFiltersValue}
              />
            )}

            {actionButtons.map(renderHeaderButton)}
          </Grid>
        </Grid>
      </Grid>
      {children && <Box>{children}</Box>}
    </Paper>
  );
};

export default PageHeader;
