import {
  alpha,
  Box,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
  useTheme
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useCallback, useState } from 'react';
import { StaticDatePicker } from '@mui/lab';
import { format, set } from 'date-fns';

interface DateFilterProps {
  placeholder: string;
  value: Date;
  onChange: (newValue: Date) => void;
}

const DateFilter = ({ placeholder, value, onChange }: DateFilterProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [localValue, setLocalValue] = useState(value);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onValueChange = useCallback((value) => {
    setLocalValue(
      set(value, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    );
  }, []);

  const setDateToToday = () => {
    setLocalValue(new Date());
  };

  const applyValue = () => {
    onChange(localValue);
    setAnchorEl(null);
  };

  const resetValue = () => {
    onChange(null);
    setAnchorEl(null);
    setLocalValue(null);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
    setLocalValue(value);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleClick}
        startIcon={<CalendarTodayIcon />}
        sx={{
          borderColor: 'lightgray !important',
          borderRadius: '8px',
          fontWeight: 400,
          backgroundColor: value ? alpha(theme.colors.secondary.main, 0.2) : ''
        }}
      >
        {value ? format(value, 'dd MMM, yy') : placeholder}
      </Button>

      {!!anchorEl && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            open={!!anchorEl}
            anchorEl={anchorEl}
            style={{ zIndex: '501' }}
          >
            <Paper sx={{ borderRadius: '8px', border: '1px solid lightgray' }}>
              <Box>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  paddingTop="16px"
                  paddingX="16px"
                  alignItems="center"
                >
                  <Typography marginLeft="12px">
                    {localValue
                      ? format(localValue as Date, 'dd MMM, yyyy')
                      : '--/--/----'}
                  </Typography>

                  <Button
                    size="small"
                    variant="outlined"
                    onClick={setDateToToday}
                  >
                    Today
                  </Button>
                </Box>

                <StaticDatePicker
                  value={localValue}
                  onChange={onValueChange}
                  renderInput={() => null}
                  showToolbar={false}
                  showDaysOutsideCurrentMonth
                  autoFocus
                  disableFuture
                />

                <Box
                  display="flex"
                  gap="8px"
                  paddingX="16px"
                  paddingY="16px"
                  borderTop="1px solid lightgray"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={resetValue}
                    fullWidth
                  >
                    Reset
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={applyValue}
                    fullWidth
                  >
                    Apply
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};

export default DateFilter;
