import { Add, DeleteOutlineOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IActionButtonProps } from 'src/types/header';
import { RootState } from 'src/redux/store';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

type Props = {
  selectedUserGroups?: (string | number)[];
  havePermissionToDeleteUserGroup?: boolean;
  handleClickAddButton?: () => void;
  handleClickDeleteButton?: () => void;
  showDeleteButton?: boolean;
  showAddButton?: boolean;
};

export const ActionButtons = ({
  selectedUserGroups = [],
  havePermissionToDeleteUserGroup = false,
  handleClickAddButton = () => {},
  handleClickDeleteButton = () => {},
  showDeleteButton = false,
  showAddButton = false
}): IActionButtonProps[] => {
  const { t } = useTranslation();
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  return [
    {
      visible: havePermissionToDeleteUserGroup && showDeleteButton,
      variant: 'text',
      color: 'primary',
      icon: DeleteOutlineOutlined,
      disabled: !selectedUserGroups.length,
      tooltipMsg: t(T.selectItemsToDelete),
      popover: false,
      onClick: handleClickDeleteButton,
      text: t(T.delete)
    },
    {
      visible:
        !!userAppPermissions[USER_APP_PERMISSIONS.CREATE_USER_GROUPS]?.value &&
        showAddButton,
      variant: 'outlined',
      color: 'primary',
      icon: Add,
      disabled: false,
      tooltipMsg: '',
      popover: false,
      onClick: handleClickAddButton,
      text: t(T.add)
    }
  ];
};
