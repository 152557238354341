import { Box } from '@mui/material';

type GridContainerProps = {
  length: number;
  children: React.ReactNode;
  isCardContent?: boolean;
  height?: string;
  minHeight?: string;
};

export const GridContainer = ({
  length,
  children,
  height,
  minHeight
}: GridContainerProps) => (
  <Box
    sx={{
      ...(length && { height: height || '100%' }),
      ...(minHeight && { minHeight }),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent'
    }}
  >
    {children}
  </Box>
);

export default GridContainer;
