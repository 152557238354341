import { USER_APP_PERMISSIONS } from 'src/types/enum';
import getItemData from './itemData';
import { ReactNode } from 'react';
import { StringKeys } from 'src/types/base';

export interface FavouriteItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  name: string;
  display_name: string;
  id: number;
  workspaceName?: string;
}

export interface FavouriteItems {
  item: FavouriteItem;
}

const getFavouriteItems = (
  userAppPermissions,
  userFavourites: any = []
): FavouriteItems[] | [] => {
  if (
    userAppPermissions?.[USER_APP_PERMISSIONS.VIEW_ROOT_FOLDER_CONTENT]?.value
  ) {
    return userFavourites && userFavourites.length
      ? userFavourites?.map((favourite: StringKeys) => {
          const itemData = getItemData(favourite);
          return {
            item: {
              name: favourite.display_name || favourite.name,
              id: favourite.item_id,
              icon: itemData.icon,
              link: itemData.link
            }
          };
        })
      : [];
  }
  return [];
};

export default getFavouriteItems;
