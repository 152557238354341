import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'src/assets/documentIcons/Info icon.svg';
import { DisclaimerText } from './styles';

type Props = {
  disclaimerText: string;
  isFavourite?: boolean;
};

const Disclaimer = ({ disclaimerText, isFavourite }: Props) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Box display={'flex'} flexDirection={'row'} gap={'4px'} padding={'2px 0'}>
      <InfoIcon />
      <DisclaimerText width={{ xs: '100%', xl: isFavourite ? '95%' : '100%' }}>
        {t(disclaimerText)}
      </DisclaimerText>
    </Box>
  );
};

export default Disclaimer;
