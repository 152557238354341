import { InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFormattedNumber } from 'src/content/Analytics/External/utils';

type Props = {
  value: number;
  title: string;
  progressHeader: string;
  showInfoIcon?: boolean;
  infoMsg?: string;
  total: number;
  consumed: number;
  unit: string;
  dateFilter?: JSX.Element;
  creditUsageMsg: string;
  loading?: boolean;
  icon?: JSX.Element;
  capitalizeUnits?: boolean;
};

const BorderLinearProgress = styled(LinearProgress)(({ value }) => ({
  height: 26,
  borderRadius: 30,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 30,
    backgroundColor: value < 90 ? '#5166CC' : '#CC5158'
  }
}));

const ProgressBar = ({
  value,
  title,
  progressHeader,
  showInfoIcon = false,
  infoMsg = '',
  total = 0,
  consumed = 0,
  unit,
  dateFilter,
  creditUsageMsg,
  loading = false,
  icon,
  capitalizeUnits = false
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Paper elevation={0}>
      <Box
        display="flex"
        marginBottom={'10px'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={{ marginRight: 1 }}>{icon}</Box>
          <Typography
            variant="body1"
            fontSize={22}
            color="primary"
            fontWeight={500}
          >
            {title}
          </Typography>
          {showInfoIcon && (
            <Tooltip title={infoMsg} placement="right">
              <InfoOutlined
                sx={(theme) => ({
                  color: theme.colors.custom.iconColor,
                  padding: '4px'
                })}
              />
            </Tooltip>
          )}
        </Box>
        <Box>{dateFilter}</Box>
      </Box>
      <Card
        sx={(theme) => ({
          border: `1px solid rgba(170, 190, 222,0.40)`,
          borderRadius: '6px'
        })}
        elevation={0}
      >
        <CardHeader
          title={
            <Typography
              variant="body1"
              fontSize={20}
              color="primary"
              fontWeight={500}
            >
              {progressHeader}
            </Typography>
          }
          sx={{ paddingBottom: 0 }}
        />
        {loading ? (
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            sx={{
              paddingTop: 0.75,
              paddingBottom: '24px',
              paddingX: 3
            }}
          >
            <Skeleton
              variant="rectangular"
              height={26}
              animation="wave"
              width={'80%'}
              sx={{ borderRadius: 30 }}
            />
            <Skeleton
              variant="rectangular"
              height={26}
              animation="wave"
              width="18%"
            />
          </Box>
        ) : (
          <CardContent
            sx={{
              paddingY: 0.75
            }}
          >
            <Grid
              container
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item xs position={'relative'} padding={0} margin={0}>
                <BorderLinearProgress
                  variant="determinate"
                  value={Math.min(value, 100)}
                  sx={{
                    color: 'black',
                    backgroundColor: '#E9F1FF'
                  }}
                />
                <Typography
                  sx={{
                    position: 'absolute',
                    top: '1px',

                    ...(value <= 10
                      ? { left: `${value + 1}%` }
                      : {
                          right: `${100 - Math.min(98, value)}%`
                        }),

                    padding: 2,
                    paddingRight: 0,
                    color: value > 10 ? 'white' : 'inherit'
                  }}
                  fontWeight={700}
                  fontSize={16}
                >
                  {value}%
                </Typography>
              </Grid>
              <Grid item display={'flex'} alignItems={'center'}>
                <Tooltip title={creditUsageMsg}>
                  <Typography
                    variant="h3"
                    fontSize={18}
                    sx={{ borderBottom: '1px dashed #222F59' }}
                  >
                    {`${getFormattedNumber(
                      consumed,
                      capitalizeUnits
                    )} / ${getFormattedNumber(total, capitalizeUnits)}`}
                  </Typography>
                </Tooltip>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  fontSize={18}
                  color="rgba(34,47,89,0.50)"
                  paddingLeft={1}
                >
                  {unit}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </Paper>
  );
};

export default ProgressBar;
