import { Box, styled } from '@mui/material';

export const MenuOptionWrapper = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== 'showCursor';
  }
})(({ showCursor = false }: { showCursor: boolean }) => ({
  display: 'flex',
  minHeight: '272px',
  padding: '13px 15px',
  gap: '12px',
  backgroundColor: showCursor ? '#fff' : 'rgba(202, 217, 242, 0.20)',
  border: '1px solid #E3E5E8',
  borderRadius: '10px',
  alignItems: 'center',
  cursor: showCursor ? 'pointer' : 'default'
}));

export const MainMenuContainer = styled(Box)(() => ({
  width: '720px',
  padding: '24px',
  rowGap: '14px',
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto'
}));

export const ImageWrapper = styled(Box)({
  flexShrink: 0,
  width: '80px',
  height: '80px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#CAD9F233',
  borderRadius: '10px',
  overflow: 'hidden'
});

export const CustomList = styled('ul')({
  margin: 0
});

export const CustomListItem = styled('li')(({ theme }) => ({
  color: theme.palette.primary.main
}));
