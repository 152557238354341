import { Typography, styled } from '@mui/material';

export const PriceLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-left: 5;
  color: #3dae8e;
  line-height: 24px;
  letter-spacing: 0.5px;
`;
