import { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  useLazyGetDataLibraryAnalyticsQuery,
  useLazyGetUserAnalyticsQuery,
  useLazyGetChatAnalyticsQuery
} from 'src/services/api';
import { useTranslation } from 'react-i18next';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { ErrorContext } from 'src/utils/errorMappings';
import DataLibraryAnalytics from './DataLibrary';
import ChatAnalytics from './Chat';
import Loader from 'src/components/Loader';
import { TitleTypography } from './styles';

const InternalAnalytics = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [
    fetchDataLibraryStats,
    { data: dataLibraryStats, isFetching: isFetchingDataLibraryStats }
  ] = useLazyQuery({
    api: useLazyGetDataLibraryAnalyticsQuery,
    errorContext: ErrorContext.GENERAL
  });
  const [fetchChatStats, { data: chatData, isFetching: isFetchingChatStats }] =
    useLazyQuery({
      api: useLazyGetChatAnalyticsQuery,
      errorContext: ErrorContext.GENERAL
    });
  const [fetchUserStats, { data: userData, isFetching: isFetchingUserStats }] =
    useLazyQuery({
      api: useLazyGetUserAnalyticsQuery,
      errorContext: ErrorContext.GENERAL
    });

  useEffect(() => {
    fetchDataLibraryStats({ params: undefined });
    fetchChatStats({ params: undefined });
    fetchUserStats({ params: undefined });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {T.empowerGPT} - {t(T.analytics)}
        </title>
      </Helmet>
      <Box
        sx={{
          maxHeight: '100%',
          overflow: 'auto',
          height: '100%',
          backgroundColor: theme.colors.alpha.white[100]
        }}
        padding={2}
      >
        <TitleTypography>{t(T.analytics)}</TitleTypography>
        <Box sx={{ marginX: 15 }}>
          {isFetchingDataLibraryStats ||
          isFetchingChatStats ||
          isFetchingUserStats ? (
            <Loader />
          ) : (
            <>
              <ChatAnalytics data={chatData || {}} />
              <DataLibraryAnalytics
                libraryData={dataLibraryStats || []}
                userData={userData || {}}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default InternalAnalytics;
