import useMutation from './useMutation';
import { useUpdateResourceMutation } from 'src/services/api';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ErrorContext } from 'src/utils/errorMappings';
import { StringKeys } from 'src/types/base';

interface UpdateAccessRightsParams {
  id: number;
  role: string;
  addedUsers?: StringKeys[];
  removedUsers?: StringKeys[];
  assignmentType?: string;
  type_id?: string;
}

export type AccessRightsUpdateHandlerType = (
  params: UpdateAccessRightsParams
) => Promise<void>;

const useUpdateAccessRights = () => {
  const [updateAccessRights, { isLoading: isUpdatingAccess }] = useMutation({
    api: useUpdateResourceMutation,
    errorContext: ErrorContext.FILES
  });

  const handleUpdateAccessRights: AccessRightsUpdateHandlerType = async ({
    id,
    role,
    addedUsers = [],
    removedUsers = [],
    assignmentType,
    type_id
  }) => {
    const addPerms = addedUsers.length
      ? addedUsers.map((user) => ({
          user_group: user.value || user,
          role: role,
          type_id: user.type_id || type_id
        }))
      : [];
    const removePerms = removedUsers?.length
      ? removedUsers.map((accessRight) => ({
          user_group: accessRight.value || accessRight,
          role: accessRight.role,
          type_id: accessRight.type_id
        }))
      : [];
    await updateAccessRights({
      params: {
        params: {
          op: 'set-perms',
          id: id,
          add_perms: JSON.stringify(addPerms),
          remove_perms: JSON.stringify(removePerms),
          assignment_type: assignmentType || 'default'
        }
      },
      fallbackMsg: T.resourceSharingErrorMessage,
      successMsg: T.resourceSharingSuccessMessage
    });
  };

  return {
    handleUpdateAccessRights,
    isUpdatingAccess
  };
};

export default useUpdateAccessRights;
