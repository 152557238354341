import Authenticated from 'src/components/Authenticated';
import mainRoutes from './dashboards';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import Status404 from 'src/content/Status404';
import BaseLayout from 'src/layouts/BaseLayout';

const router = (userAppPermissions) => [
  {
    path: '/',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        children: mainRoutes(userAppPermissions)
      }
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default router;
