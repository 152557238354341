import {
  Avatar,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import DialogTitle from 'src/components/Dialog/DialogTitle';

interface AvatarListDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  nameList: string[];
  getInitials: (value: string) => string | JSX.Element;
}
const AvatarListDialog = ({
  isOpen,
  handleClose,
  title,
  nameList,
  getInitials
}: AvatarListDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      sx={(theme) => ({
        maxHeight: '50%',
        position: 'absolute',
        top: '25%',
        [theme.breakpoints.down('sm')]: {
          top: '10%',
          maxHeight: '80%'
        }
      })}
    >
      <DialogTitle title={title} onClose={handleClose} showCloseIcon />
      <DialogContent
        dividers
        sx={{
          '&.MuiDialogContent-root': {
            padding: 0
          }
        }}
      >
        <List
          sx={{
            padding: 0
          }}
        >
          {nameList.map((name, index) => {
            return (
              <ListItem key={index} divider={nameList.length - 1 !== index}>
                <ListItemAvatar>
                  <Avatar
                    sx={(theme) => ({
                      bgcolor: theme.colors.primaryAlt.main,
                      color: theme.colors.primary.main,
                      fontSize: '1rem',
                      fontWeight: 'normal',
                      height: 38,
                      width: 38
                    })}
                  >
                    <Typography
                      color="primary"
                      sx={{
                        textTransform: 'uppercase'
                      }}
                    >
                      {getInitials(name)}
                    </Typography>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={name} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default AvatarListDialog;
