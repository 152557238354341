import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import logo from 'src/assets/empowergpt-logo.png';
import { sortFiles } from 'src/content/Chat/utils';

const createFontStyle = (
  size: number,
  bold?: boolean,
  argb?: string
): Partial<ExcelJS.Font> => {
  return {
    size,
    color: {
      argb: argb || 'ff000000'
    },
    bold: !!bold
  };
};

const createFillPatternStyle = (argb?: string): Partial<ExcelJS.Fill> => {
  return {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb
    }
  };
};

const titleFontStyle = createFontStyle(36);
const metadataHeadingFontStyle = createFontStyle(16, true, 'ff203d82');
const metadataFontStyle = createFontStyle(16, true);
const headerFontStyle = createFontStyle(14, true);

const titleFillStyles = createFillPatternStyle('ff95b3d7');
const headerFillStyles = createFillPatternStyle('ff9ecef6');

const allThinBorders: Omit<ExcelJS.Borders, 'diagonal'> = {
  top: { style: 'thin' },
  left: { style: 'thin' },
  bottom: { style: 'thin' },
  right: { style: 'thin' }
};

const verticalMiddleAlignment: Partial<ExcelJS.Alignment> = {
  vertical: 'middle'
};

const cellsToMerge = [
  'A1:B5',
  'C1:C5',
  'A6:C6',
  'B7:C7',
  'B8:C8',
  'B9:C9',
  'A10:C10'
];

const columnConfig = [
  { col: 1, width: 20 },
  { col: 2, width: 60 },
  { col: 3, width: 150 }
];

const rowConfig = [
  { row: 7, height: 20 },
  { row: 8, height: 20 },
  { row: 9, height: 20 },
  { row: 11, height: 40, alignment: verticalMiddleAlignment }
];

const cellConfig = [
  { cell: 'A1', fill: titleFillStyles },
  {
    cell: 'C1',
    fill: titleFillStyles,
    font: titleFontStyle,
    alignment: verticalMiddleAlignment,
    value: 'Doc Analyst'
  },
  { cell: 'A7', font: metadataHeadingFontStyle, value: 'Analysis Title' },
  { cell: 'A8', font: metadataHeadingFontStyle, value: 'Datasets' },
  { cell: 'A9', font: metadataHeadingFontStyle, value: 'Questionnaire' },
  { cell: 'B7', font: metadataFontStyle },
  { cell: 'B8', font: metadataFontStyle },
  { cell: 'B9', font: metadataFontStyle },
  {
    cell: 'A11',
    font: headerFontStyle,
    fill: headerFillStyles,
    value: 'No.'
  },
  {
    cell: 'B11',
    font: headerFontStyle,
    fill: headerFillStyles,
    value: 'Questions'
  },
  {
    cell: 'C11',
    font: headerFontStyle,
    fill: headerFillStyles,
    value: 'Responses'
  }
];

const cellsToAddAllBorders = [
  'A6',
  'A7',
  'B7',
  'A8',
  'B8',
  'A9',
  'B9',
  'A10',
  'A11',
  'B11',
  'C11'
];

const applyStyles = (ws, styles) => {
  styles.forEach(({ cell, row, col, ...styles }) => {
    let element;
    if (col) {
      element = ws.getColumn(col);
    } else if (row) {
      element = ws.getRow(row);
    } else {
      element = ws.getCell(cell);
    }
    Object.entries(styles).forEach(([styleKey, styleValue]) => {
      element[styleKey] = styleValue;
    });
  });
};

export const exportAnalysis = async (
  name: string,
  dataset: string,
  questionnaire: string,
  questions: any[]
) => {
  const workbook = new ExcelJS.Workbook();
  const ws = workbook.addWorksheet('My Sheet');
  ws.properties.defaultColWidth = 15;

  const imageId = workbook.addImage({
    base64: logo,
    extension: 'png'
  });

  // Merge cells
  cellsToMerge.forEach((cell) => {
    ws.mergeCells(cell);
  });

  // Apply styles
  applyStyles(ws, rowConfig);
  applyStyles(ws, cellConfig);
  applyStyles(ws, columnConfig);

  // Add all borders to cells
  cellsToAddAllBorders.forEach((cell) => {
    ws.getCell(cell).border = allThinBorders;
  });

  // Add logo and meta-data
  ws.addImage(imageId, {
    tl: { col: 0.2, row: 1.5 },
    ext: { width: 192, height: 47.04 },
    editAs: 'undefined'
  });
  ws.getCell('B7').value = name;
  ws.getCell('B8').value = dataset;
  ws.getCell('B9').value = questionnaire;

  function replaceDocumentsWithFiles(text) {
    const docMap = {};
    let count = 1;
    const pattern = /\[Document:\s*(\d+)\]/g;
    return text.replace(pattern, (match, p1) => {
      if (!docMap[p1]) {
        docMap[p1] = `file${count++}`;
      }
      return docMap[p1];
    });
  }
  const rowsData = questions.map(({ question, answer }, index) => {
    let finalResponse = '';
    try {
      const { response, files } = JSON.parse(answer);
      finalResponse = response;
      const sortedFiles = sortFiles({ response, files });
      finalResponse = replaceDocumentsWithFiles(finalResponse);
      sortedFiles.forEach((file, index) => {
        const reference = `[${file.name} (page: ${file.page})]`;
        finalResponse = finalResponse.replaceAll(`file${index + 1}`, reference);
      });
    } catch (e) {
      console.log(e);
    }

    return [index + 1, question, finalResponse];
  });

  const [firstRowData, ...restRowsData] = rowsData;
  const insertedRow = ws.insertRow(12, firstRowData, 'n');
  insertedRow.alignment = {
    wrapText: true,
    horizontal: 'left',
    vertical: 'top'
  };
  insertedRow.font = createFontStyle(14);

  ws.insertRows(13, restRowsData, 'i');

  // Write buffer and download file
  const excelBuffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  });
  saveAs(blob, `${name} - Analysis Report.xlsx`);
};
