import React from 'react';
import { Box, Typography } from '@mui/material';

type HeaderRowProps = {
  columns: Array<{ width?: string; flex?: number; title: string }>;
};
const HeaderRow: React.FC<HeaderRowProps> = ({ columns }) => {
  return (
    <Box
      height={'36px'}
      borderBottom={'1px solid #CAD9F2'}
      sx={{ backgroundColor: '#CAD9F266' }}
      width="100%"
      display="flex"
      alignItems="center"
      paddingLeft={'10px'}
      gap={'10px'}
    >
      {columns.map((column, index) => (
        <Box key={index} width={column.width} flex={column.flex}>
          <Typography fontWeight={500}>{column.title}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default HeaderRow;
