import { PropsWithChildren, useCallback, useState } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

type TooltipOnDisabledProps = PropsWithChildren<{
  showTooltip: boolean;
}> &
  TooltipProps;

function TooltipOnDisabled({
  title,
  children,
  showTooltip,
  ...rest
}: TooltipOnDisabledProps) {
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const handleMouseEnter = useCallback(() => {
    if (showTooltip) {
      setIsTooltipOpen(true);
    }
  }, [showTooltip]);

  const handleMouseLeave = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  return (
    <Tooltip title={title} open={isTooltipOpen} {...rest}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    </Tooltip>
  );
}

export default TooltipOnDisabled;
