import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelector } from 'src/redux/store';
import { Box, Typography, useTheme, Checkbox } from '@mui/material';
import StackedChips from 'src/components/StackedChips';
import SellIcon from '@mui/icons-material/Sell';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { RESOURCE_PROVIDERS, RESOURCE_TYPE } from 'src/types/enum';
import { ReactComponent as CollectionIcon } from 'src/assets/documentIcons/collection-icon.svg';
import { ReactComponent as WorkspaceIcon } from 'src/assets/documentIcons/workspace-icon.svg';
import { ReactComponent as FolderIcon } from 'src/assets/documentIcons/folder-icon.svg';
import { ReactComponent as SharepointFolderIcon } from 'src/assets/documentIcons/sharepoint-folder-icon.svg';
import { ReactComponent as OneDriveFolderIcon } from 'src/assets/documentIcons/onedrive-folder-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { EXEMPTED_WORKSPACES_COLLECTIONS } from '../utils/utils';
import { ArticleOutlined } from '@mui/icons-material';
import { getFileIcon } from 'src/utils/files/file';

const getIcon = (typeCode, original_format = null, provider_type = null) => {
  switch (typeCode) {
    case RESOURCE_TYPE.FILE:
      const Icon = getFileIcon(original_format, provider_type);
      if (Icon) return <Icon height={40} width={40} />;
      return <ArticleOutlined fontSize="medium" />;
    case RESOURCE_TYPE.FOLDER:
      return provider_type === RESOURCE_PROVIDERS.ONEDRIVE ? (
        <OneDriveFolderIcon />
      ) : provider_type === RESOURCE_PROVIDERS.SHAREPOINT ? (
        <SharepointFolderIcon />
      ) : (
        <FolderIcon />
      );
    case RESOURCE_TYPE.COLLECTION:
    case EXEMPTED_WORKSPACES_COLLECTIONS.FOUND_COLLECTION:
      return <CollectionIcon />;
    case RESOURCE_TYPE.WORKSPACE:
      return <WorkspaceIcon />;
    default:
      return null;
  }
};

export const NameFormatter = ({ value, row, isMoveDialogOpen }: any) => {
  const chipsParent = useRef(null);
  const theme = useTheme();
  const { name, typeCode } = value;
  const { t } = useTranslation();
  const { oneDriveProviderId, sharePointProviderId } = useSelector(
    (state) => state.data
  );
  const [maxChips, setMaxChips] = useState(null);
  const [providerType, setProviderType] = useState<string>('');

  useEffect(() => {
    setProviderType(
      row?.provider_id === oneDriveProviderId
        ? RESOURCE_PROVIDERS.ONEDRIVE
        : row?.provider_id === sharePointProviderId
        ? RESOURCE_PROVIDERS.SHAREPOINT
        : ''
    );
  }, [oneDriveProviderId, sharePointProviderId]);

  const tagsApplied = useMemo(() => {
    return row?.tags?.map((item) => ({
      id: item.tag_id,
      text: item.tag_name
    }));
  }, [row?.tags]);

  return (
    <Box ref={chipsParent}>
      <Box display="flex" alignItems="center" maxWidth={'100%'}>
        <Box display={'flex'} alignItems="center" maxWidth={'100%'}>
          <Box height={'30px'}>
            {getIcon(typeCode, row?.original_format, providerType)}
          </Box>
          <Typography
            title={name}
            sx={{
              pl: 1,
              fontSize: '14px',
              display: 'block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap'
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>

      {!!tagsApplied?.length && !isMoveDialogOpen && (
        <Box my={1}>
          <StackedChips
            data={tagsApplied}
            icon={<SellIcon />}
            maxNumber={maxChips}
            dialogTitle={t(T.tags)}
            parentWidth={chipsParent}
          />
        </Box>
      )}
    </Box>
  );
};

export const NameProvider = (props: any) => {
  const { isMoveDialogOpen, ...rest } = props;
  return <DataTypeProvider formatterComponent={NameFormatter} {...rest} />;
};
