import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  BorderedBox,
  CountTypography,
  T2Typography,
  T3Typography
} from '../styles';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ReactComponent as ChatIcon } from 'src/assets/Analytics/chat.svg';
import { categorizeData } from './utils';

const ChatAnalytics = ({ data }) => {
  const { t } = useTranslation();
  const categorizedData = categorizeData(data);
  return (
    <Box padding={1} marginBottom={'10px'}>
      <Box
        display="flex"
        marginBottom={'10px'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Box sx={{ marginRight: 1 }}>
            <ChatIcon />
          </Box>
          <T2Typography>{t(T.Chat)}</T2Typography>
        </Box>
      </Box>
      <BorderedBox>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Grid item xs={4} margin={0} paddingRight={0.5}>
            <T3Typography>{t(T.conversations)}</T3Typography>
            <CountTypography>{categorizedData?.chatCount}</CountTypography>
          </Grid>
          <Grid item xs={4} margin={0} paddingRight={0.5}>
            <T3Typography>{t(T.questions)}</T3Typography>
            <CountTypography>{categorizedData?.queryCount}</CountTypography>
          </Grid>
          <Grid item xs={4} margin={0} paddingRight={0.5}>
            <T3Typography>{t(T.dislikes)}</T3Typography>
            <CountTypography>{categorizedData?.dislikes}</CountTypography>
          </Grid>
        </Box>
      </BorderedBox>
    </Box>
  );
};

export default ChatAnalytics;
