import { IconButton } from '@mui/material';
import { Close, Check } from '@mui/icons-material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { initialNewRow, isRowValid } from '../../content/Documents/utils/utils';
import { StringKeys } from 'src/types/base';
import { ACTION_TYPES } from 'src/types/enum';

type RowActionProps = {
  actionType: ACTION_TYPES;
  rowData: StringKeys;
  newRowDataRef: React.MutableRefObject<StringKeys>;
  onRowAdded: (newRowData: StringKeys) => void;
  setAddNewRow: React.Dispatch<React.SetStateAction<boolean>>;
  addNewRow: boolean;
  setValidationStatus: React.Dispatch<React.SetStateAction<any>>;
  onExecute: (params?: any) => void;
};

const RowAction = ({
  actionType,
  addNewRow,
  newRowDataRef,
  setAddNewRow,
  onExecute,
  rowData,
  setValidationStatus,
  onRowAdded
}: RowActionProps) => {
  const { t } = useTranslation();

  const actionIcons = {
    cancel: <Close fontSize="small" />,
    commit: <Check fontSize="small" color="success" />
  };

  const actionTitles = {
    [ACTION_TYPES.CANCEL]: t(T.cancel),
    [ACTION_TYPES.COMMIT]: t(T.save)
  };

  const handleAction = () => {
    if (actionType === 'cancel') {
      if (addNewRow && rowData.id === 0) {
        setAddNewRow(false);
        newRowDataRef.current = { ...initialNewRow };
      }
      setValidationStatus((prev) => {
        const newState = { ...prev };
        delete newState[rowData.id];
        return newState;
      });
      onExecute();
      return;
    }
    if (actionType === 'commit') {
      onExecute();
      if (rowData.id === 0 && addNewRow && isRowValid(newRowDataRef.current)) {
        onRowAdded(newRowDataRef.current);
      }
      return;
    }
  };
  if (
    actionType === ACTION_TYPES.CANCEL ||
    actionType === ACTION_TYPES.COMMIT
  ) {
    return (
      <IconButton onClick={handleAction} title={actionTitles[actionType]}>
        {actionIcons[actionType]}
      </IconButton>
    );
  }
  return null;
};

export default RowAction;
