import { TableEditRow } from '@devexpress/dx-react-grid-material-ui';
import TextField from 'src/components/TextField';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { COLUMNS } from '../utils/utils';
import { StatusFormatter } from '../ColumnFormatters/Status';
import { UploadedByFormatter } from '../ColumnFormatters/UploadedBy';
import { UploadedFormatter } from '../ColumnFormatters/UploadedDate';
import { ChangeEvent, useState } from 'react';
import { StringKeys } from 'src/types/base';

type EditorProps = {
  cellProps: TableEditRow.CellProps;
  addNewRow: boolean;
  validationStatus: any;
  newRow: any;
  handleEnterPress?: (
    row: StringKeys,
    updatedRow: Record<string, StringKeys>
  ) => void;
};
export const getEditorComponent = ({
  cellProps,
  addNewRow,
  validationStatus,
  handleEnterPress
}: EditorProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { row, column } = cellProps;

  if (column.name.includes(COLUMNS.FILENAME)) {
    const handleFileNameChange = (event: ChangeEvent<HTMLInputElement>) => {
      cellProps.onValueChange(event.target.value);
    };

    const onEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleEnterPress(row, {
          [`${row.id}`]: {
            ...row,
            name: cellProps.value?.name
          }
        });
      }
    };

    return (
      <TableEditRow.Cell
        {...cellProps}
        sx={{
          ...(addNewRow &&
            row.id === 0 && {
              backgroundColor: theme.colors.primaryAlt.lighter
            })
        }}
        autoFocus={!validationStatus[row?.id]?.name?.error?.length}
      >
        <TextField
          onKeyPress={onEnter}
          onChange={handleFileNameChange}
          sx={{
            width: 250
          }}
          value={cellProps?.value?.name || ''}
          placeholder={t(T.name)}
          error={!!validationStatus[row?.id]?.name?.error?.length || false}
        />
      </TableEditRow.Cell>
    );
  } else if (column.name.includes(COLUMNS.STATUS)) {
    return (
      <TableEditRow.Cell
        {...cellProps}
        editingEnabled={false}
        sx={{
          ...(addNewRow &&
            row.id === 0 && {
              backgroundColor: theme.colors.primaryAlt.lighter
            })
        }}
      >
        <StatusFormatter value={cellProps.value || 'N/A'} />
      </TableEditRow.Cell>
    );
  } else if (
    column.name.includes(COLUMNS.MODIFIED_BY) ||
    column.name.includes(COLUMNS.SYNCED_BY)
  ) {
    return (
      <TableEditRow.Cell
        {...cellProps}
        editingEnabled={false}
        sx={{
          ...(addNewRow &&
            row.id === 0 && {
              backgroundColor: theme.colors.primaryAlt.lighter
            })
        }}
      >
        <UploadedByFormatter value={cellProps.value || ''} />
      </TableEditRow.Cell>
    );
  } else if (
    column.name.includes(COLUMNS.MODIFIED_ON) ||
    column.name.includes(COLUMNS.SYNCED_ON)
  ) {
    return (
      <TableEditRow.Cell
        {...cellProps}
        editingEnabled={false}
        sx={{
          ...(addNewRow &&
            row.id === 0 && {
              backgroundColor: theme.colors.primaryAlt.lighter
            })
        }}
      >
        <UploadedFormatter value={cellProps.value || ''} />
      </TableEditRow.Cell>
    );
  }
};
