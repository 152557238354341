import { Box, Grid, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { IActionButtonProps } from 'src/types/header';
import HeaderButton from '../PageHeader/HeaderButton';
import AppliedFilters from '../AppliedFilters';
import { StringKeys } from 'src/types/base';
import { Close } from '@mui/icons-material';
import { FilterComponents } from 'src/types/custom_component';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';

type Props = {
  actionButtons: IActionButtonProps[];
  hideActions: boolean;
  selectedRows?: number;
  onClearSelection?: () => void;
  appliedFilters: AppliedFiltersProps;
};

type AppliedFiltersProps = {
  appliedFiltersValue?: StringKeys;
  setAppliedFiltersValue?: React.Dispatch<React.SetStateAction<StringKeys>>;
  onReset: (initialObject: any) => void;
  elementsList: FilterComponents[];
};

const GridActions = ({
  actionButtons,
  hideActions,
  appliedFilters,
  selectedRows = 0,
  onClearSelection = () => {}
}: Props) => {
  const { t } = useTranslation();
  const renderHeaderButton = useCallback(
    (actionButton: IActionButtonProps, index: number) => {
      if (!actionButton.visible) {
        return null;
      }
      return (
        <Grid item key={index}>
          <HeaderButton
            isButtonDisabled={actionButton.disabled}
            onClick={actionButton.onClick}
            tooltipMessage={actionButton.tooltipMsg}
            isPopover={actionButton.popover}
            buttonProps={{
              color: actionButton.color,
              startIcon: <actionButton.icon />,
              variant: actionButton.variant
            }}
            popoverElements={actionButton.popoverElements}
            btnText={actionButton.text}
          />
        </Grid>
      );
    },
    []
  );
  return !hideActions ? (
    <Grid
      sx={{
        backgroundColor: '#E7F0FF26'
      }}
      container
      alignItems={'center'}
      justifyContent={'space-between'}
      height={44}
    >
      <Grid item>
        <Grid container spacing={1.5}>
          {actionButtons.map(renderHeaderButton)}
        </Grid>
      </Grid>
      <Grid item>
        <Box
          key={1}
          display={'flex'}
          gap={1}
          border={1}
          borderColor={'#CAD9F2'}
          borderRadius={'6px'}
          alignItems={'center'}
          padding={'1px 3px'}
        >
          <Typography fontSize={'13px'}>
            {selectedRows} {t(T.selected)}
          </Typography>
          <Close
            onClick={() => {
              onClearSelection();
            }}
            sx={{ fontSize: '16px', cursor: 'pointer' }}
          />
        </Box>
      </Grid>
    </Grid>
  ) : (
    <AppliedFilters {...appliedFilters} />
  );
};

export default GridActions;
