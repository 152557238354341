import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface SearchInputProps {
  value: string;
  onChange: (newValue: string) => void;
}

const SearchInput = ({ value, onChange }: SearchInputProps) => {
  const { t } = useTranslation();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }

  return (
    <TextField
      value={value}
      onChange={handleChange}
      placeholder={t(T.search)}
      size="small"
      sx={{ width: '350px' }}
      InputProps={{
        startAdornment: (
          <SearchOutlinedIcon
            color="disabled"
            sx={(theme) => ({
              fontSize: theme.typography.pxToRem(18),
              marginRight: '8px'
            })}
          />
        )
      }}
    />
  );
};

export default SearchInput;
