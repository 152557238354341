import { Add, DeleteOutlineOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IActionButtonProps } from 'src/types/header';
import { RootState } from 'src/redux/store';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';

type Props = {
  selectedRows?: (string | number)[];
  isInvitingUsers?: boolean;
  handleInviteUsers?: () => void;
  handleMultipleDelete?: () => void;
  disableMultipleDelete?: boolean;
  showInviteButton?: boolean;
  showDeleteButton?: boolean;
};

export const ActionButtons = ({
  selectedRows = [],
  isInvitingUsers = false,
  handleInviteUsers = () => {},
  handleMultipleDelete = () => {},
  disableMultipleDelete = false,
  showInviteButton = false,
  showDeleteButton = false
}: Props): IActionButtonProps[] => {
  const { t } = useTranslation();
  const { userAppPermissions } = useSelector((state: RootState) => state.data);

  return [
    {
      color: 'primary',
      visible:
        !!userAppPermissions?.[USER_APP_PERMISSIONS.DELETE_USERS]?.value &&
        showDeleteButton,
      variant: 'text',
      icon: DeleteOutlineOutlined,
      tooltipMsg: t(T.selectItemsToDelete),
      disabled: disableMultipleDelete,
      popover: false,
      onClick: handleMultipleDelete,
      text: t(T.delete)
    },
    {
      color: 'primary',
      visible:
        !!userAppPermissions?.[USER_APP_PERMISSIONS.CREATE_USERS]?.value &&
        showInviteButton,
      variant: 'outlined',
      icon: Add,
      tooltipMsg: '',
      disabled: isInvitingUsers,
      popover: false,
      onClick: handleInviteUsers,
      text: t(T.invite),
      elementId: ONBOARDING_STEPS_TARGET.USERS_INVITE_BUTTON
    }
  ];
};
