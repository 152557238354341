import { FILE_EXTENSIONS, RESOURCE_PROVIDERS, SUPPORTED_UPLOAD_FILES_FORMAT } from "src/types/enum";
import { ReactComponent as PdfIcon } from 'src/assets/documentIcons/pdf-icon.svg';
import { ReactComponent as SPPdfIcon } from 'src/assets/documentIcons/pdf-sharepoint-icon.svg';
import { ReactComponent as ODPdfIcon } from 'src/assets/documentIcons/pdf-onedrive-icon.svg';
import { ReactComponent as PptIcon } from 'src/assets/documentIcons/ppt-icon.svg';
import { ReactComponent as SPPptIcon } from 'src/assets/documentIcons/ppt-sharepoint-icon.svg';
import { ReactComponent as ODPptIcon } from 'src/assets/documentIcons/ppt-onedrive-icon.svg';
import { ReactComponent as MsWordIcon } from 'src/assets/documentIcons/ms-word-icon.svg';
import { ReactComponent as SPMsWordIcon } from 'src/assets/documentIcons/ms-word-sharepoint-icon.svg';
import { ReactComponent as ODMsWordIcon } from 'src/assets/documentIcons/ms-word-onedrive-icon.svg';
import { ReactComponent as ExcelIcon } from 'src/assets/documentIcons/excel-icon.svg';
import { ReactComponent as SPExcelIcon } from 'src/assets/documentIcons/excel-sharepoint-icon.svg';
import { ReactComponent as ODExcelIcon } from 'src/assets/documentIcons/excel-onedrive-icon.svg';

export const FILE_NAME_LENGTH_LIMIT = 200;
export const NO_OF_CONCURRENT_API_CALLS = 3;
export const MAX_FILES_IN_EACH_API = 3;
export const TOTAL_FILES_MAX_SIZE = 600; //MB

export const isSupportedFormat = (fileType: string) => {
    switch (fileType) {
      case SUPPORTED_UPLOAD_FILES_FORMAT.PDF:
      case SUPPORTED_UPLOAD_FILES_FORMAT.PPT:
      case SUPPORTED_UPLOAD_FILES_FORMAT.PPTX:
      case SUPPORTED_UPLOAD_FILES_FORMAT.ODP:
      case SUPPORTED_UPLOAD_FILES_FORMAT.DOC:
      case SUPPORTED_UPLOAD_FILES_FORMAT.DOCX:
      case SUPPORTED_UPLOAD_FILES_FORMAT.ODT:
      case SUPPORTED_UPLOAD_FILES_FORMAT.XLS:
      case SUPPORTED_UPLOAD_FILES_FORMAT.XLSX:
      case SUPPORTED_UPLOAD_FILES_FORMAT.ODS:
        return true;
      default:
        return false;
    }
  };


const getProviderFileIcon = (externalType='', iconType='') => {
    let providerIcon = null;
    switch (externalType) {
      case RESOURCE_PROVIDERS.SHAREPOINT:
        switch (iconType) {
          case FILE_EXTENSIONS.PDF:
            providerIcon = SPPdfIcon;
            break;
          case FILE_EXTENSIONS.PPT:
            providerIcon = SPPptIcon;
            break;
          case FILE_EXTENSIONS.XLSX:
            providerIcon = SPExcelIcon;
            break;
          case FILE_EXTENSIONS.DOCX:
            providerIcon = SPMsWordIcon;
            break;
          default:
            break;
        }
        break;
      case RESOURCE_PROVIDERS.ONEDRIVE:
        switch (iconType) {
          case FILE_EXTENSIONS.PDF:
            providerIcon = ODPdfIcon;
            break;
          case FILE_EXTENSIONS.PPT:
            providerIcon = ODPptIcon;
            break;
          case FILE_EXTENSIONS.XLSX:
            providerIcon = ODExcelIcon;
            break;
          case FILE_EXTENSIONS.DOCX:
            providerIcon = ODMsWordIcon;
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
    return providerIcon;
  }

  export const getFileIcon = (fileType='', providerType='') => {
    switch (fileType) {
      case SUPPORTED_UPLOAD_FILES_FORMAT.PDF:
      case FILE_EXTENSIONS.PDF:      
        return providerType ? getProviderFileIcon(providerType, FILE_EXTENSIONS.PDF): PdfIcon;
      case SUPPORTED_UPLOAD_FILES_FORMAT.PPT:
      case SUPPORTED_UPLOAD_FILES_FORMAT.PPTX:
      case SUPPORTED_UPLOAD_FILES_FORMAT.ODP:
      case FILE_EXTENSIONS.PPT:
      case FILE_EXTENSIONS.PPTX:
      case FILE_EXTENSIONS.ODP:
        return providerType ? getProviderFileIcon(providerType, FILE_EXTENSIONS.PPT): PptIcon;
      case SUPPORTED_UPLOAD_FILES_FORMAT.DOC:
      case SUPPORTED_UPLOAD_FILES_FORMAT.DOCX:
      case SUPPORTED_UPLOAD_FILES_FORMAT.ODT:
      case FILE_EXTENSIONS.DOC:
      case FILE_EXTENSIONS.DOCX:
      case FILE_EXTENSIONS.ODT:
        return providerType ? getProviderFileIcon(providerType, FILE_EXTENSIONS.DOCX): MsWordIcon;
      case SUPPORTED_UPLOAD_FILES_FORMAT.XLS:
      case SUPPORTED_UPLOAD_FILES_FORMAT.XLSX:
      case SUPPORTED_UPLOAD_FILES_FORMAT.ODS:
      case FILE_EXTENSIONS.XLS:
      case FILE_EXTENSIONS.XLSX:
      case FILE_EXTENSIONS.ODS:
        return providerType ? getProviderFileIcon(providerType, FILE_EXTENSIONS.XLSX): ExcelIcon;
  
      default:
        return null;
    }
  };
  
