import { alpha, Box, useTheme } from '@mui/material';
import SearchWithFields from '../../utils/Filters/SearchWithFields';
import DateFilter from '../../utils/Filters/DateFilter';
import ClearButton from '../../utils/Filters/ClearButton';
import ActionButton from '../../utils/Filters/ActionButton';
import CustomAutoComplete from 'src/components/CustomAutoComplete';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ANALYSIS_COLUMNS } from '../../utils/utils';
import { LOOKUP } from 'src/types/enum';
import { useMemo } from 'react';
import { useSelector } from 'src/redux/store';

const Filters = ({
  search,
  setSearch,
  searchField,
  setSearchField,
  searchFields,
  dateCreated,
  setDateCreated,
  clearAllFilters,
  handleNewAnalysis,
  isAnalysisDisabled,
  status,
  setStatus
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { lookups } = useSelector((state) => state.data);

  const localizedStatusList = useMemo(
    () =>
      lookups[LOOKUP.DOCUMENT_ANALYSIS_STATUS].map((item) => ({
        id: item.id,
        name: t(T[item.code])
      })),
    [lookups]
  );

  const handleStatusChange = (event, newValue) => setStatus(newValue);

  const isFilterApplied =
    search !== '' ||
    searchField !== ANALYSIS_COLUMNS.ANALYSIS ||
    dateCreated !== null ||
    status !== null;

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" gap="16px">
        <SearchWithFields
          search={search}
          setSearch={setSearch}
          searchField={searchField}
          setSearchField={setSearchField}
          searchFields={searchFields}
        />

        <CustomAutoComplete
          id="statusFilter"
          dataSource={localizedStatusList}
          onChange={handleStatusChange}
          sx={{
            width: 200,
            backgroundColor: status
              ? alpha(theme.colors.secondary.main, 0.2)
              : ''
          }}
          value={status}
          placeholder={t(T.status)}
          multiple={false}
          disableSearch
        />

        <DateFilter
          placeholder={t(T.fromDate)}
          value={dateCreated}
          onChange={setDateCreated}
        />

        <ClearButton onClick={clearAllFilters} disabled={!isFilterApplied} />
      </Box>

      <ActionButton
        text={t(T.newAnalysis)}
        onClick={handleNewAnalysis}
        disabled={isAnalysisDisabled}
      />
    </Box>
  );
};

export default Filters;
