import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  BorderedBox,
  C2Typography,
  ColoredBox,
  T2Typography,
  T3Typography
} from '../styles';
import { ReactComponent as DocUsageIcon } from 'src/assets/Analytics/document_usage.svg';
import { ReactComponent as UserIcon } from 'src/assets/Analytics/user.svg';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import CustomPieChart from '../CustomPieChart';
import { categorizeLibraryData, categorizeUserData } from './utils';

const DataLibraryAnalytics = ({ libraryData, userData }) => {
  const { t } = useTranslation();
  const categorizedLibraryData = categorizeLibraryData(libraryData);
  const categorizedUserData = categorizeUserData(userData);

  return (
    <Box padding={1} marginBottom={'10px'}>
      <Box
        display="flex"
        marginBottom={'10px'}
        justifyContent={'space-between'}
      >
        <Box display={'flex'} width="50%">
          <Box sx={{ marginRight: 1 }}>
            <DocUsageIcon />
          </Box>
          <T2Typography>{t(T.workspaces)}</T2Typography>
        </Box>
        <Box display={'flex'} width={'50%'}>
          <Box sx={{ marginRight: 1 }}>
            <UserIcon />
          </Box>
          <T2Typography>{t(T.users)}</T2Typography>
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} marginBottom={'15px'}>
        <Grid item xs={6} margin={0} paddingRight={1}>
          <BorderedBox>
            <Box display={'flex'} justifyItems={'space-around'}>
              <T3Typography>{t(T.documents)}</T3Typography>
            </Box>
            <CustomPieChart data={categorizedLibraryData?.documents || []} />
          </BorderedBox>
        </Grid>
        <Grid item xs={6} margin={0} paddingRight={1}>
          <BorderedBox>
            <T3Typography>{t(T.users)}</T3Typography>
            <CustomPieChart data={categorizedUserData?.users} />
          </BorderedBox>
        </Grid>
      </Box>
      <Box>
        <Grid item xs={6} margin={0} paddingRight={1}>
          <ColoredBox>
            <T3Typography>{t(T.workspaces)}</T3Typography>
            <C2Typography>
              {categorizedLibraryData?.workspaces?.count || ''}
            </C2Typography>
          </ColoredBox>
        </Grid>
      </Box>
    </Box>
  );
};

export default DataLibraryAnalytics;
