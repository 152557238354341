import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import useMutation from 'src/hooks/useMutation';
import {
  addUpdateAnalysisHistoryContext,
  closeDialog
} from 'src/redux/slices/docAnalyst';
import { RootState } from 'src/redux/store';
import { useUpdateAnalysisMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface CloseConfirmationDialogProps {
  setIsCloseModalOpen: (isOpen: boolean) => void;
  handleDialogOpen: (isDialogOpen: boolean) => void;
  isCloseModalOpen: boolean;
}

const CloseConfirmationModal: React.FC<CloseConfirmationDialogProps> = ({
  setIsCloseModalOpen,
  handleDialogOpen,
  isCloseModalOpen
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    initiateAnalysis: { selectedAnalysis }
  } = useSelector((state: RootState) => state.docAnalyst);

  const [cancelAnalysis, { isLoading: isDeleting }] = useMutation({
    api: useUpdateAnalysisMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const handleBackGroundJob = useCallback(() => {
    setIsCloseModalOpen(false);
    handleDialogOpen(false);
    dispatch(closeDialog());
  }, []);

  const handleFullScreenDialogClose = useCallback(() => {
    cancelAnalysis({
      params: {
        params: {
          id: selectedAnalysis?.id,
          cancel_analysis: true
        }
      },
      successMsg: t(T.analysisCancelSuccess),
      fallbackMsg: t(T.analysisDeleteFailed),
      successCallback(data) {
        setIsCloseModalOpen(false);
        handleDialogOpen(false);
        dispatch(closeDialog());
        dispatch(
          addUpdateAnalysisHistoryContext({
            analysisId: selectedAnalysis?.id as number,
            isAnalysisStreaming: false
          })
        );
      }
    });
  }, [selectedAnalysis]);

  const handleConfirmationModal = useCallback(() => {
    setIsCloseModalOpen(false);
  }, []);

  return (
    <DialogLayout
      dialogTitle={t(T.inProgressAnalysis)}
      isOpen={isCloseModalOpen}
      onClose={handleFullScreenDialogClose}
      handleConfirmation={handleBackGroundJob}
      confirmationButtonText={t(T.runInBackground)}
      cancelButtonText={t(T.cancelAnalysis)}
      closeModal={handleConfirmationModal}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
        <Typography>{t(T.inProgressAnalysisDescription)}</Typography>
      </Box>
    </DialogLayout>
  );
};

export default CloseConfirmationModal;
