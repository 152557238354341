import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Button,
  InputAdornment,
  Link,
  OutlinedInput
} from '@mui/material';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as SyncIcon } from 'src/assets/SyncIcon.svg';
import HeaderButton from 'src/components/PageHeader/HeaderButton';
import ImportDataset from '../../DataCollections/ImportDataset';
import { DocumentsContextProvider } from 'src/contexts/FileManagerContext';
import {
  closeDialog,
  closeSelection,
  setSearchQuery,
  setTitle,
  updateInitiateAnalysisState,
  updateQuestion
} from 'src/redux/slices/docAnalyst';
import { RootState, useSelector } from 'src/redux/store';
import { StringKeys } from 'src/types/base';
import { DOCUMENT_ANALYSIS_STATUS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import CreateQuestionnaireModal from '../../DataCollections/CreateQuestionnaireModal';
import { InputLabelWrapper, SaveButtonText } from '../../styles';
import InputWithLabel from './InputWithLabel';
import UpdateQuestionnaireModal from './QuestionnaireUpdateDialog';
import { commonButtonStyles, DataSelectorContainer } from './styles';
import Models from './Models';
import useMutation from 'src/hooks/useMutation';
import { useUpdateAnalysisMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import ActionButton from '../../utils/Filters/ActionButton';

type Props = {
  handleInitiateAnalysis: () => void;
  handleRegenerateAnalysis: () => void;
  isDisableRunAnalysisButton: boolean;
};

const Header = ({
  handleInitiateAnalysis,
  handleRegenerateAnalysis,
  isDisableRunAnalysisButton
}: Props) => {
  const {
    initiateAnalysis: {
      showSearchHeader,
      showDataSelectionHeader,
      title,
      selectedDataset,
      selectedQuestionnaire,
      isSelectingDataset,
      isSelectingQuestionnaire,
      isAnalysisView,
      selectedAnalysis,
      isUpdateQuestionnaireDialogOpen
    },
    analysisHistoryContexts,
    currentlyRunningAnalysis
  } = useSelector((state: RootState) => state.docAnalyst);

  const { maxAnalysisLimit } = useSelector((state: RootState) => state.auth);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isImportFromAppOpen, setIsImportFromAppOpen] = useState(false);
  const [sources, setSources] = useState<StringKeys>({});
  const [isUpdateQuestionnaireModal, setIsUpdateQuestionnaireModal] =
    useState<boolean>(false);
  const [questionnaireTitle, setQuestionnaireTitle] = useState<string>('');
  const [isCreateQuestionnaireOpen, setIsCreateQuestionnaireOpen] =
    useState<boolean>(false);
  const handleTitleChange = (event: React.ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    dispatch(setTitle(value));
  };

  const [updateAnalysis] = useMutation({
    api: useUpdateAnalysisMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const handleDatasetSelect = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();
    dispatch(
      updateInitiateAnalysisState({
        dialogTitle: t(T.selectDataset),
        isSelectingDataset: true,
        showBackButton: true,
        showSearchHeader: true,
        showDataSelectionHeader: false
      })
    );
  };

  const handleQuestionnaireSelect = (
    event: React.MouseEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    dispatch(
      updateInitiateAnalysisState({
        dialogTitle: t(T.selectQuestionnaire),
        isSelectingQuestionnaire: true,
        showBackButton: true,
        showSearchHeader: true,
        showDataSelectionHeader: false
      })
    );
  };

  const handleImportFromApp = () => {
    setIsImportFromAppOpen(true);
  };

  const handleImportFromAppClose = useCallback(() => {
    setIsImportFromAppOpen(false);
    setSources({});
  }, []);

  const handleCreateQuestionnaire = useCallback(() => {
    setIsCreateQuestionnaireOpen(true);
  }, []);

  const handleCreateQuestionnaireClose = useCallback(() => {
    setIsCreateQuestionnaireOpen(false);
  }, []);

  const openUpdateQuestionnaireModal = useCallback(() => {
    setQuestionnaireTitle('');
    if (
      selectedAnalysis?.isQuestionEdited &&
      !isUpdateQuestionnaireDialogOpen
    ) {
      if (!selectedAnalysis?.isQuestionnaireAssociated) {
        handleUpdateQuestionnaire();
      } else setIsUpdateQuestionnaireModal((prev) => !prev);
    } else if (isUpdateQuestionnaireDialogOpen) {
      dispatch(
        updateInitiateAnalysisState({
          isUpdateQuestionnaireDialogOpen: false
        })
      );
    }
  }, [selectedAnalysis?.isQuestionEdited, isUpdateQuestionnaireDialogOpen]);

  const closeUpdateQuestionnaireModal = useCallback(() => {
    setIsUpdateQuestionnaireModal(false);

    const initiateAnalysisState = { isUpdateQuestionnaireDialogOpen: false };
    if (isUpdateQuestionnaireDialogOpen) {
      initiateAnalysisState['isDialogOpen'] = false;
      dispatch(closeSelection());
      dispatch(closeDialog());
    }
    dispatch(updateInitiateAnalysisState(initiateAnalysisState));
  }, [isUpdateQuestionnaireDialogOpen]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setQuestionnaireTitle(event.target.value);
  };

  const showRegenerateButton = useMemo(() => {
    return (
      selectedAnalysis?.status &&
      selectedAnalysis?.status !== DOCUMENT_ANALYSIS_STATUS.INPROGRESS
    );
  }, [selectedAnalysis?.status]);

  const handleSearchChange = useCallback(
    debounce((event: React.ChangeEvent) => {
      const { value } = event.target as HTMLInputElement;
      dispatch(setSearchQuery(value));
    }, 300),
    []
  );

  const isRegeneratingDisabled = useMemo(() => {
    return (
      analysisHistoryContexts[selectedAnalysis?.id]?.isAnalysisStreaming ||
      currentlyRunningAnalysis === maxAnalysisLimit ||
      selectedDataset?.isDatasetDeleted ||
      !selectedQuestionnaire?.isEditable
    );
  }, [
    analysisHistoryContexts,
    selectedDataset,
    selectedQuestionnaire,
    maxAnalysisLimit,
    currentlyRunningAnalysis
  ]);

  const updateQuestionnaireBtnText = useMemo(() => {
    return selectedAnalysis?.isQuestionnaireAssociated ? T.save : T.update;
  }, [selectedAnalysis?.isQuestionnaireAssociated]);

  const handleUpdateQuestionnaire = useCallback(() => {
    updateAnalysis({
      params: {
        params: {
          id: selectedAnalysis?.id,
          ...(selectedAnalysis?.isQuestionnaireAssociated
            ? { save_as: questionnaireTitle }
            : { force_update: true })
        }
      },
      successMsg: selectedAnalysis?.isQuestionnaireAssociated
        ? t(T.questionnaireCreationSuccess)
        : t(T.questionUpdatedSuccess),
      successCallback(data) {
        const initiateAnalysisState = {
          isUpdateQuestionnaireDialogOpen: false
        };
        if (isUpdateQuestionnaireDialogOpen) {
          initiateAnalysisState['isDialogOpen'] = false;
        }
        dispatch(updateInitiateAnalysisState(initiateAnalysisState));
        dispatch(updateQuestion({ questionEdited: false }));
        setIsUpdateQuestionnaireModal(false);
      }
    });
  }, [
    selectedAnalysis,
    questionnaireTitle,
    selectedQuestionnaire,
    isUpdateQuestionnaireDialogOpen
  ]);

  return (
    <>
      <Box paddingTop={'16px'}>
        {showDataSelectionHeader && (
          <DataSelectorContainer>
            <Box display={'flex'} gap={'16px'}>
              <InputWithLabel
                title={t(T.analysisTitle)}
                placeholder={`${t(T.enterAnalysisTitle)}...`}
                onChange={handleTitleChange}
                id="name"
                value={title}
                disabled={isAnalysisView}
                hideEndAdornment
                inputRef={(node: HTMLInputElement) => !title && node?.focus()}
              />
              <Models isDisabled={isAnalysisView} />

              <InputWithLabel
                title={t(T.dataset)}
                placeholder={`${t(T.select)}...`}
                onClick={handleDatasetSelect}
                value={selectedDataset?.name || ''}
                disabled={isAnalysisView}
                sx={
                  selectedDataset?.isDatasetDeleted && {
                    border: '1px solid red'
                  }
                }
                tooltipText={
                  selectedDataset?.isDatasetDeleted
                    ? t(T.datasetDeletedTooltip)
                    : ''
                }
              />

              <InputWithLabel
                title={t(T.questionnaire)}
                placeholder={`${t(T.select)}...`}
                id="questionnaire"
                onClick={handleQuestionnaireSelect}
                value={selectedQuestionnaire?.name || ''}
                disabled={isAnalysisView}
              />
              {selectedAnalysis?.isQuestionEdited && (
                <Button
                  color="secondary"
                  variant="contained"
                  size="small"
                  sx={{ height: '41px', alignSelf: 'end' }}
                  onClick={openUpdateQuestionnaireModal}
                >
                  {t(updateQuestionnaireBtnText)}
                </Button>
              )}
            </Box>
            <Box alignSelf={'flex-end'} display={'flex'}>
              {showRegenerateButton ? (
                <HeaderButton
                  isButtonDisabled={isRegeneratingDisabled}
                  onClick={handleRegenerateAnalysis}
                  tooltipMessage={''}
                  buttonProps={{
                    color: 'primary',
                    variant: 'outlined',
                    startIcon: <SyncIcon />,
                    sx: { ...commonButtonStyles }
                  }}
                  btnText={t(T.regenerateResponse)}
                />
              ) : (
                <HeaderButton
                  isButtonDisabled={isDisableRunAnalysisButton}
                  onClick={handleInitiateAnalysis}
                  tooltipMessage={''}
                  buttonProps={{
                    color: 'secondary',
                    variant: 'contained',
                    sx: { ...commonButtonStyles }
                  }}
                  btnText={t(T.runAnalysis)}
                />
              )}
            </Box>
          </DataSelectorContainer>
        )}
        {showSearchHeader && (
          <Box display={'flex'} gap={'12px'}>
            <OutlinedInput
              placeholder={t(T.search)}
              sx={{ height: '32px', width: '100%' }}
              onChange={handleSearchChange}
              startAdornment={
                <InputAdornment position="start">
                  <SearchOutlinedIcon
                    sx={{ fontSize: '16px' }}
                    color="disabled"
                  />
                </InputAdornment>
              }
              inputRef={(node: HTMLInputElement) => node?.focus()}
            />
            {(isSelectingDataset || isSelectingQuestionnaire) && (
              <Box alignSelf={'center'} display={'flex'} flexShrink={0}>
                <ActionButton
                  text={t(
                    isSelectingDataset ? T.importFromApp : T.addQuestionnaire
                  )}
                  onClick={
                    isSelectingDataset
                      ? handleImportFromApp
                      : handleCreateQuestionnaire
                  }
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <DocumentsContextProvider>
        {isImportFromAppOpen && (
          <ImportDataset
            open={isImportFromAppOpen}
            handleClose={handleImportFromAppClose}
            sources={sources}
          />
        )}
      </DocumentsContextProvider>

      {isCreateQuestionnaireOpen && (
        <CreateQuestionnaireModal
          isOpen={isCreateQuestionnaireOpen}
          onClose={handleCreateQuestionnaireClose}
        />
      )}
      {(isUpdateQuestionnaireModal || isUpdateQuestionnaireDialogOpen) && (
        <UpdateQuestionnaireModal
          isOpen={isUpdateQuestionnaireModal || isUpdateQuestionnaireDialogOpen}
          onClose={closeUpdateQuestionnaireModal}
          title={questionnaireTitle}
          onInputChange={handleInputChange}
          setIsUpdateQuestionnaireModal={setIsUpdateQuestionnaireModal}
          onUpdateQuestionnaire={handleUpdateQuestionnaire}
        />
      )}
    </>
  );
};

export default Header;
