import {
  FilterComponents,
  FilterElementTypes
} from 'src/types/custom_component';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const getFilters = (datasources: any) => {
  const filters: FilterComponents[] = [
    {
      name: 'Search',
      type: FilterElementTypes.Text,
      elementWidth: 250,
      dataField: 's',
      placeholder: T.search,
      showIcon: true,
      showDivider: true
    },
    {
      name: 'Account Type',
      type: FilterElementTypes.AutoComplete,
      dataField: 'account_type_id',
      placeholder: T.accountType,
      datasource: datasources.account_type,
      elementWidth: 250,
      disableSearch: true,
      showLabel: true,
      label: T.accountType
    },
    {
      name: 'Status',
      type: FilterElementTypes.AutoComplete,
      dataField: 'status',
      placeholder: T.status,
      datasource: datasources.user_status,
      elementWidth: 250,
      disableSearch: true,
      showLabel: true,
      label: T.status
    }
  ];

  return filters;
};
