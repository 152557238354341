import { getEncryptParams } from 'src/keycloak';
import { DataApiInputParams } from 'src/types/api';
import {
  Builder,
  appendFormData,
  decryptResponse,
  getHeaders,
  getQueryString,
  serializeErrorResponse
} from './utils';
import { IS_ENCRYPTION_ENABLED } from 'src/config';

export default (builder: Builder) => {
  return {
    getAnalysisHistory: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/da-analysis?${getQueryString(arg.params)}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['DocAnalyst']
    }),
    getAnalysis: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/da-analysis-detail?${getQueryString(
          arg.params
        )}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    createAnalysis: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;

        let formData = appendFormData(arg.params);
        let url = `mini-experts/da-analysis`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    }),
    deleteAnalysis: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `mini-experts/da-analysis`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    }),
    getDatasets: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/da-dataset?${getQueryString(arg.params)}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['DocAnalyst']
    }),
    deleteDataset: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `mini-experts/da-dataset`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    }),
    getQuestionnaires: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/da-questionnaire?${getQueryString(
          arg.params
        )}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['DocAnalyst']
    }),
    getQuestionnaire: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/da-questionnaire-detail?${getQueryString(
          arg.params
        )}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      }
    }),
    createQuestionnaire: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;

        let formData = appendFormData(arg.params);
        let url = `mini-experts/da-questionnaire`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'POST',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    }),
    editQuestionnaire: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;

        let formData = appendFormData(arg.params);

        let url = `mini-experts/da-questionnaire-detail`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PATCH',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    }),
    deleteQuestionnaire: builder.mutation({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `mini-experts/da-questionnaire`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'DELETE',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    }),
    createDataset: builder.mutation({
      async queryFn({ params }, { getState }, extraOptions, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(params);
        let result: any = await fetchWithBQ({
          url: `mini-experts/da-dataset`,
          method: 'POST',
          body: formData,
          ...getHeaders(`mini-experts/da-dataset`, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    }),
    getAnalysisDetail: builder.query({
      async queryFn(
        arg: DataApiInputParams,
        { getState },
        extraOptions,
        fetchWithBQ
      ) {
        const decryptKey = getEncryptParams()?.k;
        const url = `mini-experts/da-analysis-detail?${getQueryString(
          arg.params
        )}`;
        const result: any = await fetchWithBQ({
          url: url,
          ...getHeaders(url),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      providesTags: ['DocAnalyst']
    }),
    updateAnalysisDetail: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let url = `mini-experts/da-analysis-detail`;
        let result: any = await fetchWithBQ({
          url: url,
          method: 'PATCH',
          body: formData,
          ...getHeaders(url, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);

        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      // TODO: remove invalidation and use the api response
      invalidatesTags: ['DocAnalyst']
    }),
    updateAnalysis: builder.mutation({
      async queryFn(arg: DataApiInputParams, _, __, fetchWithBQ) {
        let decryptKey = getEncryptParams()?.k;
        let formData = appendFormData(arg.params);
        let result: any = await fetchWithBQ({
          url: `mini-experts/da-analysis`,
          method: 'PATCH',
          body: formData,
          ...getHeaders(`mini-experts/da-analysis`, formData),
          responseHandler: IS_ENCRYPTION_ENABLED ? 'text' : 'json'
        });
        const response = decryptResponse(result, decryptKey);
        return response.code > 0
          ? { data: response.ret_val }
          : serializeErrorResponse(response);
      },
      invalidatesTags: ['DocAnalyst']
    })
  };
};
