import { styled, Box, Typography, Divider } from '@mui/material';

export const BorderedBox = styled(Box)({
  border: '1px solid #AABEDE',
  borderRadius: 6
});

export const ColoredBox = styled(Box)({
  border: '1px solid #AABEDE',
  borderRadius: 6,
  backgroundColor: '#CAD9F233'
});

export const TitleTypography = styled(Typography)({
  margin: 10,
  marginBottom: 20,
  fontWeight: 700,
  fontSize: '25px'
});

export const T2Typography = styled(Typography)({
  fontWeight: 500,
  fontSize: '22px'
});

export const T3Typography = styled(Typography)({
  margin: 10,
  marginBottom: 0,
  fontWeight: 500,
  fontSize: '20px'
});

export const T4Typography = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px'
});

export const CountTypography = styled(Typography)({
  margin: 10,
  marginTop: 0,
  fontWeight: 600,
  fontSize: '34px'
});

export const C2Typography = styled(Typography)({
  margin: 10,
  marginTop: 0,
  fontWeight: 600,
  fontSize: '50px'
});

export const C3Typography = styled(Typography)({
  marginRight: 20,
  fontWeight: 600,
  fontSize: '14px',
  textAlign: 'end',
  width: '20%'
});

export const LegendListDivider = styled(Divider)({
  margin: '5px 20px 5px 0'
});

export const getPieLabelFontSize = (count: number) => {
  return `${count}`.length <= 4 ? 34 : (4 * 34) / `${count}`.length;
};
