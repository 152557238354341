import { Helmet } from 'react-helmet-async';
import RolesGridView from './List';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';

const UserRoles = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {T.empowerGPT} - {t(T.rolesAndPermissions)}
        </title>
      </Helmet>
      <RolesGridView />
    </>
  );
};

export default UserRoles;
