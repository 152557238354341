import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import Checkbox from '@mui/material/Checkbox';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import InputWithLabel from './InputWithLabel';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectQuestionnaire,
  updateInitiateAnalysisState,
  updateQuestion
} from 'src/redux/slices/docAnalyst';
import { RootState } from 'src/redux/store';
import useMutation from 'src/hooks/useMutation';
import { useUpdateAnalysisMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';

interface UpdateQuestionnaireModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  onInputChange: any;
  setIsUpdateQuestionnaireModal: (isModalOpen: boolean) => void;
  onUpdateQuestionnaire: () => void;
}

const UpdateQuestionnaireModal: React.FC<UpdateQuestionnaireModalProps> = ({
  isOpen,
  onClose,
  title,
  onInputChange,
  setIsUpdateQuestionnaireModal,
  onUpdateQuestionnaire
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [
    updateAnalysis,
    { isLoading: isUpdatingAnalysis, data: updatedAnalysisData }
  ] = useMutation({
    api: useUpdateAnalysisMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });
  const {
    initiateAnalysis: { selectedQuestionnaire, selectedAnalysis }
  } = useSelector((state: RootState) => state.docAnalyst);
  const handleUpdateQuestionnaire = useCallback(() => {
    if (selectedAnalysis?.isQuestionnaireAssociated)
      dispatch(
        selectQuestionnaire({
          ...selectedQuestionnaire,
          name: title
        })
      );
    onUpdateQuestionnaire();
  }, [selectedAnalysis, title, selectedQuestionnaire]);

  const disableConfirmationButton = useMemo(() => {
    return selectedAnalysis?.isQuestionnaireAssociated ? !title.length : false;
  }, [title, selectedAnalysis?.isQuestionnaireAssociated]);

  return (
    <DialogLayout
      dialogTitle={t(T.updateQuestionnaireDialogTitle)}
      isOpen={isOpen}
      onClose={onClose}
      handleConfirmation={handleUpdateQuestionnaire}
      confirmationButtonText={
        selectedAnalysis?.isQuestionnaireAssociated ? t(T.saveNew) : t(T.update)
      }
      disableButton={disableConfirmationButton}
    >
      <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
        {selectedAnalysis?.isQuestionnaireAssociated ? (
          <Box>
            <Typography variant="body1">
              {t(T.updateAssociatedQuestionnaireDescription)}
            </Typography>
            <Typography variant="body1" marginBottom={2}>
              {t(T.newQuestionnaireDescription)}
            </Typography>
            <InputWithLabel
              fullWidth
              title={t(T.questionnaireName)}
              placeholder={`${t(T.enterQuestionnaireName)}...`}
              id="name"
              onChange={onInputChange}
              value={title}
              hideEndAdornment={true}
            />
          </Box>
        ) : (
          <Typography variant="body1">
            {t(T.updateQuestionnaireDescription)}
          </Typography>
        )}
      </Box>
    </DialogLayout>
  );
};

export default UpdateQuestionnaireModal;
