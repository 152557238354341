import React from 'react';
import {
  Box,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { User } from 'src/types/user';

const UserList = ({
  userList,
  handleToggleUser,
  existingUsers
}: {
  userList: User[];
  handleToggleUser: (user: User) => void;
  existingUsers: User[];
}) => {
  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.colors.primaryAlt.main}`,
        borderTop: 'none',
        maxHeight: '30vh',
        overflowY: 'auto'
      })}
    >
      <List>
        {userList.map((user) => (
          <React.Fragment key={user.id}>
            <ListItem
              key={user.id}
              sx={(theme) => ({ padding: theme.spacing(1) })}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={!!existingUsers.find(({ id }) => id === user.id)}
                  onClick={() => handleToggleUser(user)}
                />
              </ListItemIcon>
              <ListItemText
                primary={user?.attributes?.full_name?.[0]}
                secondary={user.username}
              />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};
export default UserList;
