import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Label
} from 'recharts';
import { useTranslation } from 'react-i18next';
import {
  C3Typography,
  LegendListDivider,
  T4Typography,
  getPieLabelFontSize
} from '../styles';

const CustomPieChart = ({ data }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const totalCount = data?.reduce((acc, curr) => acc + curr.count, 0);

  const CustomLegend = ({ payload }) => {
    return (
      <Box width={'100%'}>
        {payload?.map((entry, index) => (
          <>
            {index ? <LegendListDivider /> : null}
            <Box
              display={'flex'}
              alignItems={'space-around'}
              key={index}
              margin={0}
            >
              <Box width={'80%'} display={'flex'} alignItems={'center'}>
                <Box
                  width={8}
                  height={8}
                  borderRadius="50%"
                  display={'inline-block'}
                  marginRight={0.5}
                  sx={{ backgroundColor: entry.color }}
                />
                <T4Typography>{t(entry.value)}</T4Typography>
              </Box>
              <C3Typography>{t(entry.payload?.value)}</C3Typography>
            </Box>
          </>
        ))}
      </Box>
    );
  };

  const renderTooltipContent = (props) => {
    const { payload } = props;
    if (!payload || payload.length === 0) return null;

    const tooltipContent = payload.map((entry, index) => (
      <Box
        key={index}
        p={1}
        border={`1px solid ${theme.colors.alpha.black[50]}`}
        sx={{ backgroundColor: theme.colors.alpha.white[100] }}
        zIndex={1000}
      >
        <Typography fontSize={14}>
          {t(entry.name)}: {entry.value}
        </Typography>
      </Box>
    ));

    return tooltipContent;
  };

  return (
    <ResponsiveContainer height={180}>
      <PieChart>
        <Pie
          data={data}
          cx={100}
          cy={80}
          outerRadius={80}
          innerRadius={50}
          dataKey="count"
          legendType="circle"
          startAngle={90}
          endAngle={-270}
        >
          <Label
            value={totalCount}
            fontWeight={600}
            fontSize={getPieLabelFontSize(totalCount)}
            fill={theme.colors.primary.main}
            position="center"
          />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>

        <Tooltip content={renderTooltipContent} />
        <Legend
          content={<CustomLegend payload={data} />}
          layout="vertical"
          verticalAlign="middle"
          align="right"
          wrapperStyle={{ width: '40%' }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomPieChart;
