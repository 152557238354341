import { ChevronRight } from '@mui/icons-material';
import {
  alpha,
  Box,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';

interface InputWithLabelProps extends OutlinedInputProps {
  title: string;
  placeholder: string;
  hideEndAdornment?: boolean;
  tooltipText?: string;
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  title,
  placeholder,
  onClick,
  disabled,
  hideEndAdornment = false,
  onChange,
  tooltipText = '',
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Tooltip title={tooltipText}>
      <Box>
        <Typography height={'26px'}>{title}</Typography>
        <OutlinedInput
          {...rest} // Spread rest props into OutlinedInput
          placeholder={placeholder}
          onClick={!disabled ? onClick : undefined}
          onChange={!disabled ? onChange : undefined}
          disabled={disabled}
          sx={{
            height: '42px',
            '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
              borderColor: alpha(theme.colors.alpha.black[100], 0.23)
            },
            ...rest.sx
          }}
          endAdornment={
            hideEndAdornment ? null : (
              <InputAdornment position="end" sx={{ color: 'inherit' }}>
                <ChevronRight />
              </InputAdornment>
            )
          }
        />
      </Box>
    </Tooltip>
  );
};

export default InputWithLabel;
