import { useMemo } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { DOCUMENT_STATUS, RESOURCE_TYPE } from 'src/types/enum';
import Label from 'src/components/Label';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';

export const StatusFormatter = ({ value }: any) => {
  const { t }: { t: any } = useTranslation();
  const { status, statusCode, typeCode } = value;

  const translatedStatus = useMemo(
    () => t(T[statusCode || '']),
    [status, statusCode]
  );
  const getDocumentStatus = useMemo(() => {
    if (typeCode !== RESOURCE_TYPE.FILE) {
      return <span style={{ marginLeft: '12px', fontSize: '14px' }}>-</span>;
    }
    switch (statusCode) {
      case DOCUMENT_STATUS.PROCESSED:
        return <Label color="success">{translatedStatus}</Label>;
      case DOCUMENT_STATUS.UNSUPPORTED:
        return <Label color="error">{translatedStatus}</Label>;
      case DOCUMENT_STATUS.INPROGRESS:
      case DOCUMENT_STATUS.WAITING_FOR_FILE:
        return <Label color="warning">{translatedStatus}</Label>;
      default:
        return <Label color="info">{translatedStatus}</Label>;
    }
  }, [statusCode, status, typeCode]);

  return (
    <Box
      className={ONBOARDING_STEPS_TARGET.DOCUMENT_STATUS_TAG}
      display="flex"
      alignItems="center"
    >
      {getDocumentStatus}
    </Box>
  );
};

export const StatusProvider = (props: any) => (
  <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
);
