import { Helmet } from 'react-helmet-async';
import { Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import MainMenu from './MainMenu';

const MiniExperts: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${T.empowerGPT} - ${t(T.miniExperts)}`}</title>
      </Helmet>
      <Paper elevation={0} style={{ height: '100%', padding: '0 32px' }}>
        <MainMenu />
      </Paper>
    </>
  );
};

export default MiniExperts;
