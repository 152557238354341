import React, { useContext, useMemo } from 'react';
import { Grid } from '@mui/material';
import CollectionCard from './CollectionCard';
import { StringKeys } from 'src/types/base';
import DocumentsContext from 'src/contexts/FileManagerContext';
import CreateCollectionCard from './CreateCollectionCard';

interface CollectionCardContentProps {
  rows: StringKeys[];
  handleShareResourceClick?: (data: StringKeys) => void;
  handleStartChatClick?: (data: StringKeys) => void;
  setActiveDirectory?: (data: StringKeys) => void;
  setDeletedRowId?: React.Dispatch<React.SetStateAction<string>>;
  setDeleteOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isMoveDialogOpen?: boolean;
  onRowUpdated?: (changedRow: StringKeys) => void;
  onRowAdded: (changedRow: StringKeys) => void;
  addNewRow: boolean;
  setAddNewRow: React.Dispatch<React.SetStateAction<boolean>>;
  handleSyncResourceClick?: (providerId: string, fileId: string) => void;
  filteredKeys?: StringKeys;
  showCreateCollectionCard?: boolean;
  workSpaceCode?: string;
}
const CollectionCardContent = ({
  rows,
  handleShareResourceClick,
  handleStartChatClick,
  setActiveDirectory,
  onRowUpdated,
  setDeleteOpen,
  setDeletedRowId,
  onRowAdded,
  isMoveDialogOpen,
  addNewRow,
  setAddNewRow,
  handleSyncResourceClick,
  filteredKeys,
  showCreateCollectionCard = false,
  workSpaceCode
}: CollectionCardContentProps) => {
  const { resourcePermissions } = useContext(DocumentsContext);

  const handleCreateNewCollection = (e: any) => {
    setAddNewRow((prev) => !prev);
  };

  return (
    <Grid container pb={'12px'} rowSpacing={2} columnSpacing={2}>
      {showCreateCollectionCard && (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          xl={3}
          onClick={handleCreateNewCollection}
        >
          <CreateCollectionCard />
        </Grid>
      )}
      {!!rows.length &&
        rows.map((row: any, index: number) => {
          const rowWithWorkSpaceCode = {
            ...row,
            parent_hierarchy: workSpaceCode || ''
          };
          return (
            <Grid item key={index} xs={12} sm={12} md={6} lg={4} xl={3}>
              <CollectionCard
                row={rowWithWorkSpaceCode}
                filteredKeys={filteredKeys}
                handleSyncResourceClick={handleSyncResourceClick}
                onRowUpdated={onRowUpdated}
                handleShareResourceClick={handleShareResourceClick}
                handleStartChatClick={handleStartChatClick}
                setDeletedRowId={setDeletedRowId}
                setDeleteOpen={setDeleteOpen}
                isMoveDialogOpen={isMoveDialogOpen}
                setActiveDirectory={setActiveDirectory}
                addNewRow={addNewRow}
                setAddNewRow={setAddNewRow}
                onRowAdded={onRowAdded}
              />
            </Grid>
          );
        })}
    </Grid>
  );
};

export default CollectionCardContent;
