import { Typography } from '@mui/material';
import { Breadcrumbs as MuiBreadcrumbs, Link } from '@mui/material';
import { IBreadcrumbItem } from 'src/types/custom_component';

type BreadcrumbsProps = {
  hierarchy: IBreadcrumbItem[];
  onBreadcrumbItemClick: (item: IBreadcrumbItem, index: number) => void;
  maxItems?: number;
  fontSize?: string;
};

const Breadcrumbs = ({
  hierarchy,
  onBreadcrumbItemClick,
  maxItems = 4,
  fontSize = '15px'
}: BreadcrumbsProps) => {
  return (
    <MuiBreadcrumbs maxItems={maxItems} aria-label="breadcrumb">
      {hierarchy.map((item, index) => {
        if (index === hierarchy.length - 1 || item?.disabled) {
          return (
            <Typography
              key={item.id}
              sx={{
                fontSize: fontSize,
                fontWeight: 500,
                height: '30px',
                lineHeight: '30px'
              }}
              color="rgba(73, 89, 144, 0.60)"
            >
              {item.display_name || item.name}
            </Typography>
          );
        }
        return (
          <Link
            key={item.id}
            underline="hover"
            onClick={() => onBreadcrumbItemClick(item, index)}
            style={{
              cursor: 'pointer',
              fontWeight: 500,
              color: '#3A4A82',
              fontSize: fontSize,
              height: '30px',
              lineHeight: '30px',
              display: 'flex'
            }}
          >
            {item.display_name || item.name}
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
