import React from 'react';
import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { ReactComponent as AddIcon } from 'src/assets/documentIcons/add-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';

const CreateCollectionCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      sx={{
        border: `1px dashed ${theme.colors.alpha.black[50]}`,
        borderRadius: '6px !important',
        padding: '8px 12px',
        minHeight: '90px',
        cursor: 'pointer'
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.colors.alpha.black[20],
          textAlign: 'center'
        }}
      >
        <Avatar
          variant="square"
          sx={{
            width: '64px',
            height: '64px',
            borderRadius: '6px !important',
            backgroundColor: theme.colors.alpha.black[10],
            color: theme.colors.alpha.black[50]
          }}
        >
          <AddIcon />
        </Avatar>
      </Box>
      <Box
        sx={{
          marginLeft: 1
        }}
      >
        <Typography
          variant={'body1'}
          sx={{
            fontSize: '16px',
            fontWeight: 500
          }}
        >
          {t(T.createACollection)}
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            fontSize: '12px'
          }}
        >
          {t(T.collectionPlaceholder)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CreateCollectionCard;
