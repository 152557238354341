import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import styled from '@emotion/styled';
import { TabPanel } from '@mui/lab';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { EditActionCell, TableCellHeader } from 'src/components/Grid/styles';

type StyledTabProps = {
  bordered?: boolean;
};

export const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'bordered'
})(({ bordered = false }: StyledTabProps) => ({
  fontWeight: '400!important',
  fontSize: '16px',
  margin: '0 2px',
  borderRadius: 0, // Disable borderRadius here,
  ['&.Mui-selected']: {
    borderBottom: '3.5px solid #7BF3B9',
    borderRadius: '0px!important',
    '&:hover': {
      borderRadius: 0
    },
    ['& .MuiTab-iconWrapper']: {
      borderRadius: '12px',
      backgroundColor: '#7BF3B9'
    }
  },
  ['&.MuiTab-root']: {
    height: '44px'
  }
}));
export const StyledTabPanel = styled(TabPanel)(() => ({
  display: 'flex',
  height: 'calc(100% - 60px)',
  padding: 0
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '45px',
  maxWidth: 'max-content',
  '&.MuiTabs-root': { backgroundColor: '#fff' },
  '& .MuiTabs-scroller': {
    height: '44px',
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },
  '& .MuiTabs-flexContainer': {
    borderBottom: '1px solid #DDE5F2',
    height: '44px'
  }
}));

export const InputLabelWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '-15%',
  right: 0,
  margin: '8px'
}));

export const SaveButtonText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bordered'
})(({ bordered = false }: StyledTabProps) => ({
  fontSize: '13px',
  fontWeight: 400,
  borderBottom: bordered ? '1px solid' : '1px solid gray',
  cursor: 'pointer',
  color: bordered ? 'primary' : 'GrayText'
}));

export const CustomEditActionCell = (props: TableEditColumn.CellProps) => {
  return (
    <EditActionCell
      style={{
        height: '36px',
        padding: '8px 8px',
        wordWrap: 'break-word',
        whiteSpace: 'unset'
      }}
      {...props}
    />
  );
};

export const CompactTableCellHeader = (props) => {
  return (
    <TableCellHeader
      {...props}
      style={{
        padding: '8px'
      }}
    />
  );
};
