import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

export const DataRowContainer = styled(Box)(() => ({
  height: '69px',
  borderBottom: '1px solid #CAD9F2',
  display: 'flex',
  alignItems: 'center',
  padding: '12px 0px 12px 10px',
  gap: '10px',
  '&:hover': {
    backgroundColor: '#dcdcdc33'
  }
}));

export const ResponseCellContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  height: '100%',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  textOverflow: 'ellipsis',
  cursor: 'pointer'
}));

export const QuestionCellContainer = styled(Typography)(() => ({
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 2,
  textOverflow: 'ellipsis'
}));
