import { Add, DeleteOutlineOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { USER_APP_PERMISSIONS } from 'src/types/enum';
import { IActionButtonProps } from 'src/types/header';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

type Props = {
  selectedRows?: (string | number)[];
  havePermissionToDeleteRoles?: boolean;
  handleOnDeleteClick?: (data: boolean) => void;
  handleOnAddClick?: () => void;
  showAddButton?: boolean;
  showDeleteButton?: boolean;
};

export const ActionButtons = ({
  selectedRows = [],
  havePermissionToDeleteRoles = false,
  handleOnDeleteClick = (bool) => {},
  handleOnAddClick = () => {},
  showAddButton = false,
  showDeleteButton = false
}): IActionButtonProps[] => {
  const { t } = useTranslation();
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  return [
    {
      visible: havePermissionToDeleteRoles && showDeleteButton,
      variant: 'text',
      color: 'primary',
      icon: DeleteOutlineOutlined,
      disabled: !selectedRows.length,
      tooltipMsg: t(T.selectItemsToDelete),
      popover: false,
      onClick: () => handleOnDeleteClick(true),
      text: t(T.delete)
    },
    {
      visible:
        !!userAppPermissions[USER_APP_PERMISSIONS.CREATE_ROLES]?.value &&
        showAddButton,
      variant: 'outlined',
      color: 'primary',
      icon: Add,
      disabled: false,
      tooltipMsg: '',
      popover: false,
      onClick: handleOnAddClick,
      text: t(T.add)
    }
  ];
};
