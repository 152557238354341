import { Box, Paper, styled } from '@mui/material';

export const DataSelectorContainer = styled(Box)(() => ({
  height: '68px',
  display: 'flex',
  justifyContent: 'space-between'
}));

export const ContentContainer = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  border: '1px solid #CAD9F2',
  borderRadius: '2px'
}));

export const commonButtonStyles = {
  borderRadius: '6px',
  fontSize: '16px',
  fontWeight: 400
};

export const PdfRendererContainer = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  top: 0,
  left: '40%',
  position: 'fixed',
  width: '40%',
  padding: 6,
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    height: '85vh',
    margin: '6px 6px'
  }
}));
export const Dot = styled(Box)(({ theme }) => ({
  width: '8px',
  height: '8px',
  marginRight: '4px',
  backgroundColor: '#222F5999',
  animation: 'loaderDots 1.4s infinite ease-in-out both',
  '&:nth-of-type(1)': {
    animationDelay: '-0.32s'
  },
  '&:nth-of-type(2)': {
    animationDelay: '-0.16s'
  },
  '@keyframes loaderDots': {
    '0%, 80%, 100%': {
      opacity: 0.4
    },
    '40%': {
      opacity: 1
    }
  }
}));

export const LoaderDotsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  height: '100%'
});
