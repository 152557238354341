import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  useLazyGetDocumentsQuery,
  useLazyGetPermissionsQuery,
  useCreateResourceMutation
} from 'src/services/api';
import { ACTION_TYPES, PERMISSION_TYPE, RESOURCE_TYPE } from 'src/types/enum';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { last } from 'lodash';
import DocumentCardContent from '../../content/Documents/Workspaces/WorkspaceCard';
import {
  Grid as TableGrid,
  PagingPanel,
  TableHeaderRow,
  VirtualTable,
  TableEditRow,
  TableEditColumn,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import {
  COLUMNS,
  COLUMN_TITLES,
  EXEMPTED_WORKSPACES_COLLECTIONS,
  PAGE_SIZES,
  ROOT_WORKSPACE_PAGE_SIZE,
  UPLOAD_CONSTRAINT_RESOURCES,
  defaultSorting,
  documentsColumns,
  editingColumnExtensions,
  initialNewRow,
  isRowValid,
  sortingColumnExtensions
} from '../../content/Documents/utils/utils';
import React from 'react';
import { getEditorComponent } from '../../content/Documents/List/editor';
import GridContainer from 'src/components/GridContainer';
import {
  CustomTableRow,
  EditActionCell,
  NoDataRowComponent,
  Root,
  TableCellHeader,
  TableRowCell,
  TableRowHeader
} from 'src/components/Grid/styles';
import {
  CustomPaging,
  EditingState,
  IntegratedSelection,
  PagingState,
  SelectionState,
  Sorting,
  SortingState
} from '@devexpress/dx-react-grid';
import { NameProvider } from '../../content/Documents/ColumnFormatters/Name';
import { StatusProvider } from '../../content/Documents/ColumnFormatters/Status';
import { UploadedByProvider } from '../../content/Documents/ColumnFormatters/UploadedBy';
import { UploadedProvider } from '../../content/Documents/ColumnFormatters/UploadedDate';
import { Getter } from '@devexpress/dx-react-core';
import RowAction from './RowButtons';
import CardView from '../../content/Documents/Workspaces/WorkspaceCard/cardView';
import GridSkeleton from 'src/components/GridSkeleton';
import CardSkeleton from '../../content/Documents/utils/CardSkeleton';
import {
  addResource,
  handleSubmission,
  updateResource
} from '../../content/Documents/utils/gridUtils';
import TooltipOnDisabled from 'src/components/TooltipOnDisabled';
import { StringKeys } from 'src/types/base';
import CreateUpdateDialog from '../../content/Documents/Workspaces/WorkspaceCard/createWorkspacePopup';
import { ActionButtons } from './ActionButtons';
import PageHeader from 'src/components/PageHeader';
import Breadcrumbs from 'src/components/Breadcrumbs';
import useMutation from 'src/hooks/useMutation';
import { ErrorContext } from 'src/utils/errorMappings';
import Loader from 'src/components/Loader';
import useUpdateAccessRights from 'src/hooks/useUpdateAccessRights';
import CollectionCardContent from '../../content/Documents/Collections/CollectionCard';

type DataLibraryExplorerProps = {
  open: boolean;
  handleClose: () => void;
  dialogTitle: string;
  mainActionButtonText: string;
  disabledActionButtonMsg: string;
  sources: StringKeys;
  ignoreExemptionOfWorkspace?: boolean;
  handleDialogAction: () => void;
  disableCancelButton?: boolean;
  fetchDocumentsQueryParams?: StringKeys;
  showSelectionColumn?: boolean;
  infoIconTooltipText?: string;
  isRowClickDisabled?: boolean | ((row: any) => boolean);
  showCreateWorkspaceCard?: boolean;
  showCreateCollectionCard?: boolean;
  currentResourceId: string;
  setCurrentResourceId: React.Dispatch<React.SetStateAction<string>>;
  showAddButton?: boolean;
  onSelect?: (selected: any[]) => void;
  disableActionButton: boolean;
};

const DataLibraryExplorer = ({
  open,
  handleClose,
  dialogTitle,
  mainActionButtonText,
  disabledActionButtonMsg,
  sources,
  handleDialogAction,
  ignoreExemptionOfWorkspace = false,
  disableCancelButton = false,
  showSelectionColumn = false,
  fetchDocumentsQueryParams,
  isRowClickDisabled = false,
  infoIconTooltipText,
  showCreateWorkspaceCard = false,
  showCreateCollectionCard = false,
  showAddButton = false,
  currentResourceId,
  setCurrentResourceId,
  onSelect,
  disableActionButton = false
}: DataLibraryExplorerProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<StringKeys[]>([]);
  const [addNewRow, setAddNewRow] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [validationStatus, setValidationStatus] = useState({});
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZES[0]);
  const [editingRowIds, setEditingRowIds] = useState<number[]>([]);
  const [isCardViewAddUpdateDialogOpen, setCardViewAddUpdateDialogOpen] =
    useState<boolean>(false);
  const [columnExtensions] = useState<any>([]);
  const [isExternalResource, setIsExternalResource] = useState<boolean>(false);
  const [sorting, setSorting] = useState<Sorting[]>(defaultSorting);
  const [selectedDocs, setSelectedDocs] = useState<(string | number)[]>([]);

  const newRowDataRef = React.useRef<StringKeys>(initialNewRow);

  const { userAppPermissions, applicationResources } = useSelector(
    (state: RootState) => state.data
  );
  const { namespaceId } = useSelector((state: RootState) => state.library);

  const [createResource, { isLoading: isCreatingResource }] = useMutation({
    api: useCreateResourceMutation,
    errorContext: ErrorContext.FILES
  });

  const {
    currentResourceType,
    setResourcePermissions,
    setCurrentResourceType
  } = useContext(DocumentsContext);

  const [
    fetchUserResourcePermissions,
    {
      data: userResourcePermissions,
      isLoading: isLoadingPermissions,
      isFetching: isFetchingPermissions
    }
  ] = useLazyGetPermissionsQuery();
  const [fetchDocuments, { data: documents, isFetching }] =
    useLazyGetDocumentsQuery();

  const { handleUpdateAccessRights, isUpdatingAccess } =
    useUpdateAccessRights();

  useEffect(() => {
    newRowDataRef.current = initialNewRow;
    setAddNewRow(false);
    setEditingRowIds([]);
    setValidationStatus({});
    setPage(0);
    setSorting(defaultSorting);
    if (currentResourceId) {
      fetchResourcePermissions();
    } else {
      setResourcePermissions({});
    }
  }, [currentResourceId]);

  useEffect(() => {
    if (documents?.data) {
      setRows(documents?.data);
      setCurrentResourceType(
        last(documents.parent_hierarchy as any[]).type_code || RESOURCE_TYPE.NAMESPACE
      );
      setIsExternalResource(last(documents.parent_hierarchy as any[])?.provider_id);
    }
  }, [documents]);

  useEffect(() => {
    if (
      userResourcePermissions &&
      userResourcePermissions[currentResourceId || namespaceId] &&
      !isLoadingPermissions &&
      !isFetchingPermissions
    ) {
      setResourcePermissions({
        ...userResourcePermissions[currentResourceId || namespaceId]
      });
    }
  }, [userResourcePermissions, isLoadingPermissions, isFetchingPermissions]);

  useEffect(() => {
    fetchDocuments({
      params: {
        parent_id: currentResourceId || null,
        p: page,
        ps: !!currentResourceId ? pageSize : ROOT_WORKSPACE_PAGE_SIZE,
        order_by: `${sorting[0].columnName} ${sorting[0].direction}`,
        ...fetchDocumentsQueryParams
      }
    });
  }, [page, pageSize, currentResourceId, sorting, fetchDocumentsQueryParams]);

  const translatedDocumentColumns = useMemo(
    () =>
      documentsColumns(isExternalResource)?.map((col) => ({
        ...col,
        title: t(col.title)
      })),
    [i18n.language]
  );

  const messages = useMemo(
    () => ({
      noData: t(T.noData)
    }),
    []
  );

  const CustomTableRoot = useCallback(
    (props) => {
      return <Root {...props} isMoveDialogOpen={open} />;
    },
    [addNewRow]
  );

  const handleEnterPress = (
    rowData: StringKeys,
    updatedRow: Record<string, StringKeys>
  ) => {
    const onExecute = () => {
      onRowUpdated(updatedRow);

      if (isRowValid(updatedRow[rowData.id])) {
        setEditingRowIds((prev) => prev.filter((id) => id !== rowData.id));
      }
    };
    handleSubmission(
      ACTION_TYPES.COMMIT,
      addNewRow,
      rowData,
      newRowDataRef,
      setAddNewRow,
      setValidationStatus,
      onExecute,
      onRowAdded
    );
  };

  const fetchResourcePermissions = useCallback(() => {
    fetchUserResourcePermissions({
      params: {
        ptype: PERMISSION_TYPE.USER_RESOURCE_PERMISSION,
        rid: currentResourceId
      }
    });
  }, [currentResourceId]);

  const renderEditors = useCallback(
    (props) => {
      return getEditorComponent({
        cellProps: props,
        addNewRow,
        validationStatus,
        newRow: newRowDataRef.current,
        handleEnterPress
      });
    },
    [validationStatus, addNewRow, rows]
  );

  const commitChanges = ({ changed }: any) => {
    if (changed) {
      onRowUpdated(changed);
    }
  };

  const Command = ({ id, onExecute, ...props }: any) => {
    return (
      <RowAction
        actionType={id}
        addNewRow={addNewRow}
        newRowDataRef={newRowDataRef}
        setAddNewRow={setAddNewRow}
        onExecute={onExecute}
        rowData={props.row}
        setValidationStatus={setValidationStatus}
        onRowAdded={onRowAdded}
        {...props}
      />
    );
  };

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
    setPage(0);
  }, []);

  const handleCreateUpdateNewCard = (isRename?: boolean, row?: any) => {
    toggleCreateUpdateDialog();
    setSelectedCard(row);
  };

  const onRowUpdated = (changed) =>
    updateResource(
      changed,
      rows,
      addNewRow,
      newRowDataRef,
      setValidationStatus,
      currentResourceId,
      setRows,
      dispatch,
      t
    );

  const onRowAdded = async (added, addedUsers?, removedUsers?) => {
    addResource(
      currentResourceType,
      added,
      rows,
      currentResourceId,
      newRowDataRef,
      setAddNewRow,
      setEditingRowIds,
      setValidationStatus,
      dispatch,
      createResource,
      theme,
      t,
      handleUpdateAccessRights,
      addedUsers,
      removedUsers,
      applicationResources.User
    );
  };

  const handleSetEditingRowIds = (ids: number[]) => {
    if (addNewRow) return;
    const setIds = new Set([
      ...ids,
      ...Object.keys(validationStatus).map(Number)
    ]);
    setEditingRowIds(Array.from(setIds).map((id) => +id));
  };

  const TableRow = ({ row, ...restProps }: any) => {
    const onClickRow = (e: any) => {
      if (
        sources[row.id] ||
        (row.type_code === EXEMPTED_WORKSPACES_COLLECTIONS.FOUND &&
          !currentResourceId) ||
        RESOURCE_TYPE.FILE === row.type_code ||
        row.provider_id
      ) {
        return;
      } else if (e.detail == 1) {
        setCurrentResourceId(row.id);
        setCurrentResourceType(row.type_code);
        return;
      }
    };

    const isDisabled = useMemo(
      () =>
        typeof isRowClickDisabled === 'boolean'
          ? isRowClickDisabled
          : isRowClickDisabled(row),
      [isRowClickDisabled, row]
    );

    return (
      <CustomTableRow
        {...restProps}
        onClick={onClickRow}
        disabled={isDisabled}
      />
    );
  };

  const setCurrentResourceData = (resource) => {
    setCurrentResourceId(resource.id);
    setCurrentResourceType(resource.type_code);
  };

  const renderGrid = useMemo(() => {
    const currentRows = addNewRow ? [newRowDataRef.current, ...rows] : rows;
    const isContentRoot = !currentResourceId;
    return (
      <GridContainer
        height={'100%'}
        length={rows?.length || 0}
        isCardContent={isContentRoot}
      >
        {isContentRoot ? (
          <DocumentCardContent
            rows={currentRows}
            setRows={setRows}
            isMoveDialogOpen={open}
            setActiveDirectory={setCurrentResourceData}
            handleCreateUpdateNewCard={handleCreateUpdateNewCard}
            ignoreExemptionOfWorkspace={ignoreExemptionOfWorkspace}
            showCreateWorkspaceCard={showCreateWorkspaceCard}
          />
        ) : UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType) ? (
          <Box minHeight={'100%'} overflow={'auto'}>
            <CollectionCardContent
              rows={currentRows}
              onRowUpdated={onRowUpdated}
              isMoveDialogOpen={open}
              setActiveDirectory={setCurrentResourceData}
              onRowAdded={onRowAdded}
              addNewRow={addNewRow}
              setAddNewRow={setAddNewRow}
              showCreateCollectionCard={showCreateCollectionCard}
            />
          </Box>
        ) : (
          <TableGrid
            rows={currentRows}
            columns={translatedDocumentColumns}
            getRowId={(row) => row.id}
            rootComponent={CustomTableRoot}
          >
            <EditingState
              onCommitChanges={commitChanges}
              editingRowIds={editingRowIds}
              columnExtensions={editingColumnExtensions}
              onEditingRowIdsChange={handleSetEditingRowIds}
            />
            <SortingState
              sorting={sorting}
              columnExtensions={sortingColumnExtensions}
              onSortingChange={setSorting}
            />
            <NameProvider for={[COLUMNS.FILENAME]} isMoveDialogOpen={open} />
            <StatusProvider for={[COLUMNS.STATUS]} />
            <UploadedByProvider
              for={[COLUMNS.MODIFIED_BY, COLUMNS.SYNCED_BY]}
            />
            <UploadedProvider for={[COLUMNS.MODIFIED_ON, COLUMNS.SYNCED_ON]} />
            <PagingState
              currentPage={page}
              onCurrentPageChange={setPage}
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
            />
            <CustomPaging totalCount={documents?.rc || 0} />
            <PagingPanel
              pageSizes={PAGE_SIZES}
              messages={{
                rowsPerPage: t(T.rowsPerPage)
              }}
            />
            <VirtualTable
              rowComponent={TableRow}
              cellComponent={TableRowCell}
              columnExtensions={columnExtensions}
              noDataRowComponent={NoDataRowComponent}
              messages={messages}
            />

            <TableEditColumn
              showDeleteCommand
              showEditCommand
              commandComponent={Command}
              cellComponent={EditActionCell}
            />

            <TableHeaderRow
              rowComponent={TableRowHeader}
              cellComponent={TableCellHeader}
              showSortingControls
            />
            <TableEditRow cellComponent={renderEditors} />
            <Getter
              name="tableColumns"
              computed={({ tableColumns }) => [
                ...tableColumns.filter(
                  (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
                ),
                {
                  key: 'editCommand',
                  type: TableEditColumn.COLUMN_TYPE
                }
              ]}
            />
            <SelectionState
              selection={selectedDocs}
              onSelectionChange={(selection) => {
                setSelectedDocs(selection);

                if (onSelect) {
                  const selected = rows.filter((row) =>
                    selection.includes(row.id)
                  );
                  onSelect(selected);
                }
              }}
            />

            <IntegratedSelection />
            <TableSelection
              showSelectAll
              showSelectionColumn={showSelectionColumn}
            />
          </TableGrid>
        )}
      </GridContainer>
    );
  }, [
    rows,
    documents,
    pageSize,
    page,
    addNewRow,
    i18n.language,
    editingRowIds,
    validationStatus,
    currentResourceId,
    currentResourceType,
    selectedDocs,
    showCreateWorkspaceCard,
    showSelectionColumn,
    onSelect
  ]);

  const handleBreadcrumbNavigation = (item, index) => {
    setCurrentResourceType(item.type_code);
    setCurrentResourceId(() => (index === 0 ? null : item.id.toString()));
  };

  const renderBreadcrumbs = useMemo(
    () => (
      <Breadcrumbs
        hierarchy={[...(documents?.parent_hierarchy || [])]}
        onBreadcrumbItemClick={handleBreadcrumbNavigation}
      />
    ),
    [documents]
  );

  const handleAddNewRow = () => {
    setAddNewRow(true);
    setEditingRowIds([initialNewRow?.id]);
    setValidationStatus({});
  };

  const toggleCreateUpdateDialog = () => {
    setCardViewAddUpdateDialogOpen(!isCardViewAddUpdateDialogOpen);
  };

  const handleAddButton = () => {
    if (currentResourceId) {
      handleAddNewRow();
    } else {
      handleCreateUpdateNewCard(false);
    }
  };

  const currentWorkSpace = useMemo(() => {
    return documents?.parent_hierarchy?.[1] || null;
  }, [JSON.stringify(documents?.parent_hierarchy?.[1] || '')]);

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        fullWidth={true}
        onClose={handleClose}
        maxWidth={'lg'}
        sx={{
          minWidth: '300px',
          padding: 0,
          '& .MuiDialogContent-root': {
            padding: '0 !important'
          }
        }}
      >
        <DialogTitle>
          <PageHeader
            title={dialogTitle}
            showInfoIcon={!!infoIconTooltipText}
            infoIconTooltipText={infoIconTooltipText}
            actionButtons={ActionButtons(
              showAddButton,
              addNewRow,
              handleAddButton
            )}
          />
        </DialogTitle>
        <DialogContent>
          <Box padding={0} paddingLeft={1}>
            {isCreatingResource || (isUpdatingAccess && <Loader />)}
            {currentResourceId && currentWorkSpace ? (
              <Box marginBottom={2}>
                <CardView
                  index={0}
                  row={documents?.parent_hierarchy?.[1]}
                  isHeader
                  breadcrumbs={renderBreadcrumbs}
                  ignoreExemptionOfWorkspace={ignoreExemptionOfWorkspace}
                  workspace={{
                    rows: rows,
                    setRows: setRows,
                    isMoveDialogOpen: open,
                    showCreateWorkspaceCard
                  }}
                />
              </Box>
            ) : (
              <>
                {!!currentResourceId && !currentWorkSpace && (
                  <Skeleton
                    variant="rectangular"
                    animation="wave"
                    sx={{
                      minWidth: '450px',
                      height: '120px',
                      marginBottom: 2
                    }}
                  />
                )}
              </>
            )}
            {!currentResourceId && renderBreadcrumbs}
            {isFetching ? (
              !!currentResourceId &&
              !UPLOAD_CONSTRAINT_RESOURCES.includes(currentResourceType) ? (
                <GridSkeleton
                  columnExtensions={columnExtensions.map((item) => ({
                    ...item,
                    title: COLUMN_TITLES[item.columnName]
                  }))}
                />
              ) : (
                <CardSkeleton skeletonType={currentResourceType} />
              )
            ) : (
              <>{renderGrid}</>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={disableCancelButton}
            variant="outlined"
            size="small"
          >
            {t(T.cancel)}
          </Button>
          <TooltipOnDisabled
            title={disabledActionButtonMsg}
            showTooltip={disableActionButton}
          >
            <Button
              disabled={disableActionButton}
              onClick={handleDialogAction}
              variant="contained"
              size="small"
              autoFocus
              color="secondary"
              sx={{
                marginLeft: '10px'
              }}
            >
              {mainActionButtonText}
            </Button>
          </TooltipOnDisabled>
        </DialogActions>
      </Dialog>
      {isCardViewAddUpdateDialogOpen && (
        <CreateUpdateDialog
          message={T.documentUpdateConfirmation}
          onClose={toggleCreateUpdateDialog}
          onConfirm={onRowAdded}
          isOpen={isCardViewAddUpdateDialogOpen}
          cardDetails={selectedCard}
        />
      )}
    </>
  );
};

export default DataLibraryExplorer;
