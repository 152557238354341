import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import {
  Box,
  Container,
  CssBaseline,
  Drawer,
  IconButton,
  useTheme
} from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from './Sidebar';
import { ReactComponent as SidebarIcon } from 'src/assets/sidebarIcon/sidebar-icon.svg';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { Main, drawerWidth, miniDrawerWidth } from './styles';
import UserOnboard from 'src/components/UserOnboard';
import { RootState, useSelector } from 'src/redux/store';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  const theme = useTheme();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const [isShareChatPath, setIsShareChatPath] = useState<boolean>(true);
  const { isOnboarded, isLangInitialized } = useSelector(
    (state: RootState) => state.data
  );
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname)
      setIsShareChatPath(pathname.split('/').includes('share-chat'));
  }, [pathname]);
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      {!isShareChatPath && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,

            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box'
            }
          }}
          variant="persistent"
          anchor="left"
          open={sidebarToggle}
        >
          <Sidebar />
        </Drawer>
      )}
      <Drawer
        sx={{
          width: `${miniDrawerWidth}px`,
          flexShrink: 0,
          position: 'absolute',
          left: 0,
          '& .MuiDrawer-paper': {
            width: `${miniDrawerWidth}px`,
            boxSizing: 'border-box',
            backgroundColor: theme.sidebar.background
          }
        }}
        variant="persistent"
        anchor="left"
        open={!sidebarToggle}
      >
        <IconButton
          aria-label="open drawer"
          onClick={closeSidebar}
          color="secondary"
          sx={{
            my: theme.spacing(2),
            mx: 'auto',
            ...(sidebarToggle && { display: 'none' }),
            padding: theme.spacing(1.4)
          }}
        >
          <SidebarIcon height={'24px'} width={'24px'} />
        </IconButton>
      </Drawer>
      <Main
        props={{ isSideBarOpen: !sidebarToggle, isHidden: isShareChatPath }}
      >
        <Container
          maxWidth={false}
          disableGutters
          style={{ overflow: 'auto', height: '100%' }}
        >
          <Outlet />
          {!isOnboarded && isLangInitialized && <UserOnboard />}
        </Container>
      </Main>
    </Box>
  );
};

export default AccentHeaderLayout;
