import { Box, Button, styled, Typography } from '@mui/material';
import { Theme, alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { SelectItem } from 'src/types/base';
import { Circle } from '@mui/icons-material';

export const useCardStyles = makeStyles(() => ({
  documentCardWrapper: {
    display: 'flex',
    position: 'relative',
    borderRadius: '6px !important',
    cursor: 'pointer',
    minHeight: '118px',
    alignItems: 'center'
  },
  workspaceTitle: {
    fontSize: 26,
    marginBottom: 5
  },
  cardTypography: { fontSize: 10, fontWeight: 500 },
  initialBoxInnerWrapper: {
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center'
  },
  initialBoxEditIcon: {
    display: 'inline-grid',
    color: 'white',
    fontSize: 14,
    height: 45,
    textAlign: 'center',
    pointerEvents: 'none'
  },
  cuDialogMenuWrapper: {
    display: 'flex',
    flexFlow: 'row wrap',
    minWidth: 300
  },
  cuDialogColorHeading: {
    margin: '8px 0 4px 4px',
    color: '#676879',
    width: '100%',
    fontSize: 14
  },
  cuDialogMenuItem: {
    margin: -5
  }
}));

export const ActionsWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 14,
  bottom: 12,
  zIndex: 10,
  backgroundColor: '#FFF'
}));

export const StartChatActionWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 14,
  top: 14
}));

export const InitialBoxContainer = styled('div')(() => ({
  width: '90px',
  height: '90px',
  color: 'white',
  borderRadius: '9px',
  marginRight: '15px',
  fontSize: 35,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0
}));

export const getDocCardWrapperStyles = (
  isHeader: boolean,
  row: any,
  theme: Theme
) => {
  return {
    backgroundColor:
      isHeader && row?.initials_color
        ? alpha(row?.initials_color, 0.1)
        : 'inherit',
    color: 'inherit',
    padding: '0 14px',
    ...(!isHeader && {
      border: `1px solid ${alpha(theme.colors.custom.labelColor, 0.2)}`,
      borderRadius: '6px !important'
    }),
    boxShadow: 'none',
    ':hover': {
      '& .actionWrapper': {
        visibility: 'visible'
      },

      ...(!isHeader && {
        boxShadow: theme.colors.shadows.cardElevation
      })
    },
    '& .actionWrapper': {
      visibility: 'hidden'
    },
    '& .starRateIcon': {
      visibility: row?.isFavourite ? 'visible' : 'hidden'
    }
  };
};

export const getCardIconsStyles = (isHeader: boolean) => {
  return {
    color: isHeader ? 'white' : '',
    '&:hover': {
      borderRadius: '5px'
    },
    '& path': {
      ...(isHeader && {
        fill: `white !important`
      })
    }
  };
};

export const getCardTitleStyles = (isHeader: boolean) => {
  return (
    !isHeader && {
      overflow: 'hidden',
      maxWidth: 400,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  );
};

export const renderColor = (value: SelectItem) => {
  return <Circle sx={{ color: value.name || value, fontSize: '1.2rem' }} />;
};

export const DisclaimerText = styled(Typography)(() => ({
  color: '#222f59b3',
  fontSize: '13px',
  fontWeight: 400,
  letterSpacing: '0.5px',
  fontStyle: 'normal'
}));

export const CollectionDisclaimerBox = styled(Box)(() => ({
  padding: '0 4.5px',
  position: 'absolute',
  right: '10px',
  bottom: '10px'
}));
