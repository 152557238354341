import {
  DataTypeProvider,
  DataTypeProviderProps
} from '@devexpress/dx-react-grid';
import { Box, Typography } from '@mui/material';

const NameFormatter = ({ value }: any) => {
  return (
    <Box display={'flex'} alignItems={'center'}>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

export const NameProvider = (props: any) => {
  return <DataTypeProvider formatterComponent={NameFormatter} {...props} />;
};
