import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Typography
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AvatarListDialog from 'src/components/AvatarListDialog';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const MembersFormatter = ({ value }: { value: string[] }) => {
  const [isUsersListOpen, setIsUsersListOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const getUserNameInitials = useCallback((username: string) => {
    let splittedUsername = username.split(' ');
    if (splittedUsername.length <= 1) {
      splittedUsername = username.split('.');
    }
    return splittedUsername?.[0]?.[0]?.concat(
      (splittedUsername?.length > 1
        ? splittedUsername?.[1]?.[0]
        : splittedUsername?.[0]?.[1]) || ''
    );
  }, []);

  const handleOpenUserGroupsList = useCallback(
    () => setIsUsersListOpen(true),
    []
  );
  const handleCloseUserGroupsList = useCallback(
    () => setIsUsersListOpen(false),
    []
  );

  if (!value.length) return null;

  return (
    <>
      <Box display={'flex'}>
        <AvatarGroup max={4}>
          {value.slice(0, 3).map((username, index) => {
            return (
              <Avatar
                key={username}
                sx={(theme) => ({
                  bgcolor: theme.colors.primaryAlt.main,
                  height: 30,
                  width: 30
                })}
              >
                <Typography
                  color="primary"
                  sx={{
                    textTransform: 'uppercase'
                  }}
                >
                  {getUserNameInitials(username)}
                </Typography>
              </Avatar>
            );
          })}
          {value.length > 3 && (
            <Avatar
              key="count"
              sx={(theme) => ({
                bgcolor: theme.colors.primaryAlt.main,
                height: 30,
                width: 30
              })}
            >
              <IconButton
                sx={{ padding: 0 }}
                onClick={handleOpenUserGroupsList}
              >
                <Typography color="primary">+{value.length - 3}</Typography>
              </IconButton>
            </Avatar>
          )}
        </AvatarGroup>
      </Box>
      {isUsersListOpen && (
        <AvatarListDialog
          isOpen={isUsersListOpen}
          handleClose={handleCloseUserGroupsList}
          title={t(T.users)}
          nameList={value}
          getInitials={getUserNameInitials}
        />
      )}
    </>
  );
};

export const MembersProvider = (props: any) => (
  <DataTypeProvider formatterComponent={MembersFormatter} {...props} />
);
