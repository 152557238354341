import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import {
  EditOutlined as EditIcon,
  Add as AddIcon,
  DeleteOutline as DeleteIcon
} from '@mui/icons-material';
import {
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  styled,
  Button
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const EditButton = ({ onExecute }: any) => (
  <IconButton onClick={onExecute} title="Edit row">
    <EditIcon
      color="primary"
      fontSize="small"
      sx={(theme) => ({
        color: theme.colors.custom.iconColor
      })}
    />
  </IconButton>
);

export const DeleteButton = ({ onExecute }: any) => (
  <IconButton onClick={onExecute} title="Delete row">
    <DeleteIcon
      fontSize="small"
      sx={(theme) => ({
        color: theme.colors.custom.iconColor
      })}
    />
  </IconButton>
);

export const AddButton = ({ onExecute }: any) => {
  const { t } = useTranslation();
  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onExecute}
      title={t(T.createUser)}
      endIcon={<AddIcon />}
    >
      {t(T.new)}
    </Button>
  );
};

export const DialogContent = styled(MuiDialogContent)`
  background: ${(props) => props.theme.palette.background.default};
  .MuiPaper-root,
  .MuiTableCell-body,
  .MuiTableCell-head {
    color: black;
  }
`;

export const DialogActions = styled(MuiDialogActions)`
  background: ${(props) => props.theme.palette.background.default};
  padding: 12px 24px 24px 24px;
`;
