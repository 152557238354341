import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addDocument } from 'src/redux/slices/data';
import {
  addOpenedDocument,
  updateOpenedDocument,
  addCurrentlyDownloadingDocuments,
  removeCurrentlyDownloadingDocuments
} from 'src/redux/slices/docAnalyst';
import { useLazyDownloadFileQuery } from 'src/services/api';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { RootState } from 'src/redux/store';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { Typography } from '@mui/material';
import { DataApiInputParams } from 'src/types/api';
import { SUPPORTED_UPLOAD_FILES_FORMAT, FILE_EXTENSIONS } from 'src/types/enum';
import { downloadFileToSystem } from 'src/utils/files/file_encryption';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { ArticleOutlined } from '@mui/icons-material';
import { getFileIcon, getFileType } from 'src/utils/utils';
import { ErrorContext } from 'src/utils/errorMappings';
import { SourceItemProps } from 'src/types/custom_component';

const SourceItem = ({
  id,
  name,
  page,
  source,
  format,
  original_format,
  setIsDownloadingFile,
  documentId
}: SourceItemProps) => {
  const { t }: { t: any } = useTranslation();

  const dispatch = useDispatch();
  const documents = useSelector((state: RootState) => state.data.documents);
  const [downloadFile, { data, isLoading, isError, isFetching }] = useLazyQuery<
    DataApiInputParams,
    any
  >({ api: useLazyDownloadFileQuery, errorContext: ErrorContext.FILES });
  const [fileType] = useState(
    getFileType(name, source, format, original_format)
  );

  const {
    initiateAnalysis: { selectedAnalysis },
    analysisHistoryContexts,
    currentlyDownloadingDocuments
  } = useSelector((state: RootState) => state.docAnalyst);

  useEffect(() => {
    if (data && !isFetching) {
      const blob = new Blob([data.blob], {
        type: data?.contentType
      });
      if (data?.format === FILE_EXTENSIONS.PDF) {
        dispatch(addDocument({ id, file: blob }));
        dispatch(
          addOpenedDocument({
            analysisId: +selectedAnalysis?.id,
            document: { name, pageNumber: page, id, pdfData: blob }
          })
        );
      } else {
        dispatch(addDocument({ id, file: blob }));
        downloadFileToSystem(blob, name);
      }
      dispatch(removeCurrentlyDownloadingDocuments(id));
    }
  }, [data, isFetching]);

  useEffect(() => {
    setIsDownloadingFile(isLoading);
  }, [isLoading]);

  const updateOtherSource = () =>
    downloadFileToSystem(documents[id].file, name);

  const updatePdfSource = () => {
    const docIndex = analysisHistoryContexts[
      selectedAnalysis?.id
    ].openedDocuments.findIndex((doc) => doc.id === id);
    if (docIndex < 0) {
      dispatch(
        addOpenedDocument({
          analysisId: +selectedAnalysis?.id,
          document: {
            name,
            pageNumber: page,
            id,
            pdfData: documents[id].file
          }
        })
      );
    } else {
      dispatch(
        updateOpenedDocument({
          analysisId: selectedAnalysis?.id,
          document: {
            name,
            pageNumber: page,
            id,
            index: docIndex
          }
        })
      );
    }
  };

  const getStartIcon = useMemo(() => {
    const Icon = getFileIcon(fileType);
    if (Icon) {
      return <Icon height={20} width={20} />;
    }
    return <ArticleOutlined fontSize="small" />;
  }, [fileType]);

  const handleDownloadClick = useCallback(() => {
    if (id in documents) {
      if (documents[id]?.file?.type === SUPPORTED_UPLOAD_FILES_FORMAT.PDF) {
        updatePdfSource();
      } else {
        updateOtherSource();
      }
    } else {
      dispatch(addCurrentlyDownloadingDocuments(id));
      downloadFile({ params: { params: { id } } });
    }
  }, [documents, analysisHistoryContexts, selectedAnalysis?.id]);

  return (
    <LoadingButton
      key={id}
      id={documentId ? `+${documentId}` : undefined}
      startIcon={getStartIcon}
      loading={isLoading}
      onClick={handleDownloadClick}
      loadingPosition="start"
      style={{
        justifyContent: 'flex-start',
        textAlign: 'start'
      }}
      disabled={
        !name || isLoading || currentlyDownloadingDocuments.includes(id)
      }
    >
      <Typography
        sx={{
          width: '100%',
          textAlign: 'left',
          fontWeight: 'bold'
        }}
        variant="body2"
      >
        {name ? `${name} (page: ${page})` : `[${t(T.resourceUnavailable)}]`}
      </Typography>
    </LoadingButton>
  );
};

export default SourceItem;
