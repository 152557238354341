import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import { t } from 'i18next';
import { RootState, useSelector } from 'src/redux/store';
import { PROVIDER_STATUS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const UploadedFormatter = ({ value, row }: any) => {
  const showSyncStatus =
    row?.provider_id &&
    (row?.sync_status_code === PROVIDER_STATUS.SYNC_STARTED ||
      row?.sync_status_code === PROVIDER_STATUS.SYNC_STARTING ||
      row.sync_status_code === PROVIDER_STATUS.SYNC_RETRYING ||
      row?.sync_status_code === PROVIDER_STATUS.SYNC_FAILED);
  return (
    <Typography
      noWrap
      sx={{
        fontSize: '14px'
      }}
    >
      {showSyncStatus ? (
        <Typography
          style={{
            padding: '0px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }}
        >
          {row?.sync_status_code === PROVIDER_STATUS.SYNC_FAILED
            ? t(T.syncingFailed)
            : t(T.syncing)}
        </Typography>
      ) : (
        <Typography>
          {!!value?.length
            ? formatDistance(new Date(value), new Date(), {
                addSuffix: true
              })
            : 'NA'}
        </Typography>
      )}
    </Typography>
  );
};

export const UploadedProvider = (props: any) => (
  <DataTypeProvider formatterComponent={UploadedFormatter} {...props} />
);
