import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as ChevronLeft } from 'src/assets/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'src/assets/ChevronRight.svg';
import CustomTypography from 'src/components/CustomTypography';
import Markdown from 'src/components/Markdown';
import MultiTabsPdfViewer from 'src/content/Chat/Conversation/MultiTabsPdfViewer';
import { SecondaryMessage } from 'src/content/Chat/styles';
import { sortFiles } from 'src/content/Chat/utils';
import {
  closeDocumentViewer,
  removeOpenedDocument
} from 'src/redux/slices/docAnalyst';
import { RootState, useSelector } from 'src/redux/store';
import { SourceItemProps } from 'src/types/custom_component';
import { AnalysisResponseProps } from 'src/types/docAnalyst';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import SourceItem from '../SourceItem';
import { PdfRendererContainer } from './styles';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  response: AnalysisResponseProps;
  currentOpenedQuestion: number;
  onNext: () => void;
  onPrev: () => void;
  isDocumentViewerOpen: boolean;
  totalQuestions: number;
};

export default function ResponseDetailViewer({
  open,
  setOpen,
  response,
  currentOpenedQuestion,
  onNext,
  onPrev,
  isDocumentViewerOpen,
  totalQuestions
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isDownloadingFile, setIsDownloadingFile] = useState<boolean>(false);
  const {
    initiateAnalysis: { selectedAnalysis },
    analysisHistoryContexts
  } = useSelector((state: RootState) => state.docAnalyst);

  const answerData = useMemo(() => {
    try {
      if (typeof response?.answer === 'string') {
        const parsedResponse = JSON.parse(response.answer);
        return parsedResponse;
      }
      return response?.answer;
    } catch (error) {
      return { response: response?.answer };
    }
  }, [response.answer]);

  const sortedFiles = useMemo(() => {
    if (!!answerData?.files?.length) {
      return sortFiles(answerData);
    }
  }, [answerData?.files]);

  const handleOnCloseDocument = useCallback(
    ({ resourceId, documentIndex }) =>
      dispatch(
        removeOpenedDocument({
          analysisId: resourceId,
          documentIndex: documentIndex
        })
      ),
    []
  );

  const detailedAnswer = useMemo(
    () => (
      <SecondaryMessage>
        <Markdown
          value={answerData?.response}
          showCursor={false}
          files={[]}
          isDownloadingFile={isDownloadingFile}
        />
        {answerData?.files?.length ? (
          <Typography style={{ paddingTop: 8, fontSize: 16, fontWeight: 500 }}>
            {t(T.sources)}:
          </Typography>
        ) : null}
        <ol>
          {!!answerData?.files?.length &&
            sortedFiles?.length &&
            sortedFiles?.map(
              (
                {
                  id,
                  name,
                  source,
                  page,
                  format,
                  original_format,
                  document_id
                }: SourceItemProps & { document_id: string },
                index
              ) => {
                return id ? (
                  <li key={index}>
                    <SourceItem
                      key={index}
                      id={id}
                      name={name}
                      page={page}
                      source={source}
                      format={format || ''}
                      original_format={original_format}
                      documentId={document_id}
                      setIsDownloadingFile={setIsDownloadingFile}
                    />
                  </li>
                ) : null;
              }
            )}
        </ol>
      </SecondaryMessage>
    ),
    [response]
  );

  const pdfRender = useMemo(
    () => (
      <PdfRendererContainer>
        <MultiTabsPdfViewer
          selectedDocumentIndex={
            analysisHistoryContexts?.[selectedAnalysis?.id]
              ?.selectedDocumentIndex
          }
          pdfDocuments={
            analysisHistoryContexts?.[selectedAnalysis?.id]?.openedDocuments
          }
          resourceId={selectedAnalysis?.id}
          onDocumentRemove={handleOnCloseDocument}
        />
      </PdfRendererContainer>
    ),
    [
      analysisHistoryContexts?.[selectedAnalysis?.id]?.selectedDocumentIndex,
      analysisHistoryContexts?.[selectedAnalysis?.id]?.openedDocuments,
      selectedAnalysis?.id
    ]
  );

  const handleCloseDialog = useCallback(
    (newOpen: boolean) => () => {
      setOpen(newOpen);
      dispatch(closeDocumentViewer(selectedAnalysis?.id));
    },
    []
  );

  return (
    <Drawer
      open={open}
      onClose={handleCloseDialog(false)}
      sx={{
        zIndex: 1300,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          maxWidth: '80%',
          width: '40%',
          boxSizing: 'border-box'
        }
      }}
    >
      <>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          paddingX={'10px'}
        >
          <IconButton disabled={currentOpenedQuestion === 1} onClick={onPrev}>
            <ChevronLeft
              fill={currentOpenedQuestion === 1 && '#222F5966'}
              height={'24px'}
              width={'24px'}
            />
          </IconButton>

          <CustomTypography text={`Question # ${currentOpenedQuestion}`} />
          <IconButton
            disabled={totalQuestions === currentOpenedQuestion}
            onClick={onNext}
          >
            <ChevronRight
              fill={totalQuestions === currentOpenedQuestion && '#222F5966'}
              height={'24px'}
              width={'24px'}
            />
          </IconButton>
        </Box>
        <Box padding={'20px'} height={'100%'} overflow={'auto'}>
          <CustomTypography text={response.question} />
          <Typography>{detailedAnswer}</Typography>
        </Box>
      </>
      {isDocumentViewerOpen && pdfRender}
    </Drawer>
  );
}
