import {
  FilterComponents,
  FilterElementTypes
} from 'src/types/custom_component';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const getFilters = () => {
  const filters: FilterComponents[] = [
    {
      name: 'Search',
      type: FilterElementTypes.Text,
      elementWidth: 250,
      dataField: 's',
      placeholder: T.search,
      showIcon: true,
      showDivider: false
    }
  ];

  return filters;
};
