import {
  IntegratedSorting,
  Sorting,
  SortingState
} from '@devexpress/dx-react-grid';
import { StringKeys } from 'src/types/base';
import { ACTION_TYPES, ACCOUNT_TYPE } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { IAccountType } from './types';
import { caseInsensitiveSort } from 'src/utils/utils';

export const PAGE_SIZES = [10, 20, 50, 500];
export const MIN_WIDTH_ALLOWED = 1140;
export const USER_DEFAULT_PASSWORD = '#Egpt123';
export const USER_COLUMNS = {
  NAME: 'name',
  USER_GROUPS: 'userGroups',
  STATUS: 'status',
  ACCOUNT_TYPE: 'accountType'
};

export const ACCOUNT_TYPE_DESCRIPTION = {
  [ACCOUNT_TYPE.ORGANIZATION_ADMINISTRATOR]: T.organizationAdminSubtitle,
  [ACCOUNT_TYPE.WORKSPACE_ADMINISTRATOR]: T.workspaceAdminSubtitle,
  [ACCOUNT_TYPE.REGULAR_USER]: T.regularUserSubtitle
};

export const placeholderRow = {
  id: '',
  email: 'dummy_row@email.com',
  fullName: '',
  accountType: {},
  userGroups: []
};
export const UserColumns = [
  {
    name: USER_COLUMNS.NAME,
    title: T.users,
    required: true,
    getCellValue: (row) => {
      return { fullName: row?.fullName, email: row?.email } || {};
    }
  },
  {
    name: USER_COLUMNS.USER_GROUPS,
    title: T.userGroups,
    required: false
  },
  {
    name: USER_COLUMNS.ACCOUNT_TYPE,
    title: T.accountType,
    required: true
  },
  {
    name: USER_COLUMNS.STATUS,
    title: T.status,
    required: false
  }
];

export const userColumnExtensions = [
  {
    columnName: USER_COLUMNS.NAME,
    title: T.users,
    width: '31%',
    wordWrapEnabled: true
  },
  {
    columnName: USER_COLUMNS.USER_GROUPS,
    width: '17%',
    wordWrapEnabled: true
  },
  {
    columnName: USER_COLUMNS.ACCOUNT_TYPE,
    width: '27%',
    wordWrapEnabled: true
  },
  {
    columnName: USER_COLUMNS.STATUS,
    width: '18%',
    wordWrapEnabled: true
  }
];

export const filterList = (a: string[], b: string[]) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

export const generateRows = (users: any, loggedInUser) => {
  let sortedUsers = sortRows(users, loggedInUser);
  return sortedUsers?.map((user: any) => {
    return {
      id: user?.id,
      email: user?.email || '',
      fullName: user?.attributes?.full_name?.[0] || '',
      accountType: user?.attributes?.account_type?.[0],
      userName: user?.username || '',
      status: user?.attributes?.status?.[0] || '',
      userGroups: user?.groups || []
    };
  });
};

const requiredRule = {
  isValid: (value) => value !== '',
  errorText: 'This field is required'
};
const validationRules = {
  accountType: requiredRule
};

export const validate = (changed) => ({
  ...Object.keys(changed)
    .filter((key) => key === 'accountType')
    .reduce((acc, field) => {
      const isValid = validationRules[field].isValid(changed[field]);
      return {
        ...acc,
        [field]: {
          isValid,
          error: !isValid && validationRules[field].errorText
        }
      };
    }, {})
});

export const isRowValid = (changedObject) => {
  return Object.keys(placeholderRow).every((key) => {
    if (
      key === 'id' ||
      key === 'userName' ||
      key === 'fullName' ||
      key === 'userGroups' ||
      key === 'email'
    )
      return true;
    return (
      changedObject?.hasOwnProperty(key) &&
      validationRules?.[key]?.isValid(changedObject[key] || '')
    );
  });
};

export const sortRows = (rows, loggedInUser) => {
  let tempRows = [...rows];
  const index =
    tempRows.findIndex((row) => {
      return row.id === loggedInUser?.id;
    }) || -1;

  if (index !== -1) {
    let [user] = tempRows.splice(index, 1);
    tempRows.unshift(user);
  }
  return tempRows;
};
export const getFilteredList = (filtered: StringKeys) => {
  let filterObj = {};
  Object.keys(filtered).forEach((key) => {
    if (key === 's') {
      filterObj[key] = filtered[key];
    } else if (key === 'account_type_id' && filtered[key].value !== 'all') {
      filterObj[key] = filtered[key]?.id;
    } else if (key === 'status') {
      filterObj[key] = filtered[key]?.code;
    }
  });
  return filterObj;
};

export const compareFunction = (a, b, loggedInUser) => {
  if (
    a.email === placeholderRow.email ||
    b.email === placeholderRow.email ||
    a.email === loggedInUser?.email ||
    b.email === loggedInUser?.email
  ) {
    return 0;
  }

  return a?.email?.localeCompare(b?.email) || 0;
};

export const columnSortingExtensions: SortingState.ColumnExtension[] = [
  {
    columnName: USER_COLUMNS.NAME,
    sortingEnabled: true
  },
  {
    columnName: USER_COLUMNS.USER_GROUPS,
    sortingEnabled: false
  },
  {
    columnName: USER_COLUMNS.STATUS,
    sortingEnabled: false
  },
  {
    columnName: USER_COLUMNS.ACCOUNT_TYPE,
    sortingEnabled: false
  }
];

export const integratedSortingColumnExtensions = (
  loggedInUser
): IntegratedSorting.ColumnExtension[] => {
  return [
    {
      columnName: USER_COLUMNS.NAME,
      compare: (a, b) => compareFunction(a, b, loggedInUser)
    }
  ] as IntegratedSorting.ColumnExtension[];
};
export const defaultSorting: Sorting[] = [
  { columnName: USER_COLUMNS.NAME, direction: 'asc' }
];

export const getAccountTypeDetails = (
  accountTypes: IAccountType[],
  t: any
): IAccountType[] => {
  let _accountTypes = accountTypes;
  return _accountTypes?.map((item) => ({
    ...item,
    secondaryText: t(ACCOUNT_TYPE_DESCRIPTION[item.code])
  }));
};
