import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { PropsWithChildren } from 'react';

import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch } from 'react-redux';
import { ReactComponent as ChevronLeft } from 'src/assets/ChevronLeft.svg';
import { ReactComponent as CloseIcon } from 'src/assets/close-icon.svg';
import { handleInitiateAnalysisBackState } from 'src/redux/slices/docAnalyst';

type Props = PropsWithChildren<{
  isOpen: boolean;
  setIsOpen: (arg: boolean) => void;
  onClose: () => void;
  title: React.ReactNode;
  showBackButton?: boolean;
}>;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({
  isOpen,
  setIsOpen,
  onClose,
  showBackButton = false,
  title,
  children
}: Props) {
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose();
  };

  const handleBackButton = () => {
    dispatch(handleInitiateAnalysisBackState({}));
  };
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '0px !important',
            padding: '30px',
            overflow: 'hidden'
          }
        }}
        TransitionComponent={Transition}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 5,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon width={'28px'} height={'28px'} />
        </IconButton>
        <Box display={'flex'} alignItems={'center'} gap={'4px'}>
          {showBackButton && (
            <Button
              sx={{ cursor: 'pointer', padding: 0, minWidth: 0 }}
              onClick={handleBackButton}
            >
              <ChevronLeft />
            </Button>
          )}
          {title}
        </Box>
        <Box display={'flex'} flexDirection={'column'} flex={1}>
          {children}
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
