import {
  CustomPaging,
  DataTypeProvider,
  PagingState,
  SelectionState
} from '@devexpress/dx-react-grid';
import {
  PagingPanel,
  Table,
  Grid as TableGrid,
  TableHeaderRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompactTableCellHeader,
  CustomTableRowHighlight,
  Root,
  TableRowCell,
  TableRowHeader
} from 'src/components/Grid/styles';
import GridContainer from 'src/components/GridContainer';
import { getColumnExtensionsWidth } from 'src/content/Documents/utils/utils';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { PAGE_SIZES } from './utils';

type SingleSelectionGridProps = {
  rows: any[];
  columns: any[];
  columnsExtensions: any;
  selection: any;
  setSelection: (selection: any) => void;
  getRowId: any;
  formatters?: any[];
  totalWidth?: number;
  onSelect?: (id: number | string) => void;
  page: any;
  pageSize: any;
  setPage: any;
  setPageSize: any;
  totalRowsCount: number;
};

function SingleSelectionGrid({
  rows,
  columns,
  selection,
  setSelection,
  columnsExtensions,
  getRowId,
  formatters = [],
  totalWidth = 768, // TODO
  onSelect = () => {},
  page,
  pageSize,
  setPage,
  setPageSize,
  totalRowsCount
}: SingleSelectionGridProps) {
  const { t, i18n } = useTranslation();

  const computedColumnExtensions = useMemo(() => {
    return [...getColumnExtensionsWidth(columnsExtensions, totalWidth || 0)];
  }, [totalWidth]);

  const translatedColumns = useMemo(
    () =>
      columns.map((col) => ({
        ...col,
        title: t(col.title)
      })),
    [i18n.language]
  );

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
    setPage(0);
  }, []);

  const handleSelection = useCallback(
    (selection: (string | number)[]) => {
      const id =
        selection.length === 0 ? null : selection[selection.length - 1];
      setSelection(id ? [id] : []);
      onSelect(id);
    },
    [onSelect]
  );

  const customFormatters = useMemo(() => {
    return (
      <>
        {formatters.map((f, index) => (
          <DataTypeProvider
            for={f.for}
            formatterComponent={f.formatterComponent}
            key={index}
          />
        ))}
      </>
    );
  }, [formatters]);

  return (
    <GridContainer minHeight={'100%'} length={1} height='1px'>
      <TableGrid
        rows={rows}
        columns={translatedColumns}
        getRowId={getRowId}
        rootComponent={Root}
      >
        {customFormatters}

        <Table
          cellComponent={TableRowCell}
          columnExtensions={computedColumnExtensions}
        />
        <TableHeaderRow
          rowComponent={TableRowHeader}
          cellComponent={CompactTableCellHeader}
        />

        <SelectionState
          selection={selection}
          onSelectionChange={handleSelection}
        />
        <TableSelection
          selectByRowClick
          highlightRow
          showSelectionColumn={false}
          rowComponent={CustomTableRowHighlight}
        />
        <PagingState
          currentPage={page}
          onCurrentPageChange={setPage}
          pageSize={pageSize}
          onPageSizeChange={handlePageSizeChange}
        />
        <CustomPaging totalCount={totalRowsCount} />
        <PagingPanel
          pageSizes={PAGE_SIZES}
          messages={{
            rowsPerPage: t(T.rowsPerPage)
          }}
        />
      </TableGrid>
    </GridContainer>
  );
}

export default SingleSelectionGrid;
