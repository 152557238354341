import { Box, List } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useContext, useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { startChat } from 'src/content/Documents/utils/gridUtils';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { RootState, useSelector } from 'src/redux/store';
import { PureLightTheme } from 'src/theme/schemes/PureLightTheme';
import History from '../History';
import SidebarMenu from '../Menu/index';
import { MenuWrapper, SubMenuWrapper } from '../styles';
import SidebarFavouriteItem from './item';
import getFavouriteItems, { FavouriteItem, FavouriteItems } from './items';

const renderSidebarFavouriteItems = ({
  item,
  path,
  handleStartChatClick,
  handleFavouriteClick,
  t
}: {
  item: FavouriteItem;
  path: string;
  handleStartChatClick: (
    id: number,
    name: string,
    workspaceName?: string
  ) => void;
  handleFavouriteClick: (id: number, isFavourite: boolean) => void;
  t: TFunction<'translation', undefined>;
}): JSX.Element => {
  const key = item.name;

  const exactMatch = item?.link
    ? !!matchPath(
        {
          path: item?.link,
          end: true
        },
        path
      )
    : false;

  return (
    <SubMenuWrapper>
      <List component="div">
        {[
          <SidebarFavouriteItem
            key={key}
            active={exactMatch}
            name={item.name}
            workspaceName={item?.workspaceName}
            link={item?.link}
            icon={item?.icon}
            id={item.id}
            handleStartChatClick={handleStartChatClick}
            handleFavouriteClick={handleFavouriteClick}
            t={t}
          />
        ]}
      </List>
    </SubMenuWrapper>
  );
};

function SidebarMidSection() {
  const location = useLocation();
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const { userAppPermissions, userFavourites, isFavouritesFetching } =
    useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const { isUpdatingFavourite, updateFavourite } = useContext(DocumentsContext);

  const menuItems = useMemo(
    () => getFavouriteItems(userAppPermissions, userFavourites || []),
    [userFavourites]
  );

  const listHeight = useMemo(
    () =>
      `${
        userFavourites?.length && userFavourites.length <= 3
          ? userFavourites.length * 44.5
          : 133.5
      }px`,
    [userFavourites]
  );

  const handleStartChatClick = async (
    id: number,
    name: string,
    workspaceName?: string
  ) => {
    const routeState = location?.state?.documentContext;
    await startChat({
      documentContext: {
        id,
        name,
        workspaceName
      },
      dispatch,
      navigate,
      selectedChat,
      routeState
    });
  };

  const handleFavouriteClick = useCallback(
    (id: number, isFavourite: boolean) => {
      if (isUpdatingFavourite || isFavouritesFetching) return;

      dispatch(setIsFavouritesFetching(true));
      updateFavourite(id, !isFavourite);
    },
    [dispatch, isUpdatingFavourite, isFavouritesFetching]
  );

  return (
    <>
      <History />
      <SidebarMenu />
      {userFavourites?.length ? (
        <Box height={listHeight}>
          <Box
            position={'absolute'}
            height={listHeight}
            width={'100%'}
            sx={{
              overflowY: 'auto',
              paddingLeft: '10px'
            }}
          >
            {menuItems.map((section: FavouriteItems) => (
              <MenuWrapper key={section?.item?.link}>
                <List component="div">
                  {renderSidebarFavouriteItems({
                    item: section.item,
                    path: location.pathname,
                    handleStartChatClick: handleStartChatClick,
                    handleFavouriteClick: handleFavouriteClick,
                    t: t
                  })}
                </List>
              </MenuWrapper>
            ))}
          </Box>
        </Box>
      ) : null}
    </>
  );
}

export default SidebarMidSection;
