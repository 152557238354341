import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  FilterComponents,
  FilterSelect,
  FilterElementTypes,
  SelectItem,
  FilterDatasource
} from 'src/types/custom_component';
import {
  Grid,
  InputAdornment,
  styled,
  useTheme,
  Popover,
  Button,
  Badge,
  Typography,
  Link,
  IconButton,
  Box
} from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TextField from '../TextField';
import { StringKeys } from 'src/types/base';
import {
  SelectAllItem,
  getDefaultValues,
  getSelectedFilters,
  getValues
} from './utils';
import CustomAutoComplete from 'src/components/CustomAutoComplete';
import { useTranslation } from 'react-i18next';
import SearchableAutoComplete from '../SearchableAutoComplete';
import Select from 'src/components/Select';
import { Clear, Done, FilterList } from '@mui/icons-material';

const GridContainer = styled(Grid)`
  width: 100%;
  padding: 15px;
  font: #000;
`;

const StyledLink = styled(Link)({
  color: 'primary',
  underline: 'always',
  fontWeight: 'bold',
  cursor: 'pointer'
});

const FilterPopup = ({
  componentsValue,
  setComponentValue,
  hideFilterButton = false,
  ...props
}: FilterProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState<StringKeys>({});
  const [dataSources, setDataSources] = useState<FilterDatasource>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover-actions' : undefined;

  const searchComponent = useMemo(() => {
    return props.elementsList.find(
      (element) => element.type === FilterElementTypes.Text
    );
  }, [props.elementsList]);

  const handleOpenPopup = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleClose = useCallback((e) => {
    e.stopPropagation();
    setAnchorEl(null);
  }, []);

  const isFiltersSelected = useMemo((): boolean => {
    const { isFilterSelected } = getSelectedFilters(componentsValue);
    return isFilterSelected;
  }, [componentsValue]);

  useEffect(() => {
    let componentsValue: StringKeys = {};
    let textInputs: StringKeys = {};
    let dataSources: FilterDatasource = {};

    props.elementsList.forEach((item: FilterComponents) => {
      componentsValue[item['dataField']] = getDefaultValues(item);
      if (item.type === FilterElementTypes.Text) {
        textInputs[item['dataField']] = item['defaultValue'] || '';
      }

      if (
        item.type === FilterElementTypes.AutoComplete ||
        item.type === FilterElementTypes.MultiAutoComplete
      ) {
        dataSources[item['dataField']] = item.filteredDatasource
          ? item.filteredDatasource
          : item.datasource || [];
      }
      if (item.type === FilterElementTypes.Select) {
        componentsValue[item['dataField']] =
          item['defaultValue'] || SelectAllItem;
      }
    });
    setComponentValue(componentsValue);
    setTextValue(textInputs);
    setDataSources(dataSources);

    setLoading(false);
  }, [props.elementsList]);

  useEffect(() => {
    if (!isLoading) {
      executeSearch();
    }
  }, [componentsValue, isLoading]);

  const executeSearch = () => {
    let filteringKeys: { [z: string]: any } = {};

    getValues(componentsValue, filteringKeys, props.elementsList);
    props.onSearch(filteringKeys);
  };

  const onClickReset = () => {
    let initialObject: { [name: string]: string | boolean } = {};
    let initialText: { [name: string]: string } = {};
    let defaultState: { [name: string]: string | boolean } = {};
    const defaults: { [name: string]: string } = {};
    let dataSources: FilterDatasource = {};
    Object.keys(componentsValue).forEach((key: string) => {
      let item: FilterComponents | undefined = props.elementsList.find(
        (item) => item.dataField === key
      );
      if (item) {
        initialObject[key] = getDefaultValues(item, props.forceReset);
        if (getDefaultValues(item, props.forceReset)) {
          defaults[key] = getDefaultValues(item, props.forceReset);
        }
        if (item.type === FilterElementTypes.Text) {
          initialText[key] = '';
        }
        if (
          item.type === FilterElementTypes.MultiAutoComplete ||
          item.type === FilterElementTypes.AutoComplete
        ) {
          dataSources[item['dataField']] = item.filteredDatasource
            ? item.filteredDatasource
            : item.datasource || [];
        }
        if (item.type === FilterElementTypes.Select) {
          dataSources[item['dataField']] = item.datasource || [];
        }
      }
    });
    getValues(defaults, defaultState, props.elementsList);
    defaultState = { ...defaultState, s: componentsValue['s'] };
    initialObject = { ...initialObject, s: componentsValue['s'] };
    setComponentValue(initialObject);
    setDataSources(dataSources);
    props.onReset(defaultState);
  };

  const updateState = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: any = event.target.value;
    let type: string = getItemType(event.target.id);

    setComponentValue((prevState: any) => {
      return { ...prevState, [event.target.id]: value };
    });
  };

  const clearSearch = (key) => {
    setTextValue({ [key]: '' });
    setComponentValue((prevState: any) => {
      return { ...prevState, [key]: '' };
    });
  };

  const onChangeSelect = (event: any, newValue: FilterSelect, id: string) => {
    setComponentValue((prevState: any) => {
      return {
        ...prevState,
        [id]: newValue
      };
    });
  };

  const getItemType = (key: string): FilterElementTypes | '' => {
    let item: FilterComponents | undefined = props.elementsList.find(
      (item) => item.dataField === key
    );
    if (item) {
      return item.type;
    }
    return '';
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === 13) {
      updateState(e);
    }
  };

  const renderInputField = (item: FilterComponents) => {
    let InputProps = item.showIcon
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <SearchOutlinedIcon color="disabled" />
            </InputAdornment>
          ),
          endAdornment: !!textValue?.s?.length && (
            <IconButton
              sx={{ padding: 0 }}
              onClick={() => clearSearch(item.dataField)}
            >
              <Clear fontSize="small" />
            </IconButton>
          )
        }
      : {};

    const textProps = {
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
        setTextValue((prevState: any) => {
          return { ...prevState, [event.target.id]: event.target.value };
        });
        if (!event.target.value.length) {
          updateState(event);
        }
      },
      inputProps: {
        onKeyDown: handleKeyDown
      },
      value: textValue[item['dataField']]
    };

    return (
      <TextField
        disabled={props.disableFilters}
        type={item.type}
        id={item.dataField}
        name={item.name}
        variant="outlined"
        size="small"
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '43px'
          }
        }}
        style={{
          width: item.elementWidth
        }}
        placeholder={t(item.placeholder)}
        InputLabelProps={{ style: { fontSize: 12 } }}
        InputProps={InputProps}
        {...textProps}
      />
    );
  };

  const renderMultiAutoComplete = (item: FilterComponents) => {
    const handleChange = (event: any, newValue: SelectItem) => {
      setComponentValue((prevState: any) => {
        return {
          ...prevState,
          [item.dataField]: newValue
        };
      });
    };
    return (
      <Box>
        <CustomAutoComplete
          id={item.dataField}
          dataSource={[...dataSources[item.dataField]] || []}
          onChange={handleChange}
          width={item.elementWidth}
          value={componentsValue[item.dataField]}
          sx={{
            width: item.elementWidth
          }}
          placeholder={t(item.placeholder)}
        />
      </Box>
    );
  };

  const renderAutoComplete = (item: FilterComponents) => {
    return (
      <Box>
        <CustomAutoComplete
          id={item.dataField}
          dataSource={[...(dataSources[item.dataField] || [])]}
          onChange={(event, newValue: SelectItem) =>
            onChangeSelect(event, newValue, item.dataField)
          }
          width={item.elementWidth}
          sx={{
            width: item.elementWidth
          }}
          value={componentsValue?.[item.dataField]}
          placeholder={t(item.placeholder)}
          multiple={false}
          disableSearch={item.disableSearch}
        />
      </Box>
    );
  };

  const renderSearchAutoComplete = (item: FilterComponents) => {
    const handleChange = (e, newValue) =>
      setComponentValue((prevState: any) => {
        return {
          ...prevState,
          [item.dataField]: newValue
        };
      });
    return (
      <SearchableAutoComplete
        searchApi={item.searchApi}
        placeholder={t(item.placeholder)}
        optionLabel={item.labelKey}
        onChange={handleChange}
        valueKey={item.valueKey}
        sx={{
          width: item.elementWidth
        }}
        value={componentsValue[item.dataField] || null}
      />
    );
  };

  const renderSelect = (item: FilterComponents) => {
    const handleChange = (e: any) =>
      setComponentValue((prevState: any) => {
        return {
          ...prevState,
          [item.dataField]: [SelectAllItem, ...item.datasource].find(
            (item) => item.id === e.target.value
          )
        };
      });
    return (
      <Select
        id={item.dataField}
        value={componentsValue?.[item.dataField]?.id}
        width={item.elementWidth}
        onChange={handleChange}
        dataSource={[SelectAllItem, ...item.datasource] || []}
      />
    );
  };
  return isLoading ? null : (
    <>
      <Grid item>{searchComponent && renderInputField(searchComponent)}</Grid>
      {!hideFilterButton && (
        <Grid item>
          <Badge
            invisible={!isFiltersSelected}
            badgeContent={<Done sx={{ fontSize: '10px' }} />}
            color="success"
          >
            <Button
              sx={{
                minWidth: 0,
                padding: '8px 12px',
                border: '1px solid'
              }}
              disabled={props.disableFilters}
              variant="outlined"
              color="primary"
              onClick={handleOpenPopup}
            >
              <FilterList />
            </Button>
          </Badge>
          <Popover
            id={id}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <GridContainer container style={{ padding: 20 }}>
              <Grid
                display={'flex'}
                flexDirection={'column'}
                container
                spacing={0.5}
                style={{ width: 'calc(100% - 80px)' }}
              >
                {props.Button && (
                  <Grid item style={{ paddingBottom: 6 }}>
                    {props.Button}
                  </Grid>
                )}
                {props.elementsList.map(
                  (item: FilterComponents, index: number) => {
                    return item.type === FilterElementTypes.AutoComplete ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderAutoComplete(item)}
                      </Grid>
                    ) : item.type === FilterElementTypes.MultiAutoComplete ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderMultiAutoComplete(item)}
                      </Grid>
                    ) : item.type === FilterElementTypes.SearchAutoComplete ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderSearchAutoComplete(item)}
                      </Grid>
                    ) : item.type === FilterElementTypes.Select ? (
                      <Grid item key={index} style={{ paddingBottom: 2 }}>
                        {item.showLabel && (
                          <Typography
                            color={'primary'}
                            sx={{ marginBottom: '4px' }}
                          >
                            {item.label}
                          </Typography>
                        )}
                        {renderSelect(item)}
                      </Grid>
                    ) : null;
                  }
                )}
                <Grid item alignSelf={'end'}>
                  <Typography variant="body2" align="center">
                    <StyledLink onClick={onClickReset}>Clear Filter</StyledLink>
                  </Typography>
                </Grid>
              </Grid>
            </GridContainer>
          </Popover>
        </Grid>
      )}
    </>
  );
};

type FilterProps = {
  onSearch?: (selectedFields: { [z: string]: any }) => any;
  onReset: (initialObject: any) => void;
  forceReset?: boolean;
  elementsList: FilterComponents[];
  Button?: any;
  showFilters?: boolean;
  disableFilters?: boolean;
  componentsValue?: StringKeys;
  setComponentValue?: React.Dispatch<React.SetStateAction<StringKeys>>;
  hideFilterButton?: boolean;
};
export default FilterPopup;
