import { Add } from '@mui/icons-material';
import { TableCellHeader } from 'src/components/Grid/styles';
import { Box, TableRow, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { USER_APP_PERMISSIONS } from 'src/types/enum';

interface IInviteUsersRowProps {
  onClick: () => void;
}
const InviteUsersRow = ({ onClick }: IInviteUsersRowProps) => {
  const { userAppPermissions } = useSelector((state: RootState) => state.data);

  const theme = useTheme();
  return (
    <TableRow
      sx={{
        '&:hover': {
          cursor: 'pointer'
        }
      }}
      onClick={onClick}
    >
      {userAppPermissions?.[USER_APP_PERMISSIONS.DELETE_USERS]?.value && (
        <TableCellHeader
          colSpan={1}
          style={{
            backgroundColor: theme.colors.alpha.trueWhite[100]
          }}
        />
      )}
      <TableCellHeader
        colSpan={5}
        style={{
          textAlign: 'center',
          backgroundColor: theme.colors.alpha.trueWhite[100]
        }}
      >
        <Box width={'100%'} display={'flex'}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            style={{
              borderRadius: '50%',
              borderStyle: 'dashed',
              borderWidth: '2px',
              borderColor: theme.colors.secondary.dark,
              width: '40px',
              height: '40px'
            }}
          >
            <Add
              style={{
                color: theme.colors.secondary.dark
              }}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            justifyItems={'flex-start'}
            marginLeft={1}
          >
            <Typography
              variant={'body1'}
              fontWeight={'bold'}
              style={{
                color: theme.colors.secondary.dark
              }}
            >
              Invite your teammates
            </Typography>
            <Typography variant={'body2'}>
              Enable your teammates with quick access to critical knowledge
            </Typography>
          </Box>
        </Box>
      </TableCellHeader>
    </TableRow>
  );
};

export default InviteUsersRow;
