import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

interface CitationButtonProps {
  props?: {
    isDownloadingFile?: boolean;
    selectedId?: string;
    buttonId?: string;
  };
}
export const CitationButton = styled(LoadingButton)<CitationButtonProps>(
  ({ props: { isDownloadingFile, selectedId, buttonId } }) => `
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    height: 19px;
    min-width: 16px;
    border-radius: 6px;
    padding: 0 4px;
    color: rgba(34, 51, 84, 0.70);
    position: relative;
    bottom: 3px;
    border: ${
      isDownloadingFile && selectedId === buttonId
        ? 0
        : '1px solid rgba(34, 51, 84, 0.70)'
    };
  `
);
