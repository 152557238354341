import {
  useTheme,
  Autocomplete,
  TextField,
  Typography,
  alpha,
  Paper
} from '@mui/material';
import { StyledPopper } from './styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCallback } from 'react';

interface CustomAutoCompleteProps {
  onChange: (event: any, value: any) => void;
  label?: string;
  placeholder?: string;
  dataSource: any[];
  optionLabel?: string;
  value: any;
  freeSolo?: boolean;
  hideClearIcon?: boolean;
  multiple?: boolean;
  customLabel?: boolean;
  disableSearch?: boolean;
  limitTags?: number;
  popperWidth?: string | number;
  readOnly?: boolean;
  optionStyles?: boolean;
  chipSize?: 'small' | 'medium';
  popperMaxHeight?: string | number;
  disabledInputStroke?: string;
  [z: string]: any;
}

const CustomAutoComplete = ({
  onChange,
  label = '',
  customLabel = false,
  optionLabel = '',
  placeholder = '',
  dataSource,
  value,
  freeSolo = false,
  hideClearIcon = false,
  multiple = true,
  disableSearch = false,
  limitTags = 1,
  popperWidth = null,
  readOnly = false,
  optionStyles,
  size = 'small',
  chipSize = 'small',
  popperMaxHeight,
  disabledInputStroke = '#223354',
  ...rest
}: CustomAutoCompleteProps) => {
  const theme = useTheme();
  const { sx, ...restProps } = rest;

  const PopperComponent = useCallback((props) => {
    return (
      <StyledPopper
        optionStyles={optionStyles}
        maxHeight={popperMaxHeight}
        {...props}
      />
    );
  }, []);

  const PaperComponent = useCallback(
    (props) => (
      <Paper {...props} style={{ ...props.style, width: popperWidth }} />
    ),
    []
  );

  return (
    <>
      {customLabel && (
        <Typography
          sx={{
            marginBottom: '5px',
            marginLeft: '2px',
            color: '#575757',
            fontSize: '13px'
          }}
          gutterBottom
        >
          {label}
        </Typography>
      )}
      <Autocomplete
        multiple={multiple}
        id={label}
        size={size}
        options={dataSource}
        disableClearable={hideClearIcon}
        freeSolo={freeSolo}
        getOptionLabel={(option: any) =>
          typeof option === 'string'
            ? option
            : optionLabel
            ? option[optionLabel]
            : option?.name
        }
        value={value}
        limitTags={limitTags}
        onChange={onChange}
        PopperComponent={PopperComponent}
        sx={{
          fontSize: theme.typography.pxToRem(12),
          position: 'relative',
          borderRadius: theme.general.borderRadius,
          ...sx
        }}
        autoHighlight={!disableSearch}
        ChipProps={{
          size: chipSize
        }}
        componentsProps={{
          clearIndicator: {
            sx: {
              mr: 0.01,
              color: theme.colors.alpha.black[100],
              backgroundColor: 'transparent',
              '&:hover': {
                color: theme.colors.alpha.black[100],
                backgroundColor: theme.colors.alpha.black[10]
              }
            }
          }
        }}
        PaperComponent={PaperComponent}
        renderInput={(params) => (
          <TextField
            {...params}
            label={customLabel ? null : null}
            maxRows={1}
            disabled={disableSearch}
            size={size}
            sx={(theme) => ({
              ...(disableSearch && {
                '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
                  {
                    color: 'green',
                    borderColor: alpha(theme.colors.alpha.black[100], 0.23)
                  },
                input: {
                  '&.Mui-disabled': {
                    textFillColor: disabledInputStroke
                  }
                }
              })
            })}
            placeholder={value?.length ? '' : placeholder}
            InputProps={{ ...params.InputProps, readOnly: readOnly }}
            InputLabelProps={{ shrink: customLabel ? false : true }}
          />
        )}
        popupIcon={<KeyboardArrowDownIcon fontSize={size} />}
        {...restProps}
      />
    </>
  );
};

export default CustomAutoComplete;
