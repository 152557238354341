import { useCallback, useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography, IconButton, Tooltip } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

const MultiTabsPdfViewer = ({
  pdfDocuments,
  selectedDocumentIndex,
  onDocumentRemove,
  resourceId
}) => {
  const [pdfTabs, setPdfTabs] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    setPdfTabs(
      pdfDocuments.map((pdfDoc) => ({
        ...pdfDoc,
        pdfUrl: `${window.URL.createObjectURL(pdfDoc.pdfData)}#page=${
          pdfDoc.pageNumber
        }`
      }))
    );
  }, [pdfDocuments]);

  useEffect(() => {
    setTabIndex(selectedDocumentIndex);
  }, [selectedDocumentIndex]);

  const handleTabChange = (_, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleCloseIcon = useCallback(
    (index) => {
      if (pdfTabs[index]?.id) {
        onDocumentRemove({
          resourceId: +resourceId,
          documentIndex: index
        });
        if (index === tabIndex && pdfTabs.length > 1) {
          setTabIndex(0);
        }
      }
    },
    [resourceId, pdfTabs, tabIndex]
  );

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <TabContext value={`${tabIndex}`}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={'auto'}
        >
          {pdfTabs.map((pdfTab, index) => (
            <Tab
              key={pdfTab.uuid || `tab-${index}`}
              value={index}
              label={
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  width={'100%'}
                  sx={{ overflow: 'hidden' }}
                >
                  <Tooltip
                    title={pdfTab.name}
                    placement={'bottom'}
                    componentsProps={{
                      tooltip: {
                        sx: { backgroundColor: 'rgba(97, 97, 97, 0.95)' }
                      }
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        width: '200px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                      }}
                    >
                      {pdfTab.name}
                    </Typography>
                  </Tooltip>
                  {tabIndex === index && (
                    <IconButton onClick={() => handleCloseIcon(index)}>
                      <CloseIcon htmlColor="primary" />
                    </IconButton>
                  )}
                </Box>
              }
            />
          ))}
        </Tabs>
        {pdfTabs.map((pdfTab, index) => (
          <TabPanel
            style={{
              padding: 0,
              width: '-webkit-fill-available',
              height: 'calc(100% - 40px)'
            }}
            value={`${index}`}
            key={pdfTab.uuid || `doc-${index}`}
          >
            <iframe
              src={`${pdfTab.pdfUrl}`}
              style={{
                height: '100%',
                width: '100%'
              }}
            />
          </TabPanel>
        ))}
      </TabContext>
    </div>
  );
};

export default MultiTabsPdfViewer;
