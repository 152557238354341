import { useCallback, useEffect, useMemo, useState } from 'react';
import keyBy from 'lodash/keyBy';
import SingleSelectionGrid from '../../utils/SingleSelectionGrid';
import {
  getRowId,
  datasetColumnExtensions,
  datasetColumns,
  PAGE_SIZES,
  datasetFormatters
} from '../../utils/utils';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { useLazyGetDatasetsQuery } from 'src/services/api';
import { useDispatch, useSelector } from 'src/redux/store';
import { closeSelection, selectDataset } from 'src/redux/slices/docAnalyst';
import Loader from 'src/components/Loader';
import { ErrorContext } from 'src/utils/errorMappings';

interface DatasetSelectionProps {
  selectedId: number;
  width: number;
}

function DatasetSelection({ selectedId, width }: DatasetSelectionProps) {
  const [selection, setSelection] = useState<number[]>(
    selectedId ? [selectedId] : []
  );
  const dispatch = useDispatch();
  const { searchQuery } = useSelector(
    (state) => state.docAnalyst.initiateAnalysis
  );

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZES[0]);

  const [fetchDatasets, { isLoading, isFetching, data }] = useLazyQuery({
    api: useLazyGetDatasetsQuery,
    errorContext: ErrorContext.DOC_ANALYST
  });

  useEffect(() => {
    fetchDatasets({
      params: {
        params: {
          p: page,
          ps: pageSize,
          show_valid: true,
          s: searchQuery
        }
      }
    });
  }, [searchQuery, page, pageSize]);

  const idKeyMap = useMemo(
    () => (Array.isArray(data?.data) ? keyBy(data?.data, 'id') : {}),
    [data]
  );

  const onSelect = useCallback(
    (selectedId) => {
      const { id, name } = idKeyMap[selectedId] || {};
      dispatch(selectDataset(id ? { id, name } : null));
      if (id) {
        dispatch(closeSelection());
      }
    },
    [idKeyMap]
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SingleSelectionGrid
      rows={data?.data || []}
      columns={datasetColumns}
      selection={selection}
      setSelection={setSelection}
      getRowId={getRowId}
      columnsExtensions={datasetColumnExtensions}
      onSelect={onSelect}
      totalWidth={width}
      page={page}
      setPage={setPage}
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalRowsCount={data?.rc}
      formatters={datasetFormatters}
    />
  );
}

export default DatasetSelection;
