import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@mui/material';
import { PropsWithChildren } from 'react';

import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'src/assets/close-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { SxProps } from '@mui/system';

type IProps = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
  dialogTitle: string;
  handleConfirmation: () => void;
  closeModal?: () => void;
  sxDialog?: SxProps;
  maxWidth?: any;
  confirmationButtonText?: string;
  cancelButtonText?: string;
  disableButton?: boolean;
}>;

const DialogLayout = ({
  isOpen,
  onClose,
  handleConfirmation,
  closeModal = onClose,
  dialogTitle,
  confirmationButtonText = T.confirm,
  cancelButtonText = T.cancel,
  sxDialog = {},
  maxWidth = 'sm',
  children,
  disableButton
}: IProps) => {
  const { t } = useTranslation();

  const handleClose = (e, reason = '') => {
    if (reason !== 'backdropClick') onClose();
  };

  return (
    <Dialog
      open={isOpen}
      maxWidth={maxWidth}
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.general.borderRadiusXl,
          zIndex: 1500
        })
      }}
      disableEscapeKeyDown
      onClose={closeModal}
      sx={sxDialog}
    >
      <DialogTitle>
        <Typography fontSize={'26px'} fontWeight={500}>
          {t(dialogTitle)}
        </Typography>
      </DialogTitle>
      <IconButton
        onClick={closeModal}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="primary"
        >
          {t(cancelButtonText)}
        </Button>
        <Button
          disabled={disableButton}
          onClick={handleConfirmation}
          variant="contained"
          size="small"
          color="secondary"
        >
          {t(confirmationButtonText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogLayout;
