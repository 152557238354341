import {
  IntegratedSorting,
  Sorting,
  SortingState
} from '@devexpress/dx-react-grid';
import { StringKeys } from 'src/types/base';
import { caseInsensitiveSort } from 'src/utils/utils';

export const COLUMNS = {
  NAME: 'name',
  PERMISSIONS: 'permissions'
};

export const RoleColumns = [
  { name: COLUMNS.NAME, title: 'Name', getCellValue: (row) => row.name },
  {
    name: COLUMNS.PERMISSIONS,
    title: 'Permissions',
    getCellValue: (row) => row.permissions
  }
];

export const columnExtensions = [
  {
    columnName: COLUMNS.NAME,
    width: '400px',
    wordWrapEnabled: true
  },
  {
    columnName: COLUMNS.PERMISSIONS,
    wordWrapEnabled: true
  }
];

export const AllowedPattern = /^[ A-Za-z0-9_/_-]*$/;

export const sortArray = (array: any, key: string) => {
  let sortedArray: any = [...array];
  return sortedArray.sort((a: any, b: any) =>
    a[key].toLowerCase().localeCompare(b[key].toLowerCase())
  );
};

export const RESOURCE_ROLE_TEMPLATE = 'Resource Role Template';

export const getFilteredList = (filtered: StringKeys) => {
  let filterObj = {};
  Object.keys(filtered).forEach((key) => {
    if (key === 's') {
      filterObj[key] = filtered[key];
    }
  });
  return filterObj;
};

export const defaultSorting: Sorting[] = [
  {
    columnName: COLUMNS.NAME,
    direction: 'asc'
  }
];

export const sortingColumnExtensions: SortingState.ColumnExtension[] = [
  {
    columnName: COLUMNS.NAME,
    sortingEnabled: true
  },
  {
    columnName: COLUMNS.PERMISSIONS,
    sortingEnabled: false
  }
];

export const integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[] =
  [
    {
      columnName: COLUMNS.NAME,
      compare: (a, b) => caseInsensitiveSort(a, b)
    }
  ];
