import { useCallback, useMemo, useRef, useState } from 'react';
import { Button, List, ListItem, Popover } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuWrapper, SubMenuWrapper } from '../styles';
import LanguageSwitcher from '../LanguageSwitcher';
import { authSignOut as signOut } from 'src/redux/slices/auth';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CustomListItemText from 'src/layouts/AccentHeaderLayout/Sidebar/ListItemText';
import kc from 'src/keycloak';
import { getPopupRoutes } from './utils';
import { LogoutOutlined, MoreVert } from '@mui/icons-material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { LOOKUP } from 'src/types/enum';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';

const BottomSection = () => {
  const { t } = useTranslation();
  const {
    user: { isNonTrialUser, accountType, name }
  } = useSelector((state: RootState) => state.auth);
  const { lookups } = useSelector((state: RootState) => state.data);
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>();
  const dispatch = useDispatch();

  const handleLogout = async (e): Promise<void> => {
    await dispatch(signOut());
  };

  const handleOpenIcon = useCallback(() => setIsMenuOpen(true), []);
  const handleCloseIcon = useCallback(() => setIsMenuOpen(false), []);
  const handleChangePassword = useCallback(() => {
    kc.login({
      action: 'UPDATE_PASSWORD'
    });
  }, []);

  const popupRoutes = useMemo(
    () =>
      getPopupRoutes(userAppPermissions, handleChangePassword, isNonTrialUser),
    [handleChangePassword, userAppPermissions, isNonTrialUser]
  );

  const userAccountType = useMemo(
    () =>
      lookups[LOOKUP.ACCOUNT_TYPE]?.find((item) => item.code === accountType),
    [accountType, lookups]
  );

  return (
    <MenuWrapper>
      <SubMenuWrapper>
        <List component="div" sx={{ padding: 0, margin: 0 }} disablePadding>
          <ListItem
            component="div"
            sx={{ padding: 0, margin: 0 }}
            disablePadding
          >
            <LanguageSwitcher />
          </ListItem>
          <ListItem component="div" disablePadding>
            <Button
              id={ONBOARDING_STEPS_TARGET.SIDEBAR_MORE_ACTION_BUTTON}
              disableRipple
              startIcon={
                <PersonOutlineOutlinedIcon
                  sx={{ fontSize: '1rem !important' }}
                />
              }
              endIcon={
                <MoreVert
                  id={ONBOARDING_STEPS_TARGET.SIDEBAR_MORE_ACTION_ICON}
                />
              }
              onClick={handleOpenIcon}
              sx={{
                color: 'white',
                display: 'flex',
                padding: '9px 16px',
                '& .MuiButton-startIcon': {
                  mr: '7px !important'
                }
              }}
              ref={buttonRef}
            >
              <CustomListItemText
                primaryText={name}
                secondaryText={userAccountType?.value}
                sx={{
                  paddingLeft: 0
                }}
              />
            </Button>
          </ListItem>
          <ListItem
            component="div"
            sx={{ padding: 0, margin: 0 }}
            disablePadding
          >
            <Button
              startIcon={
                <LogoutOutlined sx={{ fontSize: '16px !important' }} />
              }
              sx={{
                color: 'white'
              }}
              onClick={handleLogout}
            >
              <CustomListItemText primaryText={t(T.logout)} />
            </Button>
          </ListItem>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            PaperProps={{
              sx: () => ({
                border: 0,
                backgroundColor: 'primary.main'
              })
            }}
            open={isMenuOpen}
            anchorEl={buttonRef.current}
            onClose={handleCloseIcon}
          >
            <SubMenuWrapper sx={{ p: 0 }}>
              <List component="div" disablePadding>
                {popupRoutes.map(
                  ({ to, startIcon: StartIcon, onClick, title }, index) => (
                    <ListItem
                      key={index}
                      component="div"
                      sx={{ padding: 0, margin: 0 }}
                      disablePadding
                    >
                      <Button
                        id={
                          to === '/users'
                            ? ONBOARDING_STEPS_TARGET.USERS_NAVIGATION_LINK
                            : undefined
                        }
                        disableRipple
                        activeClassName=""
                        component={RouterLink}
                        to={to}
                        onClick={onClick || handleCloseIcon}
                        startIcon={StartIcon}
                        sx={{
                          color: 'white'
                        }}
                      >
                        {t(title)}
                      </Button>
                    </ListItem>
                  )
                )}
              </List>
            </SubMenuWrapper>
          </Popover>
        </List>
      </SubMenuWrapper>
    </MenuWrapper>
  );
};

export default BottomSection;
