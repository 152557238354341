import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import TooltipOnDisabled from 'src/components/TooltipOnDisabled';
import { DOC_ANALYST_TABS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  CustomList,
  CustomListItem,
  ImageWrapper,
  MenuOptionWrapper
} from './styles';

type optionProps = {
  title: string;
  description: string;
  navLink: string;
  ImageIcon: any;
  mainFeatures: string[];
};

const MenuItem: React.FC<optionProps> = ({
  title,
  description,
  ImageIcon,
  navLink,
  mainFeatures
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleItemClick = () => {
    navigate(navLink, { state: { selectedTab: DOC_ANALYST_TABS.ANALYSIS } });
  };

  return (
    <TooltipOnDisabled
      title={t(T.comingSoon)}
      showTooltip={!navLink}
      followCursor={true}
    >
      <MenuOptionWrapper showCursor={!!navLink} onClick={handleItemClick}>
        <ImageWrapper>{ImageIcon && <ImageIcon />}</ImageWrapper>
        <Box>
          <Typography fontSize="17px" fontWeight={500}>
            {t(title)}{' '}
            {!navLink && (
              <Typography
                fontSize="17px"
                fontWeight={500}
                display={'inline'}
                color={'error'}
              >{`(${t(T.comingSoon)})`}</Typography>
            )}
          </Typography>
          <Typography>{t(description)}</Typography>
          <Box marginTop={2}>
            <Typography>{t(T.mainFeatures)}</Typography>
            <CustomList>
              {mainFeatures.map((item, index) => (
                <CustomListItem key={index}>{item}</CustomListItem>
              ))}
            </CustomList>
          </Box>
        </Box>
      </MenuOptionWrapper>
    </TooltipOnDisabled>
  );
};

export default MenuItem;
