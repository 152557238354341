import { Chip } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const ChipItem = ({
  item,
  allowRemove,
  onTagRemove,
  icon,
  size = 'small',
  disabled,
  color = 'primary'
}: any) => {
  const onDelete = () => {
    onTagRemove(item.id);
  };
  const deleteProps = allowRemove
    ? {
        onDelete: onDelete,
        deleteIcon: allowRemove ? <CloseOutlinedIcon sx={{ ml: 14 }} /> : null
      }
    : {};

  return (
    <Chip
      label={item.text}
      color={color}
      disabled={disabled}
      sx={(theme) => ({
        p: 0.6
      })}
      size={size}
      icon={allowRemove ? null : icon}
      {...deleteProps}
    />
  );
};

export default ChipItem;
