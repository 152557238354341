import { StringKeys } from 'src/types/base';

export enum DOCUMENTS {
  Chats = 'chats',
  FEEDBACK = 'feedback',
  QUERIES = 'queries'
}

export const categorizeData = (data: StringKeys) => {
  let chatCount = 0;
  let queryCount = 0;
  let dislikes = 0;

  Object.keys(data || {}).forEach((key) => {
    const items = data[key];
    switch (key) {
      case DOCUMENTS.Chats:
        chatCount = items.reduce((acc, item) => acc + item.count, 0);
        break;
      case DOCUMENTS.QUERIES:
        queryCount = items.reduce((acc, item) => acc + item.count, 0);
        break;
      case DOCUMENTS.FEEDBACK:
        dislikes = items.find((item) => item.feedback === 'Thumbs Down')?.count;
        break;
      default:
        break;
    }
  });

  return { chatCount, queryCount, dislikes };
};
