import {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
  useRef
} from 'react';
import {
  EditingState,
  IntegratedSelection,
  IntegratedSorting,
  SelectionState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid as DataGrid,
  TableHeaderRow,
  TableEditRow,
  VirtualTable,
  TableEditColumn,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';
import Loader from 'src/components/Loader';
import {
  TableCellHeader,
  TableRowHeader,
  Root,
  CustomTableRow,
  EditActionCell
} from 'src/components/Grid/styles';
import GridContainer from 'src/components/GridContainer';
import { Box, Grid, useTheme } from '@mui/material';
import { setAlert } from 'src/redux/slices/snackbar';
import {
  useDeleteUserMutation,
  useLazyGetUsersQuery,
  useUpdateUserMutation
} from 'src/services/api';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { IUser } from '../types';
import {
  USER_COLUMNS,
  UserColumns,
  columnSortingExtensions,
  defaultSorting,
  generateRows,
  getFilteredList,
  isRowValid,
  userColumnExtensions,
  MIN_WIDTH_ALLOWED,
  placeholderRow,
  integratedSortingColumnExtensions
} from '../utils';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { AccountTypeProvider } from '../ColumnFormatters/AccountType';
import PageHeader from 'src/components/PageHeader';
import { TextProvider } from '../ColumnFormatters/Text';
import { StatusProvider } from '../ColumnFormatters/Status';
import { Getter, Plugin } from '@devexpress/dx-react-core';
import RowAction from './RowButtons';
import { getEditorComponent } from './editor';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import { ALERT, LOOKUP, USER_APP_PERMISSIONS } from 'src/types/enum';
import { getFilters } from './filter';
import { isEqual } from 'lodash';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import GridSkeleton from 'src/components/GridSkeleton';
import useMutation from 'src/hooks/useMutation';
import { ActionButtons } from './actionButtons';
import { ErrorContext } from 'src/utils/errorMappings';
import { getColumnExtensionsWidth } from 'src/content/Documents/utils/utils';
import { drawerWidth } from 'src/layouts/AccentHeaderLayout/styles';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { UserGroupProvider } from '../ColumnFormatters/UserGroups';
import InviteUsersRow from './InviteUsersRow';
import InviteUsersPopup from '../InviteUsersPopup';
import GridActions from 'src/components/GridActions';
import { toggleOnboarding } from 'src/redux/slices/onboarding';

const UserGridView = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sidebarToggle } = useContext(SidebarContext);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  const { lookups, isOnboarded } = useSelector(
    (state: RootState) => state.data
  );
  const [rows, setRows] = useState<IUser[]>([]);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isInviteUsersPopupOpen, setIsInviteUsersPopupOpen] =
    useState<boolean>(false);
  const [postUser, { isLoading: isUpdatingUser }] = useMutation<
    DataApiInputParams,
    StringKeys
  >({ api: useUpdateUserMutation, errorContext: ErrorContext.USERS });
  const [deleteUser, { isLoading: isDeletingUser }] = useMutation<
    DataApiInputParams,
    StringKeys
  >({ api: useDeleteUserMutation, errorContext: ErrorContext.USERS });
  const [deleteUserId, setDeleteUserId] = useState<string>('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [filteredKeys, setFilteredKeys] = useState<StringKeys>({});
  const [filtersValue, setFiltersValue] = useState<StringKeys>({});

  const [editingRowIds, setEditingRowIds] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<(string | number)[]>([]);
  const [fetchUsers, { data: users, isLoading, isFetching }]: any =
    useLazyQuery<DataApiInputParams, StringKeys>({
      api: useLazyGetUsersQuery,
      errorContext: ErrorContext.USERS
    });
  const [columnExtensions, setColumnExtensions] = useState<any>([
    ...getColumnExtensionsWidth(
      userColumnExtensions,
      Math.max(window.innerWidth - drawerWidth, MIN_WIDTH_ALLOWED)
    )
  ]);
  const isFilterDisabled = useMemo(() => {
    return isFetching || selectedRows.length > 0;
  }, [isFetching, selectedRows]);
  const columns = useMemo(
    () => UserColumns.map((col) => ({ ...col, title: t(col.title) })),
    [i18n.language]
  );

  useEffect(() => {
    fetchUsers({
      params: { params: { ...getFilteredList(filteredKeys) } }
    });
  }, [filteredKeys]);

  useEffect(() => {
    if (users) {
      setRows([...generateRows(users, user)]);
    }
  }, [users, isLoading, isFetching]);

  useEffect(() => {
    const add = !sidebarToggle ? drawerWidth - 60 : 0;
    setColumnExtensions([
      ...getColumnExtensionsWidth(
        userColumnExtensions,
        Math.max(gridRef.current?.clientWidth, MIN_WIDTH_ALLOWED) + add || 0
      )
    ]);
  }, [sidebarToggle]);

  const getRowId = useCallback((row) => {
    return row.id;
  }, []);

  const getActionColumnSorted = useCallback(
    ({ tableColumns }) => [
      ...tableColumns.filter(
        (c: any) => c.type !== TableEditColumn.COLUMN_TYPE
      ),
      {
        key: 'editCommand',
        type: TableEditColumn.COLUMN_TYPE
      }
    ],
    []
  );

  const onClickReset = (initialObject: any) => {
    setFilteredKeys({ ...initialObject });
  };

  const onSearch = (selectedFields: StringKeys) => {
    if (!isEqual(selectedFields, filteredKeys)) {
      fetchUsers({
        params: { params: { ...getFilteredList(selectedFields) } }
      });
      setFilteredKeys(selectedFields);
    }
  };

  const renderEditors = useCallback((props) => {
    return getEditorComponent({
      cellProps: props
    });
  }, []);

  const commitChanges = ({ changed, deleted }: any) => {
    if (deleted) {
      setDeleteDialogOpen(true);
      setDeleteUserId(deleted[0]);
      return;
    }

    if (changed) {
      onRowUpdated(changed);
    }
  };

  const handleMultipleDelete = (): void => {
    setDeleteDialogOpen(true);
  };

  const handleClose = useCallback(() => {
    setIsInviteUsersPopupOpen(false);
    setDeleteDialogOpen(false);
    setDeleteUserId('');
  }, []);

  const handleInviteUsersClick = useCallback(() => {
    setIsInviteUsersPopupOpen(true);
  }, []);

  const handleSetEditingRowIds = useCallback((ids: any) => {
    setEditingRowIds(() => ids);
  }, []);

  const deleteUserSuccessCallback = useCallback(
    (data) => {
      if (data) {
        dispatch(
          setAlert({
            msg:
              selectedRows.length > 1
                ? t(T.usersDeleteSuccess)
                : t(T.userDeleteSuccess),
            type: ALERT.SUCCESS
          })
        );
        setRows((prev) =>
          prev.filter(
            (row) => !selectedRows.includes(row.id) && row.id !== data
          )
        );
      } else {
        dispatch(
          setAlert({
            msg: t(T.userDeleteError),
            type: ALERT.ERROR
          })
        );
      }
      setSelectedRows([]);
      setShowLoader(false);
    },
    [selectedRows]
  );

  const handleDelete = useCallback(async () => {
    if (selectedRows.length && deleteUserId === '') {
      await deleteUser({
        params: {
          params: {
            user_list: selectedRows.toString()
          }
        },
        fallbackMsg: T.userDeleteError,
        successCallback: deleteUserSuccessCallback
      });
      setShowLoader(false);
    } else {
      onRowDeleted(deleteUserId);
    }
    setDeleteDialogOpen(false);
  }, [deleteUserId, selectedRows]);

  const renderTableHeader = useCallback(
    (props: any) => <TableRowHeader {...props} />,
    []
  );

  const EditableRow = useCallback(({ row, ...restProps }: any) => {
    const activateRowEditing = () => {
      if (
        userAppPermissions?.[USER_APP_PERMISSIONS.EDIT_USERS]?.value &&
        row.id !== user.id
      ) {
        setEditingRowIds((prev) => [...prev, row.id]);
      } else return;
    };

    if (!row.id) {
      return <InviteUsersRow onClick={handleInviteUsersClick} />;
    }
    return (
      <CustomTableRow
        row={row}
        {...restProps}
        onDoubleClick={activateRowEditing}
      />
    );
  }, []);

  const Command = useCallback(({ id, onExecute, ...props }: any) => {
    return (
      <RowAction
        onExecute={onExecute}
        actionType={id}
        rowData={props.rowData}
        setEditingRowIds={setEditingRowIds}
        setShowLoader={setShowLoader}
        {...props}
      />
    );
  }, []);

  const onRowUpdated = async (changed) => {
    const changedObject: any = Object.values(changed)[0];
    const changedRowId = Object.keys(changed)[0];

    let changedRow: any = rows.filter((row: any) => changedRowId === row.id);
    if (isRowValid({ ...changedRow[0], ...changedObject })) {
      setShowLoader(() => true);
      let changedRow: any = rows.filter((row: any) => changedRowId === row.id);
      const params: any = {
        user_id: changedRowId,
        account_type_id:
          changedObject?.accountType?.id || changedRow[0]?.accountType?.id,
        user_name: changedRow[0]?.userName
      };
      const res = await postUser({
        params: {
          params: params
        },
        fallbackMsg: T.userUpdateFailed
      });
      if (res) {
        let changedRows =
          rows.map((row: any) => {
            return changedRowId === row.id
              ? {
                  ...row,
                  ...changedObject
                }
              : row;
          }) || [];

        setRows(changedRows);
        dispatch(
          setAlert({
            msg: t(T.userUpdateSuccess),
            type: ALERT.SUCCESS
          })
        );
      }
      setShowLoader(() => false);
    }
  };

  const onRowDeleted = (deleted: string) => {
    const deletedRow = rows.find((row: any) => deleted === row.id);
    if (deletedRow) {
      setShowLoader(true);
      deleteUser({
        params: {
          params: {
            user_list: deletedRow?.id
          }
        },
        fallbackMsg: T.userDeleteError,
        successCallback: deleteUserSuccessCallback,
        errorCallback: () => setShowLoader(false)
      });
      setDeleteDialogOpen(false);
    } else {
      dispatch(
        setAlert({
          msg: t(T.noRecordFound),
          type: ALERT.ERROR
        })
      );
    }
    setSelectedRows((prev) => prev.filter((rowId) => rowId !== deleted));
    setDeleteUserId('');
  };

  const renderSelectionState = useMemo(
    () => userAppPermissions?.[USER_APP_PERMISSIONS.DELETE_USERS]?.value,

    [selectedRows, userAppPermissions]
  );

  const IntegratedColumnExtension = useMemo(() => {
    return integratedSortingColumnExtensions(user);
  }, [user]);

  const filters = useMemo(
    () =>
      getFilters({
        account_type: lookups?.[LOOKUP.ACCOUNT_TYPE] || [],
        user_status: lookups?.[LOOKUP.USERS] || []
      }),
    [lookups[LOOKUP.ACCOUNT_TYPE], lookups[LOOKUP.USERS]]
  );

  const disableMultipleDelete: boolean = useMemo(() => {
    return !selectedRows.length || selectedRows.includes(user.id);
  }, [selectedRows, user]);
  const onClearSelection = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    if (!isLoading && users?.length && !isOnboarded)
      dispatch(toggleOnboarding());
  }, [isLoading, users]);
  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        height="100%"
        padding={2}
        ref={gridRef}
        sx={{
          ...(sidebarToggle && { width: `calc(100vw - ${drawerWidth}px)` })
        }}
      >
        <PageHeader
          title={t(T.users)}
          showInfoIcon={false}
          infoIconTooltipText={''}
          filterProps={{
            elementsList: filters,
            onSearch: onSearch,
            onReset: onClickReset,
            forceReset: true,
            showFilters: !isFetching,
            disableFilters: isFilterDisabled,
            filtersValue,
            setFiltersValue
          }}
          actionButtons={ActionButtons({
            selectedRows,
            showInviteButton: true,
            isInvitingUsers: isInviteUsersPopupOpen,
            handleInviteUsers: handleInviteUsersClick
          })}
        >
          <GridActions
            hideActions={!selectedRows.length}
            selectedRows={selectedRows.length}
            onClearSelection={onClearSelection}
            appliedFilters={{
              appliedFiltersValue: filtersValue,
              setAppliedFiltersValue: setFiltersValue,
              onReset: onClickReset,
              elementsList: filters
            }}
            actionButtons={ActionButtons({
              selectedRows,
              showDeleteButton: true,
              handleMultipleDelete,
              disableMultipleDelete
            })}
          />
        </PageHeader>

        {isLoading ? (
          <Loader />
        ) : (
          <Grid
            marginTop={theme.spacing(2)}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            sx={{
              height: 'calc(100% - 130px)'
            }}
          >
            <Grid item xs={12} height={'100%'}>
              {isDeletingUser || isUpdatingUser ? <Loader /> : null}
              {isFetching || isLoading ? (
                <GridSkeleton columnExtensions={userColumnExtensions} />
              ) : (
                <GridContainer length={rows?.length || 0}>
                  {showLoader ? <Loader /> : null}
                  <DataGrid
                    rows={
                      !!userAppPermissions?.[USER_APP_PERMISSIONS.CREATE_USERS]
                        ?.value
                        ? [placeholderRow, ...rows]
                        : rows
                    }
                    columns={columns}
                    getRowId={getRowId}
                    rootComponent={Root}
                  >
                    <EditingState
                      onCommitChanges={commitChanges}
                      editingRowIds={editingRowIds}
                      onEditingRowIdsChange={handleSetEditingRowIds}
                    />
                    {renderSelectionState && (
                      <SelectionState
                        selection={selectedRows}
                        onSelectionChange={setSelectedRows}
                      />
                    )}
                    <SortingState
                      defaultSorting={defaultSorting}
                      columnExtensions={columnSortingExtensions}
                    />
                    <IntegratedSorting
                      columnExtensions={IntegratedColumnExtension}
                    />
                    <TextProvider for={[USER_COLUMNS.NAME]} />
                    <UserGroupProvider for={[USER_COLUMNS.USER_GROUPS]} />
                    <AccountTypeProvider for={[USER_COLUMNS.ACCOUNT_TYPE]} />
                    <StatusProvider for={[USER_COLUMNS.STATUS]} />
                    <VirtualTable
                      rowComponent={EditableRow}
                      columnExtensions={columnExtensions}
                    />
                    <TableEditColumn
                      showEditCommand
                      showDeleteCommand
                      commandComponent={Command}
                      cellComponent={EditActionCell}
                    />
                    <TableHeaderRow
                      rowComponent={renderTableHeader}
                      cellComponent={TableCellHeader}
                      showSortingControls={!editingRowIds.length}
                    />
                    {renderSelectionState && (
                      <Plugin>
                        <IntegratedSelection />
                        <TableSelection showSelectAll />
                      </Plugin>
                    )}

                    <TableEditRow cellComponent={renderEditors} />
                    <Getter
                      name="tableColumns"
                      computed={getActionColumnSorted}
                    />
                  </DataGrid>
                </GridContainer>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
      {deleteDialogOpen && (
        <ConfirmationDialog
          title={t(T.delete) + '?'}
          isOpen={deleteDialogOpen}
          message={
            deleteUserId || selectedRows.length === 1
              ? t(T.confirmDeleteUser)
              : t(T.confirmDeleteMultipleUsers)
          }
          onConfirm={handleDelete}
          onClose={handleClose}
        />
      )}
      {isInviteUsersPopupOpen && (
        <InviteUsersPopup
          isOpen={isInviteUsersPopupOpen}
          onClose={handleClose}
          setShowLoader={setShowLoader}
        />
      )}
    </>
  );
};

export default UserGridView;
