import { DOC_ANALYST_TABS } from 'src/types/enum';
import { ReactComponent as DocAnalystIcon } from 'src/assets/miniExperts/docAnalyst.svg';
import { ReactComponent as DocClassificationIcon } from 'src/assets/miniExperts/docClassifier.svg';
import { ReactComponent as SummaryGeneratorIcon } from 'src/assets/miniExperts/docSummarizer.svg';

import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

export const menuOptions = [
  {
    title: T.docAnalyst,
    description: T.docAnalystDescription,
    navLink: `/mini-experts/doc-analyst/${DOC_ANALYST_TABS.ANALYSIS}`,
    icon: DocAnalystIcon,
    mainFeatures: T.docAnalystFeatures
  },
  {
    title: T.documentClassification,
    description: T.docClassifierDescription,
    icon: DocClassificationIcon,
    mainFeatures: T.docClassifierFeatures
  },
  {
    title: T.summaryGenerator,
    description: T.docSummarizerDescription,
    icon: SummaryGeneratorIcon,
    mainFeatures: T.docSummaryGenerator
  }
];
