import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface ClearButtonProps {
  text?: string;
  onClick: () => void;
  disabled?: boolean;
}

const ClearButton = ({
  text = T.clearAll,
  disabled = false,
  onClick
}: ClearButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button variant="text" size="small" onClick={onClick} disabled={disabled}>
      {t(text)}
    </Button>
  );
};

export default ClearButton;
