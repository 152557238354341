import { Typography, TypographyProps } from '@mui/material';
import React from 'react';

type Props = {
  text: string;
  rest?: TypographyProps;
};

const CustomTypography = ({ text, rest = {} }) => {
  return (
    <Typography fontSize={'17px'} fontWeight={500} color={'primary'}>
      {text}
    </Typography>
  );
};

export default CustomTypography;
