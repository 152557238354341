import { useCallback, useState } from 'react';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { Box, Chip } from '@mui/material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { getInitials } from 'src/utils/utils';
import AvatarListDialog from 'src/components/AvatarListDialog';
import { StringKeys } from 'src/types/base';

const UserGroupsFormatter = ({ value }) => {
  const [isGroupListOpen, setIsGroupListOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleOpenUserGroupsList = useCallback(
    () => setIsGroupListOpen(true),
    []
  );
  const handleCloseUserGroupsList = useCallback(
    () => setIsGroupListOpen(false),
    []
  );

  return (
    <>
      <Box>
        {!!value?.length && (
          <Box my={1}>
            {value.slice(0, 1 || value.length).map((item, index) => (
              <Chip
                label={item?.name}
                key={index}
                disabled={false}
                title={item?.name}
                sx={{
                  height: 26,
                  maxWidth: 90,
                  overflow: 'ellipsis'
                }}
              />
            ))}
            {!!(value.length - 1) && (
              <Chip
                onClick={handleOpenUserGroupsList}
                label={`+${value.length - 1}`}
                sx={{
                  height: 26,
                  marginLeft: 1
                }}
              />
            )}
          </Box>
        )}
      </Box>
      {isGroupListOpen && (
        <AvatarListDialog
          isOpen={isGroupListOpen}
          handleClose={handleCloseUserGroupsList}
          title={t(T.userGroups)}
          nameList={value.map((item: StringKeys) => item?.name as string)}
          getInitials={getInitials}
        />
      )}
    </>
  );
};

export default UserGroupsFormatter;

export const UserGroupProvider = (props: any) => {
  return (
    <DataTypeProvider formatterComponent={UserGroupsFormatter} {...props} />
  );
};
