import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import format from 'date-fns/format';
import { DOCUMENT_ANALYSIS_STATUS } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { FilesCountFormatter } from './ColumnFormatters';

export const getRowId = (row) => row.id;
const DATE_FORMAT = `dd MMM, yy hh:mm a`;

export const ANALYSIS_COLUMNS = {
  ANALYSIS: 'name',
  DATASET: 'dataset_name',
  QUESTIONNAIRE: 'questionnaire_name',
  CREATED_ON: 'created_on',
  MODIFIED_ON: 'modified_on',
  STATUS: 'status_code'
};

export const ANALYSIS_COLUMN_TITLES = {
  [ANALYSIS_COLUMNS.ANALYSIS]: T.analysis,
  [ANALYSIS_COLUMNS.DATASET]: T.dataset,
  [ANALYSIS_COLUMNS.QUESTIONNAIRE]: T.questionnaire,
  [ANALYSIS_COLUMNS.CREATED_ON]: T.createdOn,
  [ANALYSIS_COLUMNS.MODIFIED_ON]: T.modifiedOn,
  [ANALYSIS_COLUMNS.STATUS]: T.status
};

export const analysisColumns = [
  {
    name: ANALYSIS_COLUMNS.ANALYSIS,
    title: ANALYSIS_COLUMN_TITLES[ANALYSIS_COLUMNS.ANALYSIS]
  },
  {
    name: ANALYSIS_COLUMNS.DATASET,
    title: ANALYSIS_COLUMN_TITLES[ANALYSIS_COLUMNS.DATASET]
  },
  {
    name: ANALYSIS_COLUMNS.QUESTIONNAIRE,
    title: ANALYSIS_COLUMN_TITLES[ANALYSIS_COLUMNS.QUESTIONNAIRE]
  },
  {
    name: ANALYSIS_COLUMNS.STATUS,
    title: ANALYSIS_COLUMN_TITLES[ANALYSIS_COLUMNS.STATUS]
  },
  {
    name: ANALYSIS_COLUMNS.CREATED_ON,
    title: ANALYSIS_COLUMN_TITLES[ANALYSIS_COLUMNS.CREATED_ON],
    // TODO: Check if need to move to a custom formatter
    getCellValue: (row) => format(new Date(row.created_on), DATE_FORMAT)
  },
  {
    name: ANALYSIS_COLUMNS.MODIFIED_ON,
    title: ANALYSIS_COLUMN_TITLES[ANALYSIS_COLUMNS.MODIFIED_ON],
    // TODO: Check if need to move to a custom formatter
    getCellValue: (row) => format(new Date(row.modified_on), DATE_FORMAT)
  }
];

export const analysisColumnExtensions = [
  {
    columnName: ANALYSIS_COLUMNS.ANALYSIS,
    width: '20%',
    wordWrapEnabled: true
  },
  {
    columnName: ANALYSIS_COLUMNS.DATASET,
    width: '20%',
    wordWrapEnabled: true
  },
  {
    columnName: ANALYSIS_COLUMNS.QUESTIONNAIRE,
    width: '20%',
    wordWrapEnabled: true
  },
  {
    columnName: ANALYSIS_COLUMNS.CREATED_ON,
    width: '14%',
    wordWrapEnabled: true
  },
  {
    columnName: ANALYSIS_COLUMNS.MODIFIED_ON,
    width: '14%',
    wordWrapEnabled: true
  },
  {
    columnName: ANALYSIS_COLUMNS.STATUS,
    width: '12%',
    wordWrapEnabled: true
  }
];

export const analysisSearchFields = [
  {
    value: ANALYSIS_COLUMNS.ANALYSIS,
    label: T.analysis
  },
  {
    value: ANALYSIS_COLUMNS.DATASET,
    label: T.dataset
  },
  {
    value: ANALYSIS_COLUMNS.QUESTIONNAIRE,
    label: T.questionnaire
  }
];

export const DATASET_COLUMNS = {
  NAME: 'name',
  CREATED_ON: 'created_on',
  FILES_COUNT: 'files_count'
};

export const DATASET_COLUMN_TITLES = {
  [DATASET_COLUMNS.NAME]: T.name,
  [DATASET_COLUMNS.CREATED_ON]: T.createdOn,
  [DATASET_COLUMNS.FILES_COUNT]: T.numberOfFiles
};

export const datasetColumns = [
  {
    name: DATASET_COLUMNS.NAME,
    title: DATASET_COLUMN_TITLES[DATASET_COLUMNS.NAME]
  },
  {
    name: DATASET_COLUMNS.FILES_COUNT,
    title: DATASET_COLUMN_TITLES[DATASET_COLUMNS.FILES_COUNT]
  },
  {
    name: DATASET_COLUMNS.CREATED_ON,
    title: DATASET_COLUMN_TITLES[DATASET_COLUMNS.CREATED_ON],
    // TODO: Check if need to move to a custom formatter
    getCellValue: (row) => format(new Date(row.created_on), DATE_FORMAT)
  }
];

export const datasetColumnExtensions = [
  {
    columnName: DATASET_COLUMNS.NAME,
    width: '40%',
    wordWrapEnabled: true
  },
  {
    columnName: DATASET_COLUMNS.CREATED_ON,
    width: '30%',
    wordWrapEnabled: true
  },
  {
    columnName: DATASET_COLUMNS.FILES_COUNT,
    width: '30%',
    wordWrapEnabled: true
  }
];

export const QUESTIONNAIRE_COLUMNS = {
  NAME: 'name',
  CREATED_ON: 'created_on',
  MODIFIED: 'modified_on',
  QUESTIONS_COUNT: 'questions_count'
};

export const QUESTIONNAIRE_COLUMN_TITLES = {
  [QUESTIONNAIRE_COLUMNS.NAME]: T.name,
  [QUESTIONNAIRE_COLUMNS.CREATED_ON]: T.createdOn,
  [QUESTIONNAIRE_COLUMNS.MODIFIED]: T.modifiedOn,
  [QUESTIONNAIRE_COLUMNS.QUESTIONS_COUNT]: T.numberOfQuestions
};

export const questionnaireColumns = [
  {
    name: QUESTIONNAIRE_COLUMNS.NAME,
    title: QUESTIONNAIRE_COLUMN_TITLES[QUESTIONNAIRE_COLUMNS.NAME]
  },
  {
    name: QUESTIONNAIRE_COLUMNS.QUESTIONS_COUNT,
    title: QUESTIONNAIRE_COLUMN_TITLES[QUESTIONNAIRE_COLUMNS.QUESTIONS_COUNT]
  },
  {
    name: QUESTIONNAIRE_COLUMNS.CREATED_ON,
    title: QUESTIONNAIRE_COLUMN_TITLES[QUESTIONNAIRE_COLUMNS.CREATED_ON],
    // TODO: Check if need to move to a custom formatter
    getCellValue: (row) => format(new Date(row.created_on), DATE_FORMAT)
  },
  {
    name: QUESTIONNAIRE_COLUMNS.MODIFIED,
    title: QUESTIONNAIRE_COLUMN_TITLES[QUESTIONNAIRE_COLUMNS.MODIFIED],
    // TODO: Check if need to move to a custom formatter
    getCellValue: (row) => format(new Date(row.modified_on), DATE_FORMAT)
  }
];

export const questionnaireColumnExtensions = [
  {
    columnName: QUESTIONNAIRE_COLUMNS.NAME,
    width: '34%',
    wordWrapEnabled: true
  },
  {
    columnName: QUESTIONNAIRE_COLUMNS.CREATED_ON,
    width: '24%',
    wordWrapEnabled: true
  },
  {
    columnName: QUESTIONNAIRE_COLUMNS.MODIFIED,
    width: '24%',
    wordWrapEnabled: true
  },
  {
    columnName: QUESTIONNAIRE_COLUMNS.QUESTIONS_COUNT,
    width: '18%',
    wordWrapEnabled: true
  }
];

export const PAGE_SIZES = [25, 50, 100];

export const getActionColumnSorted = ({ tableColumns }) => [
  ...tableColumns.filter((c: any) => c.type !== TableEditColumn.COLUMN_TYPE),
  {
    key: 'editCommand',
    type: TableEditColumn.COLUMN_TYPE
  }
];

export const StatusCodesWithExportDisabled = [
  DOCUMENT_ANALYSIS_STATUS.INPROGRESS,
  DOCUMENT_ANALYSIS_STATUS.REGENERATING,
  DOCUMENT_ANALYSIS_STATUS.FAILED
];

export const datasetFormatters = [
  {
    for: [DATASET_COLUMNS.FILES_COUNT],
    formatterComponent: FilesCountFormatter
  }
];

export const analysisDetailColumns = [
  { width: '40px', title: 'No.' },
  { width: '400px', title: 'Questions' },
  { flex: 1, title: 'Response' },
  { width: '100px', title: '' }
];

export const QUESTION_CHARACTER_LIMIT = 500;
export const QUESTION_CHARACTER_WARNING_LIMIT = 450;