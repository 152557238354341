import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { getInitials } from 'src/utils/utils';

export const TextFormatter = ({ value }: any) => {
  const theme = useTheme();

  return (
    <Box display={'flex'} alignItems={'center'} maxWidth={'100%'}>
      <AvatarGroup max={2}>
        <Avatar
          sx={(theme) => ({
            bgcolor: theme.colors.primaryAlt.main,
            color: theme.palette.primary.main,
            height: 38,
            width: 38,
            fontSize: '1rem'
          })}
        >
          {getInitials(value?.fullName?.trim() || value?.email)}
        </Avatar>
      </AvatarGroup>
      <Stack marginLeft={1} maxWidth={'90%'}>
        <Typography
          title={value.email}
          variant={'body1'}
          fontWeight={'bold'}
          sx={{
            display: 'block',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
        >
          {value.email}
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            color: theme.colors.custom.labelColor
          }}
        >
          {value?.fullName || ''}
        </Typography>
      </Stack>
    </Box>
  );
};

export const TextProvider = (props: any) => {
  return <DataTypeProvider formatterComponent={TextFormatter} {...props} />;
};
