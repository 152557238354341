import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  useTheme
} from '@mui/material';
import {
  Close,
  Check,
  DeleteOutlineOutlined,
  MoreHoriz
} from '@mui/icons-material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { RootState, useDispatch } from 'src/redux/store';
import {
  ACTION_TYPES,
  ALERT,
  USER_APP_PERMISSIONS,
  USER_STATUS
} from 'src/types/enum';
import { useCallback, useMemo, useState } from 'react';
import { IUser } from '../types';
import { StringKeys } from 'src/types/base';
import useMutation from 'src/hooks/useMutation';
import { DataApiInputParams } from 'src/types/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { useUpdateStatusMutation } from 'src/services/api';
import { setAlert } from 'src/redux/slices/snackbar';
import { useSelector } from 'src/redux/store';

interface RowActionProps {
  setEditingRowIds: React.Dispatch<React.SetStateAction<any>>;
  actionType: ACTION_TYPES;
  onExecute: (params?: any) => void;
  rowData: IUser;
  setShowLoader?: React.Dispatch<React.SetStateAction<boolean>>;
}
const RowAction = ({
  actionType,
  onExecute,
  rowData,
  setShowLoader,
  setEditingRowIds
}: RowActionProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { user: loggedInUser } = useSelector((state) => state.auth);
  const { userAppPermissions } = useSelector((state: RootState) => state.data);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [updateStatus] = useMutation<DataApiInputParams, StringKeys>({
    api: useUpdateStatusMutation,
    errorContext: ErrorContext.USERS
  });

  const open = Boolean(anchorEl);
  const id = open ? 'user-actions' : undefined;
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const actionIcons = {
    delete: (
      <DeleteOutlineOutlined
        fontSize="small"
        sx={{
          color: theme.colors.custom.iconColor
        }}
      />
    ),
    cancel: <Close fontSize="small" />,
    commit: <Check fontSize="small" color="success" />
  };
  const actionTitles = {
    [ACTION_TYPES.EDIT]: t(T.edit),
    [ACTION_TYPES.DELETE]: t(T.delete),
    [ACTION_TYPES.CANCEL]: t(T.cancel),
    [ACTION_TYPES.COMMIT]: t(T.save)
  };

  const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleAction = () => {
    onExecute();
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    setEditingRowIds((prev) => [...prev, rowData.id]);
    onExecute();
    handleClose(e);
  };

  const actionText = useMemo(() => {
    switch (rowData.status) {
      case USER_STATUS.ACTIVE:
        return t(T.deActivate);
      case USER_STATUS.INACTIVE:
        return t(T.activate);
      default:
        return t(T.resendInvite);
    }
  }, [rowData, i18n.language]);

  const getErrorMessages = useCallback(
    (params: StringKeys) => {
      if (params.status) {
        return (params.status as USER_STATUS) === USER_STATUS.ACTIVE
          ? t(T.userActivationFailed)
          : t(T.userDeactivationFailed);
      } else if (params.send_email) {
        return t(T.inviteSentFailed);
      }
    },
    [i18n.language]
  );

  const updateSuccessCallback = useCallback(
    (params) => {
      if (params.status) {
        dispatch(
          setAlert({
            msg:
              params.status === USER_STATUS.ACTIVE
                ? t(T.userActivated)
                : t(T.userDeactivated),
            type: ALERT.SUCCESS
          })
        );
      }
      if (params.send_email) {
        dispatch(
          setAlert({
            msg: t(T.inviteSentSuccessfully),
            type: ALERT.SUCCESS
          })
        );
      }
    },
    [i18n.language]
  );

  const handleUpdate = useCallback(async (e) => {
    setShowLoader(true);
    handleClose(e);
    const params = {
      user_id: rowData.id,
      ...(rowData.status === USER_STATUS.ACTIVE ||
      rowData.status === USER_STATUS.INACTIVE
        ? {
            status:
              rowData.status === USER_STATUS.ACTIVE
                ? USER_STATUS.INACTIVE
                : USER_STATUS.ACTIVE
          }
        : {
            send_email: true
          })
    };
    await updateStatus({
      params: { params },
      fallbackMsg: getErrorMessages(params),
      successCallback: () => updateSuccessCallback(params)
    });
    setShowLoader(false);
  }, []);

  if (
    (actionType === ACTION_TYPES.DELETE &&
      !userAppPermissions?.[USER_APP_PERMISSIONS.DELETE_USERS]?.value) ||
    rowData?.id === loggedInUser.id
  ) {
    return null;
  } else if (
    (actionType === ACTION_TYPES.EDIT &&
      !userAppPermissions?.[USER_APP_PERMISSIONS.EDIT_USERS]?.value) ||
    rowData?.id === loggedInUser.id
  ) {
    return null;
  }

  if (actionType === ACTION_TYPES.EDIT) {
    return (
      <>
        <IconButton
          aria-describedby={id}
          onClick={handlePopoverClick}
          sx={{
            marginRight: 0.5
          }}
        >
          <MoreHoriz fontSize="small" />
        </IconButton>
        <Popover
          id={id}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          disablePortal={true}
        >
          <Box
            display={'flex'}
            textAlign={'left'}
            flexDirection={'column'}
            sx={{
              padding: 0,
              backgroundColor: 'white'
            }}
          >
            <Button
              sx={{
                borderRadius: 0,
                justifyContent: 'flex-start'
              }}
              onClick={handleUpdate}
            >
              <Typography
                sx={{
                  color: theme.colors.custom.labelColor
                }}
              >
                {actionText}
              </Typography>
            </Button>
            <Button
              sx={{
                borderRadius: 0,
                borderTop: '1px solid #E5E5E5',
                justifyContent: 'flex-start'
              }}
              onClick={handleEdit}
            >
              <Typography
                sx={{
                  color: theme.colors.custom.labelColor
                }}
              >
                {t(T.edit)}
              </Typography>
            </Button>
          </Box>
        </Popover>
      </>
    );
  }
  return (
    <>
      <IconButton onClick={handleAction} title={actionTitles[actionType]}>
        {actionIcons[actionType]}
      </IconButton>
    </>
  );
};

export default RowAction;
