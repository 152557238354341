import { combineReducers } from '@reduxjs/toolkit';
import { reducer as themeReducer } from '../slices/theme';
import { reducer as authReducer } from '../slices/auth';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as snackbarReducer } from '../slices/snackbar';
import { reducer as dataReducer } from '../slices/data';
import { reducer as libraryReducer } from '../slices/library';
import { reducer as onboardingReducer } from '../slices/onboarding';
import { reducer as DocAnalyst } from '../slices/docAnalyst';

import { api } from 'src/services/api';
import { microsoftApi } from 'src/services/microsoftApi';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['isAuthorized']
};

const dataPersistConfig = {
  key: 'data',
  storage,
  whitelist: ['uploadTransactionId']
};
const rootReducer = combineReducers({
  theme: themeReducer,
  auth: persistReducer(authPersistConfig, authReducer),
  chat: chatReducer,
  snackbar: snackbarReducer,
  data: persistReducer(dataPersistConfig, dataReducer),
  library: libraryReducer,
  onboarding: onboardingReducer,
  docAnalyst: DocAnalyst,
  [api.reducerPath]: api.reducer,
  [microsoftApi.reducerPath]: microsoftApi.reducer
});

export default rootReducer;
