import {
  DataTypeProvider,
  DataTypeProviderProps
} from '@devexpress/dx-react-grid';
import { Box } from '@mui/material';
import StackedChips from 'src/components/StackedChips';

const DescriptionFormatter = ({
  value
}: DataTypeProvider.ValueFormatterProps) => {
  return (
    <Box maxWidth={'800px'}>
      <StackedChips
        maxNumber={4}
        data={Object.keys(value || {}).map((key) => ({
          id: value[key].id,
          text: value[key].description
        }))}
        icon={undefined}
        color={'default'}
      />
    </Box>
  );
};

export const DescriptionProvider = (props: DataTypeProviderProps) => {
  return (
    <DataTypeProvider {...props} formatterComponent={DescriptionFormatter} />
  );
};
